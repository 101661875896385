import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import { font, theme } from '../../ApiService/Theme'
import { base_url } from '../../config';
import Default from '../../Assets/Images/default.png'
import API from '../../ApiService/ApiService';


const TC1 = ({ category }) => {
const api = new API();



  return (
    <>
      <TopCategory>
        <div className='top_category_section'>
          <div className='wrapper'>
            <H2>Popular Category</H2>
            <ul>
              {
                category?.slice(0, 6).map((e, i) => {
                  return (
                    <li key={i}>
                      <div className='top_box'>
                        <div className='top_box_img'>
                          <Link to={`/shop/${e?._id}`}> <img src={e?.images ? api.rootUrl1 + e?.images : Default} alt={e?.name} /></Link>
                        </div>
                        <div className='top_content'>
                          <h4>{e?.name}</h4>
                        </div>
                      </div>
                    </li>
                  )
                }
                )}
            </ul>
          </div>
        </div>
      </TopCategory>
    </>
  )
}

export default TC1;

const H2 = styled.h2`
   font-size:30px;
   margin : 0 0 35px;
   text-transform: uppercase;
   font-family: ${font?.regular} !important;
   letter-spacing: 0.7px;

   @media screen and (max-width:768px) {
    text-align: center;
   }

`

const TopCategory = styled.section`
    width: 100%;
    display: inline-block;
    position: relative;

    .top_category_section {
        display: inline-block;
        width: 100%;
        position: relative;
    }
    .top_category_section ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(6,1fr);
        gap: 25px;
    }
    .top_category_section ul li {
        width: 100%;
        display: inline-block;
        position: relative;
    }
    .top_category_section ul li .top_box {
        display: inline-block;
        position: relative;
        width: 100%;
        
    }
    .top_category_section ul li .top_box .top_box_img {
        height: 140px;
    width: 140px;
    padding: 35px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 0 60px 60px 60px;
    box-shadow: 0 0 15px rgb(0 0 0 / 5%);
    /* border: 1px solid #bfbbbb; */
    /* border-style: dashed; */
}


    .top_category_section ul li .top_box .top_content {
        width: 100%;
        padding: 20px 15px 0px 20px;
        text-align: center;
        
    }

    .top_category_section ul li .top_box .top_content h4 {
        font-size: 15px;
        font-family: ${font?.regular} !important;
        text-transform: uppercase;
        color: ${theme?.bg};
        font-weight: 600;
        letter-spacing: 1px;
        margin: 0 0 5px;
    }
   

    @media screen and (max-width:1200px) {
        .top_category_section ul li .top_box .top_box_img {
            height: 125px;
            width: 125px;
            padding: 33px;
        }

}

@media screen and (max-width:992px) {
    .top_category_section ul {
        grid-template-columns: repeat(3,1fr);
    }
}

@media screen and (max-width:580px) {
    .top_category_section ul {
    grid-template-columns: repeat(2,1fr);
    gap: 30px 15px;
}
}







`;