import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import homeSlice from './slices/homeSlice';
import homeCustomSlice from './slices/homeCustomSlice';
import templateOrderArraySlice from "./slices/templateOrderArraySlice";
import specificationSlice from "./slices/specificationSlice";
import specificationdetailsSlice from "./slices/specificationdetailsSlice";
import socialMedia from "./slices/socialMedia";
import company from './slices/company';
import banner from './slices/banner';
import tax from './slices/tax';
import category from './slices/category';
import product from "./slices/product";
import CetgoryMenu from "./slices/categoryMenu";
import user from './slices/user'
import userAddress from "./slices/userAddress";
import updateAddress from "./slices/updateAddress";
import removeAddress from "./slices/removeAddress";
import states from "./slices/states";
import cities from "./slices/cities";
import singleProduct from "./slices/singleProduct";
import profile from "./slices/profile";
import rating from "./slices/rating";
import cart from "./slices/cart";
import privacypolicy from "./slices/privacypolicy";
import refundpolicy from "./slices/refundpolicy";
import deliverypolicy from "./slices/deliverypolicy";
import returnpolicy from "./slices/returnpolicy";
import cancellationpolicy from "./slices/cancellationpolicy";
import termsAndCondition from "./slices/termsAndCondition";
import aboutus from "./slices/aboutus";
import contactus from "./slices/contactus"
import review from "./slices/review";
import wishList from "./slices/wishList";

const reducer = combineReducers({
  homeSlice: homeSlice,
  homeCustomSlice: homeCustomSlice,
  templateOrderArraySlice: templateOrderArraySlice,
  specificationSlice: specificationSlice,
  specificationdetailsSlice: specificationdetailsSlice,
  socialMedia: socialMedia,
  company: company,
  banner: banner,
  category: category,
  product: product,
  CetgoryMenu: CetgoryMenu,
  tax: tax,
  user: user,
  userAddress: userAddress,
  updateAddress: updateAddress,
  removeAddress: removeAddress,
  states: states,
  cities: cities,
  singleProduct : singleProduct,
  profile: profile,
  rating: rating,
  cart: cart,
  privacypolicy:privacypolicy,
  refundpolicy:refundpolicy,
  deliverypolicy:deliverypolicy,
  returnpolicy:returnpolicy,
  cancellationpolicy:cancellationpolicy,
  termsAndCondition: termsAndCondition,
  aboutus: aboutus,
  contactus: contactus,
  review: review,
  wishList: wishList
});


const store = configureStore({
  reducer,
  middleware: [thunk]
});

export default store;
