import { createSlice } from "@reduxjs/toolkit";

const socialMedia = createSlice({
  name:'socialmedia',
  initialState:{
    socialmedia:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    socialMediaGetAllStart:(state)=>{
      state.isFetching = true;
    },
    socialMediaGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.socialmedia = action.payload;
    },
    socialMediaGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {socialMediaGetAllStart,socialMediaGetAllSuccess,socialMediaGetAllFail} = socialMedia?.actions;
export default socialMedia.reducer;



