import React from "react";
import "../../Assets/Css/style.css";
import styled from "styled-components";
import Product1 from "../../Assets/Images/GiftProduct/g-p1.png";
import product2 from "../../Assets/Images/GiftProduct/gp-2.png";
import product3 from "../../Assets/Images/GiftProduct/gp-3.png";
import product4 from "../../Assets/Images/GiftProduct/gp-4.png";
import product5 from "../../Assets/Images/GiftProduct/gp-5.png";
import product6 from "../../Assets/Images/GiftProduct/gp-6.png";
import { Link } from "react-router-dom";
import { Button } from "antd";
import API from "../../ApiService/ApiService";

const GiftProductSection = ({ product, content_all }) => {
  // console.log("product from componn", content_all);

  const api = new API();

  const GiftProducts = [
    {
      image: Product1,
      name: "Pen with card holder",
    },
    {
      image: product2,
      name: "Cushions",
    },
    {
      image: product3,
      name: "Fancy Keychains",
    },
    {
      image: product4,
      name: "Table Lamp",
    },
    {
      image: product5,
      name: "Wooden Bowls",
    },
    {
      image: product6,
      name: "Coffee Mugs",
    },
    {
      image: product2,
      name: "Cushions",
    },
    {
      image: Product1,
      name: "Pen with card holder",
    },
    {
      image: Product1,
      name: "Pen with card holder",
    },
    {
      image: product2,
      name: "Cushions",
    },
    {
      image: product3,
      name: "Fancy Keychains",
    },
    {
      image: product4,
      name: "Table Lamp",
    },
  ];

  return (
    <GiftProductSectionALign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">{content_all?.smile_pro_title}</p>
        </div>
        <div className="GiftProductSection">
          <div className="GiftProduct_Main">
            {content_all?.smile_pro_pro ?.map((item, i) => {
              return (
                <div key={i} className="GiftProduct_Box">
                  <div className="GiftProduct_Image">
                    <Link to={`/product/${item?.value}`}><img src={api.rootUrl1 + item?.image} alt="" /></Link>
                  </div>
                  <div className="Product_Name">
                    <p>{item?.label}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="View_All">
          <Button className="View_All_Button" href={`/products`}>
            {/* {product?.button_text} */}
            View Products
          </Button>
        </div>
      </div>
    </GiftProductSectionALign>
  );
};

export default GiftProductSection;

const GiftProductSectionALign = styled.div`
padding-top: 100px;
  .Top_Title {
    text-align: center;
  }

  .Top_Title .Title {
    color: #3d418b;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .Top_Title .Span_Title {
    padding-left: 10px;
    color: #0174be;
    font-family: Yellowtail;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .GiftProduct_Main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    align-items: center;
    justify-content: space-evenly;
    justify-items: center;
  }
  .GiftProduct_Box .GiftProduct_Image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .GiftProduct_Box .GiftProduct_Image img {
    height: 200px;
    width: 200px;
  }

  .GiftProductSection {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .GiftProduct_Box .Product_Name {
    text-align: center;
    color: #000;
    font-family: Yellowtail;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  }

  .View_All {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .View_All .View_All_Button {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #f6a82d;
    color: #fafafa;
    border-radius: 0 !important;
    text-decoration: none;
  }

  Link {
    text-decoration: none;
  }

  .View_All .View_All_Button:focus {
    background: #f6a82d;
    color: #fafafa;
  }

  .View_All .View_All_Button:hover {
    background: #f6a82d !important;
    color: #fafafa !important;
  }

  @media screen and (max-width: 767px) {
    .GiftProduct_Main {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }
`;
