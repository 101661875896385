import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import "../../Assets/Css/style.css";
import { Carousel, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
// import Banner from "../../Assets/Images/Poornima/Poornima_mainbanner.png";
import Marquee from "react-fast-marquee";
import API from "../../ApiService/ApiService";
import { base_url } from "../../config";
import { Navigate, useNavigate, Link } from "react-router-dom";

const contentStyle = {
  height: "100vh",
  width: "100%",
  objectFit: "fill",
};

const overlayStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
  textAlign: "center",
  width: "50%",
};

const slideStyle = {
  position: "relative",
};

const api = new API();

const BA11 = ({ banner, OfferBanner, content_all }) => {
  // console.log("homecustom", banner);
  // console.log("OfferBanner ?", OfferBanner);
  // console.log(api.rootUrl1 + banner?.[0]?.image);
  let image = api.rootUrl1 + content_all?.poornima_main_banner_img?.image;
  // console.log("image", image);
  image = image.replace(/\\/g, "/");
  // console.log("image===>", image);

  // const FilteredBanner = [];

  const carouselRef = useRef(null);

  const FilteredBanner = [];

  OfferBanner?.map((item) => {
    if(item?.offer_status === true && item?.offer_banner){
      FilteredBanner.push(item);
    }
  })

  // const FilteredBanner = OfferBanner?.filter((item) => item?.offer_status === true) 
  //   .map((item) => ({
  //     ...item,
  //     offer_banner: BannerImg1,
  //   }));

  // console.log("FilteredBanner ?", FilteredBanner);

  const navigate = useNavigate();

  const OfferBannerNavigation = (bannerItem) =>{
    console.log("bannerItem", bannerItem)
    // const navId = bannerItem?.products ? (bannerItem?.products?.[0]?.main_product_id) : (bannerItem?.main_products?.[0]?.main_product_id);
    const navId = bannerItem?.main_products?.[0]?.main_product_id ?  (bannerItem?.main_products?.[0]?.main_product_id) : (bannerItem?.products?.[0]?.main_product_id);
    console.log("combo", navId)
    navigate(`/product/${navId}`, { state: { bannerItem } });
  };

  return (
    <>
      {FilteredBanner?.length > 0 ? (
        <BannerSection>
          <Carousel
            ref={carouselRef}
            autoplay={true}
            infinite={true}
            arrows={false}
            fade
            // slidesToSlide={1}
            // customRightArrow={<CustomRight />}
            // customLeftArrow={<CustomLeft />}
          >
            {FilteredBanner?.map((bannerItem, index) => (
              // <Link
              //   key={index}
              //   to={{
              //     pathname: `/product/${
              //       bannerItem?.products
              //         ? bannerItem?.products?.[0]?.main_product_id
              //         : bannerItem?.main_products?.[0]?.main_product_id
              //     }`,
              //     state: { bannerItem },
              //   }}
              // >                
                <div style={slideStyle} onClick={() => OfferBannerNavigation(bannerItem)} key={index}>
                  <img
                    className="banner-image"
                    // src={bannerItem?.offer_banner}
                    src={api.rootUrl1 +bannerItem?.offer_banner}
                    style={contentStyle}
                  />
                  {/* <div className="overlay" style={overlayStyle}>                   
                    <div
                      className="custom-button-wrapper"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "32%",
                        margin: "auto",
                        gap: "20px",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        className="custom-button"
                        ghost
                        style={{ borderRadius: 0, fontWeight: "bold" }}
                        onClick={() => (window.location.href = `/register`)}
                      >
                        SIGN UP
                      </Button>
                    </div>
                  </div> */}
                </div>
              // </Link>
            ))}
          </Carousel>
          <ArrowLeft onClick={() => carouselRef.current.prev()}>
            <LeftOutlined />
          </ArrowLeft>
          <ArrowRight onClick={() => carouselRef.current.next()}>
            <RightOutlined />
          </ArrowRight>
        </BannerSection>
      ) : (
        <BannerAlign>
          <div className="transparent_wrapper">
            <div
              className="home_wrapper"
              style={{ backgroundImage: `url(${image})` }}
            >
              <div className="Banner_Main">
                <div className="Left_Section">
                  <div className="Left_Content">
                    <p
                      className="content"
                      style={{ fontSize: "30px", letterSpacing: "0.5px" }}
                    >
                      {/* Cotten Printed */}
                      {content_all?.poornima_banner_title1}
                    </p>
                    <h2
                      className="title"
                      style={{
                        fontSize: "40px",
                        letterSpacing: "1px",
                        fontWeight: "900",
                      }}
                    >
                      {/* Dress Material */}
                      {content_all?.poornima_banner_title2}
                    </h2>
                    <p>
                      {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sint
                      autem modi doloribus praesentium quae maiores quaerat ab minus,
                      perspiciatis vitae! */}
                      {content_all?.poornima_banner_desc}
                    </p>
                    <Button
                      className="Button"
                      style={{ marginTop: "10px", fontWeight: "600" }}
                      onClick={() => (window.location.href = `/products`)}
                    >
                      {/* {banner?.[0]?.button_text} */}
                      BUY NOW
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BannerAlign>
      )}
    </>
  );
};

export default BA11;

const BannerAlign = styled.div`
  .home_wrapper::before {
    background-color: #000;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.4;
  }

  .home_wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .Banner_Main {
    display: flex;
    /* align-items: center; */
    justify-content: space-evenly;
    gap: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    width: 100%;
    position: relative;
    /* left: -99px; */
    /* top: 95px; */
  }

  .Banner_Main .Left_Section {
    position: relative;
    top: -150px;
  }

  .Left_Section .Left_Content {
    color: #fff;
    font-family: Moneta;
    /* font-size: 60px; */
    font-style: normal;
    /* font-weight: 400; */
    line-height: 110%; /* 92.4px */
    /* letter-spacing: 2.52px; */
    margin: 360px 0 0 820px;
    padding-bottom: 20px;
    /* border: 1px solid red; */
  }

  .content {
    width: 500px;
  }

  .Left_Content .Button {
    display: inline-flex;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #88ab8e;
    color: #fff;
    border: none;
    outline: none;
  }

  .Left_Content .Button:hover {
    background: #fff;
    color: #000;
  }

  .Offer_Section {
    padding-top: 5px;
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    background: #2e4076;
    color: #fff;
    display: grid;
    place-items: center;
  }

  .marquee span {
    padding: 0 20px;
  }

  .Banner_Main .Right_Section {
    /* border: 1px solid red; */
    mix-blend-mode: multiply;
    filter: contrast(1);
  }
  .Right_Section .Right_Image {
    /* border: 1px solid black; */
    width: 375px;
  }

  .Offer_Section {
    .rfm-child {
      span {
        display: flex;
        p {
          margin: 0px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .Left_Section .Left_Content {
      margin: 360px 0 0 620px;
      p {
        width: 450px;
      }
    }
  }

  @media screen and (max-width: 1076px) {
    .Left_Section .Left_Content {
      margin: 360px 0 0 500px;
      p {
        width: 450px;
      }
    }
  }

  @media screen and (max-width: 972px) {
    .Left_Section .Left_Content {
      margin: 360px 0 0 400px;
      p {
        width: 450px;
      }
    }
  }

  @media screen and (max-width: 868px) {
    .Left_Section .Left_Content {
      margin: 360px 0 0 300px;
      p {
        width: 450px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .Left_Section .Left_Content {
      margin: 360px 0 0 200px;
      p {
        width: 450px;
      }
    }
  }

  @media screen and (max-width: 668px) {
    .Left_Section .Left_Content {
      margin: 360px 0 0 0px;
      p {
        width: 450px;
      }
    }
  }

  @media screen and (max-width: 540px) {
    .Left_Section .Left_Content {
      margin: 300px 0 0 10px;
      p {
        width: 450px;
      }
    }
  }

  @media screen and (max-width: 520px) {
    .Left_Section .Left_Content {
      margin: 300px 0 0 10px;
      p {
        width: 450px;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .Left_Section .Left_Content {
      margin: 300px 0 0 10px;
      p {
        width: 430px;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .Left_Section .Left_Content {
      margin: 300px 0 0 10px;
      p {
        width: 400px;
      }
    }
    .content {
      font-size: 20px;
    }
    .title {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 412px) {
    .Left_Section .Left_Content {
      margin: 300px 0 0 0px;
      width: 375px;
      p {
        width: 400px;
      }
    }
    .content {
      font-size: 20px;
      width: 300px;
    }
    .title {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 390px) {
    .Left_Section .Left_Content {
      margin: 300px 0 0 0px;
      width: 350px;
      p {
        width: 360px;
      }
    }
    .content {
      font-size: 20px;
      width: 300px;
    }
    .title {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 375px) {
    .Left_Section .Left_Content {
      margin: 300px 0 0 0px;
      width: 330px;
      p {
        width: 330px;
      }
    }
    .content {
      font-size: 20px;
      width: 300px;
    }
    .title {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 360px) {
    .Left_Section .Left_Content {
      margin: 300px 0 0 0px;
      width: 330px;
      p {
        width: 330px;
      }
    }
    .content {
      font-size: 20px;
      width: 300px;
    }
    .title {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 320px) {
    .Left_Section .Left_Content {
      margin: 300px 0 0 0px;
      width: 300px;
      p {
        width: 300px;
      }
    }
    .content {
      font-size: 20px;
      width: 300px;
    }
    .title {
      font-size: 30px;
    }
  }
`;

const BannerSection = styled.div`
  position: relative;

  .overlay {
    h2 {
      font-size: 60px;
      font-family: Inter;
      font-weight: 700;
    }
  }

  .custom-button:hover {
    background-color: #ffffff !important;
    color: #c23b2c !important;
    border: 1px solid #ffffff !important;
  }

  @media (max-width: 860px) {
    .banner-image {
      height: 45vh !important;
    }
    .overlay {
      h2 {
        font-size: 6vw; /* Adjust font size for smaller screens */
      }
      p {
        font-size: 3.5vw; /* Adjust font size for smaller screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    .banner-image {
      height: 45vh !important;
    }
    .overlay {
      h2 {
        font-size: 6vw; /* Adjust font size for smaller screens */
      }
      p {
        font-size: 3.5vw; /* Adjust font size for smaller screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 480px) {
    .banner-image {
      height: 45vh !important;
    }
    .overlay {
      h2 {
        font-size: 8vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 4vw; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }
  @media (max-width: 380px) {
    .banner-image {
      height: 45vh !important;
    }
    .overlay {
      h2 {
        font-size: 7vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 3vw; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  transform: translateY(-50%);
  font-size: 18px;
`;

const ArrowLeft = styled(Arrow)`
  left: 10px;
`;

const ArrowRight = styled(Arrow)`
  right: 10px;
`;
