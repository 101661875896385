import React from "react";
import styled from "styled-components";
import API from "../../ApiService/ApiService";

const WhyUsSection = ({why_us, content_all}) => {
  // console.log("why_us------------->", content_all);

 const api = new API();

 var why_us1 = [
  {
    id: 1,
    card_title: content_all?.smile_why_us_title1,
    card_description: content_all?.smile_why_us_desc1,
    image: content_all?.smile_why_us_img?.[0]?.image,
  },
  {
    id: 2,
    card_title: content_all?.smile_why_us_title2,
    card_description: content_all?.smile_why_us_desc2,
    image: content_all?.smile_why_us_img?.[1]?.image,
  },
  {
    id: 3,
    card_title: content_all?.smile_why_us_title3,
    card_description: content_all?.smile_why_us_desc3,
    image: content_all?.smile_why_us_img?.[2]?.image,
  },   
]

  return (
    <WhyUsSectionAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">{content_all?.smile_why_us_main_title}</p>
        </div>
        <div className="WhyUs_Section_Main">
          <div className="WhyUs_Section">
            {why_us1?.map((item, index) => (
              <div key={index} className="WhyUs_Section_Box">
                <div className="WhyUs_Section_Box_Image">
                  <img src={api.rootUrl1 + item?.image} />
                </div>
                <div className="WhyUs_Section_Box_Title">
                  <p>{item?.card_title}</p>
                </div>
                <div className="WhyUs_Section_Box_Description">
                  <p>{item?.card_description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </WhyUsSectionAlign>
  );
};

export default WhyUsSection;

const WhyUsSectionAlign = styled.div`
padding-top: 100px;
  .home_wrapper {
    width: 100%;
    flex-shrink: 0;
    background: #f8f8f8;
  }
  .Top_Title {
    text-align: center;
    padding: 30px 0 30px 0;
  }

  .Top_Title .Title {
    color: #3d418b;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .Top_Title .Span_Title {
    padding-left: 10px;
    color: #0174be;
    font-family: Yellowtail;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .WhyUs_Section_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .WhyUs_Section {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
  }

  .WhyUs_Section_Box_Image {
    height: 150px;
    width: 150px;
  }

  .WhyUs_Section_Box {
    width: 250px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 200px; */
  }

  .WhyUs_Section_Box_Title {
    /* padding-top: 10px; */

    text-align: center;
    color: #000;
    font-family: Yellowtail;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .WhyUs_Section_Box_Description {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  @media screen and (max-width: 767px) {
    .WhyUs_Section {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .Top_Title span::before {
      content: "";
      white-space: pre;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }
  }
`;
