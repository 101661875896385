import React from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { Button } from "antd";
import Image1 from "../../Assets/Images/Poornima/fourBanner1.png";
import Image2 from "../../Assets/Images/Poornima/fourBanner2.png";
import Image3 from "../../Assets/Images/Poornima/fourBanner3.png";
import Image4 from "../../Assets/Images/Poornima/fourBanner4.png";
import styled from "styled-components";
import API from "../../ApiService/ApiService";

const PoornimaFourBanner = ({ banner, content_all }) => {
  // console.log("banner==>", banner);
  const api = new API();
  const navigate = useNavigate();
  return (
    <BannerAlign>
      <div className="home_wrapper">
        {/* {( */}
        <div className="Banner_Main">
          {content_all?.poornima_four_banner?.map((item, index) => (
            <div className="Left_Section1" style={{ position: "relative" }}>
            <Link to={`/products?catName=${item?.label}`} style={{textDecoration: "none"}}>
            <img src={api?.rootUrl1 + item?.image} alt="Banner" style={{width: "100%"}} />
            {/* <img src={Image1} alt="Banner" /> */}
            <div
              className="Left_Content1"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "auto",
                // padding: "20px 20px",
                // border: "1px solid red",
                paddingTop: "200px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              <h4 style={{ color: "#fff", fontWeight: "600" }}>
                {/* KIDS WEAR */}
                {item?.label}
                </h4>
              <Button
                className="Button"
                // style={{ marginTop: "10px", fontWeight: "600" }}
                // onClick={() =>
                //   (window.location.href = `/${banner?.button_link}`)
                // }
              >
                {/* {banner?.button_text} */}
                BUY NOW
              </Button>
            </div>
            </Link>
          </div>
          ))}
          
        </div>
        {/* )} */}
      </div>
    </BannerAlign>
  );
};

export default PoornimaFourBanner;

const BannerAlign = styled.div`
  padding: 60px 60px;

  .Banner_Main {
    display: grid;
    /* align-items: center; */
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .Button {
    display: inline-flex;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    outline: #fff;
  }

  .Left_Content1 {
    background: transparent;
    background-image: linear-gradient(0deg, rgb(5, 5, 5), rgba(0, 0, 0, 0));
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%;
  }

  @media screen and (max-width: 1024px) {
    max-width: 1024px;
    padding: 0px 30px;
    .Banner_Main {
      grid-template-columns: repeat(2, 1fr);
    }    
  }

  @media screen and (max-width: 992px) {
    max-width: 992px;
    padding: 0px 30px;
    .Banner_Main {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 912px) {
    max-width: 912px;
    padding: 0px 30px;
    .Banner_Main {
      grid-template-columns: repeat(2, 1fr);
    }    
  }

  @media screen and (max-width: 768px) {
    max-width: 768px;
    padding: 0px 30px;
    .Banner_Main {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 540px) {
    max-width: 540px;
    padding: 0px 30px;
    .Banner_Main {
      grid-template-columns: repeat(1, 1fr);
    }    
  }

  @media screen and (max-width: 480px) {
    max-width: 480px;
    padding: 0px 30px;
    .Banner_Main {
      grid-template-columns: repeat(1, 1fr);
    }    
  }

  @media screen and (max-width: 425px) {
    max-width: 425px;
    padding: 0px 30px;
    .Banner_Main {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (max-width: 414px) {
    max-width: 414px;
    padding: 0px 30px;
    .Banner_Main {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (max-width: 412px) {
    max-width: 412px;
    padding: 0px 30px;
    .Banner_Main {      
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (max-width: 390px) {
    max-width: 390px;
    padding: 0px 30px;
    .Banner_Main {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (max-width: 375px) {
    max-width: 375px;
    padding: 0px 30px;
    .Banner_Main {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (max-width: 360px) {
    max-width: 360px;
    padding: 0px 30px;
    .Banner_Main {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (max-width: 320px) {
    max-width: 320px;
    padding: 0px 30px;
    .Banner_Main {
      height: auto;
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
