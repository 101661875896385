import React from "react";

import HomeProduct2 from "../HomeProductSection/HomeProduct2";
import FeaturesMain from "../Pages/FeaturesMain";
import WhyWeUsSectionMain from "../Pages/WhyWeUsSectionMain";
import AvailablePlatform from "../Pages/AvailablePlatform";
import Sales from "../Pages/Sales";
import ClientReviewMain from "../Pages/ClientReviewMain";
import FAQMain from "../Pages/FAQMain";
import CustomizableCollection from "../Pages/CustomizableCollection";
import styled from "styled-components";
import BannerMain from "../Pages/BannerMain";
import HomeProductMain from "../Pages/HomeProductMain";

const Template8 = ({ homecustom }) => {

  return (
    <div>
      <BannerMain banner_type="BA8" homecustom={homecustom?.theme_2?.banner_type} />
      <Wrapper className="wrapper">
        <HomeProductMain Home_product="HP2" homecustom={homecustom?.theme_2?.productspec_id} />
        <FeaturesMain features_type="F1" homecustom={homecustom?.theme_2?.feature_id} />
        <WhyWeUsSectionMain why_us_type="why_us_2" why_us={homecustom?.theme_2?.whyus}/>
        <AvailablePlatform homecustom={homecustom?.theme_2?.available_platforms} />
        <Sales homecustom={homecustom?.theme_2?.banner_mid}/>
        <ClientReviewMain review_type="review_2" homecustom={homecustom?.theme_2?.review} />
        <FAQMain homecustom={homecustom?.theme_2?.faq} />
        <CustomizableCollection homecustom={homecustom?.theme_2?.custom_collection} />
      </Wrapper>
    </div>
  );
};

export default Template8;

const Wrapper = styled.main`
  display: grid;
  grid-template-columns: repeat(1, fr);
  gap: 100px 0;

  @media screen and (max-width: 480px) {
    padding: 0 20px;
    gap: 30px 0;
  }
`;
