import React, {useState} from "react";
import { Link } from "react-router-dom";
import {
  SearchOutlined,
  ShoppingCartOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import { Menu, Space, } from "antd";
import { styled } from "styled-components";
import { useSelector } from 'react-redux'
import logo from '../../Assets/Images/logo.png'
import { base_url } from '../../config'

const { SubMenu } = Menu

const Header6 = () => {

  const [category, setCategory] = useState([])

  const company = useSelector((state)=> {return state?.company?.value})

  return (
    <div>
      <HeaderWrapper>
        <div className="header_align">
          <div className="header_left">
            
            <Link>
              <img src={company?.logo ? base_url + company?.logo : logo} alt="logo" />
            </Link>
            <Menu
              className="menu"
              mode="horizontal"
              style={{ borderBottom: "none" }}
            >
              <Menu.Item key="home">
                <Link to="/">Home</Link>
              </Menu.Item>
              <Menu.Item key="about">
                <Link to="/about">About Us</Link>
              </Menu.Item>
              <SubMenu key="categories" title="categories">
                {
                  category?.map((item, idx) => {
                    return (
                      <Menu.Item key={`category ${idx}`}>
                        <Link
                          to={`/${item?.category_name
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, "")}`}
                        >
                          {item?.category_name}
                        </Link>
                      </Menu.Item>
                    );
                  })
                }
              </SubMenu>
              <Menu.Item key="contact">
                <Link to="/contact">Contact Us</Link>
              </Menu.Item>
            </Menu>
          </div>
          <div className="header_right">
            <Space size="large">
              <SearchOutlined style={{ fontSize: "20px" }} />
              <ShoppingCartOutlined style={{ fontSize: "20px" }} />
              <HeartOutlined style={{ fontSize: "20px" }} />
            </Space>
          </div>
        </div>
      </HeaderWrapper>
    </div>
  );
};

export default Header6;

const HeaderWrapper = styled.div`
  width: 100%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;

  .header_align {
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid red; */
  }

  .header_left {
    width: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid red; */
  }
  img {
    margin-right: 30px;
    height: 60px;
  }
  .menu {
    /* border: 1px solid red; */
    width: 500px;
    font-weight: 500;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;
