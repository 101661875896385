import React from "react";
import styled from "styled-components";
import { Carousel, Button } from "antd";
import API from "../../ApiService/ApiService";
// import image1 from "../../Assets/Images/OneWear/OneWearOurStory1.jpg";
// import image2 from "../../Assets/Images/OneWear/OneWearOurStory2.jpg";
import '../../Assets/Fonts/fonts.css';

const overlayStyle = {
  position: "absolute",
  top: "0",
  left: "0",
  transform: "translate(80%, 15%)",
};

const slideStyle = {
  position: "relative",
};

const OurStory = ({ content_all }) => {
  const api = new API();
  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="HCFurniture_Main">
          <div className="Left_Section" style={slideStyle}>
            {/* <Link to={`/${content_all?.right_banner_img_top_link}`}> */}
            <div className="overlay">
              <img src={api.rootUrl1 + content_all?.onewear_ourstory_images?.[0]?.image} alt="banner2" />
              {/* <img src={image1} alt="banner2" /> */}
            </div>
            <div className="overlay1" style={overlayStyle}>
              <img src={api.rootUrl1 + content_all?.onewear_ourstory_images?.[1]?.image} alt="banner2" />
            </div>
            {/* </Link> */}
          </div>
          <div className="Right_Section">
            <div className="overlay2">
              {/* <p>
                At OneWear, we believe that casual doesn't mean compromising on
                style.{" "}
              </p> */}
              <p>
                {content_all?.onewear_ourstory_sub_description1}
              </p>
              <h4>{content_all?.onewear_ourstory_main_description}</h4>
              {/* <h4>ALWAYS IMPROVE, NEVER CHANGE. BREAK FREE AND EXPLORE.</h4> */}
              <p>
                {content_all?.onewear_ourstory_sub_description2}
              </p>
              {/* <p>
                At OneWear, we believe that casual doesn't mean compromising on
                style. Our curated collection of premium casual wear ensures you
                stay fashionable, comfortable, and effortlessly cool no matter
                where you go. Dive into our range of high-quality, meticulously
                crafted apparel designed for the modern individual.
              </p> */}
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button 
                  className="custom-button" 
                  style={{ 
                    borderRadius: 0, 
                    fontFamily: 'Montserrat' 
                    }}
                    onClick={() => window.location.href = `${content_all?.onewear_story_btn_link}`}
                    >
                    {/* SIGN UP */}
                    {content_all?.onewear_story_btn_txt}
                  </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerAlign>
  );
};

export default OurStory;

const BannerAlign = styled.div`
  /* padding-bottom: 80px; */
  .home_wrapper {
    display: flex;
    background-color: #e96027;
    padding: 60px 0px;
    width: 100%;
    height: 100%;
  }

  .HCFurniture_Main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid red; */
    width: 80%;
    height: 60vh;
    margin: auto;
    /* gap: 20px; */
  }

  .HCFurniture_Main .Left_Section
   {
    width: 50%;
    height: 100%;
    /* border: 1px solid red; */
  }

  .HCFurniture_Main .Right_Section{
    width: 40%;
    height: 100%;
    /* border: 1px solid blue; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay {
    width: 50%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .overlay1 {
    width: 50%;
    height: 65%;
    img {
      width: 90%;
      height: 100%;
    }
  } 

  .Right_Section .overlay2{
    display: flex;
    flex-direction: column;
    justify-content: "flex-end";
    align-items: "flex-end";
    gap: 20px;
    p{
        font-family: Inter;
      font-size: 10px;
      text-align: right;
      color: #ffffff;
      /* margin-bottom: 20px; */
    }

    h4{
        font-family: Inter;
      font-size: 20px;
      font-weight: 700;
      text-align: right;
      color: #ffffff;
    }
  }

  @media (max-width: 768px) {
    .HCFurniture_Main {
      flex-direction: column;
      height: auto;
    }

    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
      height: auto;
    }

    .overlay1 {
      transform: translate(100%, 25%) !important;
    }

    .overlay {
      width: 75%;
      height: auto;
    }

    .overlay1 img {
        width: 100%;
        height: 70%;
        object-fit: cover;
      }

    /* .overlay1 {
      width: 100%;
      height: auto;
    } */

    .Right_Section .overlay2 {
      p {
        font-size: 12px;
        text-align: center;
      }

      h4 {
        font-size: 16px;
        text-align: center;
      }
    }

    .custom-button {
      width: 100%;
      text-align: center;
    }
  }


  @media (max-width: 480px) {
    .HCFurniture_Main {
      flex-direction: column;
      height: auto;
    }

    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
      height: auto;
    }

    .overlay1 {
      transform: translate(83%, 20%) !important;
    }

    .overlay {
      width: 60%;
      height: auto;
    }

    /* .overlay1 {
      width: 100%;
      height: auto;
    } */
      .overlay1 img {
        width: 100%;
        height: 80%;
        object-fit: cover;
      }

    .Right_Section .overlay2 {
      p {
        font-size: 12px;
        text-align: center;
      }

      h4 {
        font-size: 16px;
        text-align: center;
      }
    }

    .custom-button {
      width: 100%;
      text-align: center;
    }
  }
`;
