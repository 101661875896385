import React from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import popular1 from "../../Assets/Images/popular image 1.png";
import popular2 from "../../Assets/Images/popular image 2.png";
import popular3 from "../../Assets/Images/popular image 3.png";
import popular4 from "../../Assets/Images/popular image 4.png";
import popular5 from "../../Assets/Images/popular image 5.png";
import API from "../../ApiService/ApiService";
import amazonLogo from "../../Assets/Images/AMAZON FULL LOGO.png";
import filpkartLogo from "../../Assets/Images/FLIPKART FULL LOGO.png";
import meeshoLogo from "../../Assets/Images/MESSHO LOGO.png";
import jioMart from "../../Assets/Images/JIOMART LOGO (1).png";
import shopClues from "../../Assets/Images/shopclues.png";

const AvailablePlatform = ({homecustom, content_all}) => {
  // console.log("Avail-------->", homecustom);
  const api = new API();
  const navigate = useNavigate();

  const handleClick = (buttonLink) => {
    if (buttonLink) {
      window.location.href = `https://${buttonLink}`;
      // navigate(buttonLink);
    }
  };

  const availaleplatforms_id = [
    {
      id: 1,
      image: content_all?.gowri_available_platform_product_images?.[0]?.image,
      icon: content_all?.gowri_available_platform_images?.[0]?.image,
      name: content_all?.gowri_available_platform_images?.[0]?.label,
      button_link: content_all?.gowri_Available_platform_link_1,
    },
    {
      id: 2,
      image: content_all?.gowri_available_platform_product_images?.[1]?.image,
      icon: content_all?.gowri_available_platform_images?.[1]?.image,
      name: content_all?.gowri_available_platform_images?.[1]?.label,
      button_link: content_all?.gowri_Available_platform_link_2,
    },
    {
      id: 3,
      image: content_all?.gowri_available_platform_product_images?.[2]?.image,
      icon: content_all?.gowri_available_platform_images?.[2]?.image,
      name: content_all?.gowri_available_platform_images?.[2]?.label,
      button_link: content_all?.gowri_Available_platform_link_3,
    },
    {
      id: 4,
      image: content_all?.gowri_available_platform_product_images?.[3]?.image,
      icon: content_all?.gowri_available_platform_images?.[3]?.image,
      name: content_all?.gowri_available_platform_images?.[3]?.label,
      button_link: content_all?.gowri_Available_platform_link_4,
    },
    {
      id: 5,
      image: content_all?.gowri_available_platform_product_images?.[4]?.image,
      icon: content_all?.gowri_available_platform_images?.[4]?.image,
      name: content_all?.gowri_available_platform_images?.[4]?.label,
      button_link: content_all?.gowri_Available_platform_link_5,
    },
  ]

  return (
    <Section>
      <div className="container">
        <h2 className="section_title">{content_all?.ap_title}</h2>
        <p className="section_description">{content_all?.ap_description}</p>
        <div className="platforms">
          {availaleplatforms_id?.map((e, i) => (
            <div class="popular-details amazon">
              <div class="popular-top">
                <div class="popular-img">
                  <img
                    alt="Plastic Buffet Plates, Buffet Plates Manufacturers in Coimbatore, Plastic Plates"
                    src={api.rootUrl1 + e?.image}
                  />
                </div>
              </div>
              <div class="popular-content">
                <div class="popular-icon">
                  <img
                    src={api.rootUrl1 + e?.icon}
                    alt="Buffet Plastic Plates, Buffet Plates Manufacturers in Coimbatore, Plastic Plates"
                  />
                </div>
                <div class="popular-text">
                  <button
                    class="btn-outline"
                    onClick={() => handleClick(e?.button_link)}
                  >
                    {/* {e?.button_text} */}
                    Shop Now
                  </button>
                </div>
              </div>
            </div>
          ))}

         
        </div>
      </div>
    </Section>
  );
};

export default AvailablePlatform;

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      color: #000;

      font-family: Brevia;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 25px;
    }
    .section_description {
      text-align: center;
      color: #898787;
      text-align: center;
      font-family: Brevia;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px; /* 32px */
    }

    .platforms {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .popular-details {
        width: 200px;
        height: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 110px 10px 9px;
        padding: 30px 0 0;
        border-radius: 10px;

        .popular-img img {
          display: inline-block;
          border: none;
          border-radius: 0;
          margin-top: -200px;
          mix-blend-mode: normal;
          filter: contrast(1);
          
        }

        .popular-icon {
          width: 120px;
          height: 40px;

          margin-bottom: 20px;
        }

        .popular-text {
          button {
            height: 100%;
            width: 100%;
            border: 1px solid #006a68;
            padding: 4px 10px;

            color: #006a68;
            font-family: Brevia;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            &:active {
              transform: scale(0.95);
            }
          }
        }
      }
      .amazon {
        /* background-color: #cbf3d7; */
        border: 1px solid #cbf3d7;
        opacity: 1;
        .popular-text {
          button {
            background-color: #cbf3d7;
          }
        }
      }
      .flipkart {
        background-color: #fce9d4;
        .popular-text {
          button {
            background-color: #fce9d4;
          }
        }
      }
      .meesho {
        background-color: #fddcee;
        .popular-text {
          button {
            background-color: #fddcee;
          }
        }
      }
      .jiomart {
        background-color: #f6d4d3;
        .popular-text {
          button {
            background-color: #f6d4d3;
          }
        }
      }
      .shopclues {
        background-color: #dce8f9;
        .popular-text {
          button {
            background-color: #dce8f9;
          }
        }
      }
    }
  }

  @media screen and (max-width: 990px) {
    .platforms {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }
  @media screen and (max-width: 786px) {
    .platforms {
      display: grid !important;
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }
  @media screen and (max-width: 480px) {
    .section_title {
      font-size: 30px !important;
      margin-bottom: 10px !important;
    }
    .platforms {
      display: grid !important;
      grid-template-columns: repeat(1, 1fr) !important;
    }
  }
`;
