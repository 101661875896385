import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/scss/pagination";
import API from "../../ApiService/ApiService";
import Default from "../../Assets/Images/banner_default.png";
import { base_url } from '../../config'
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user).setColor;

const Banner4 = ({ banner }) => {
  return (
   <>
    <BannerSection>
        <section className="Temp5_Banner">
          <Swiper
            pagination={{
              type: "progressbar",
            }}
            speed={2000}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            navigation={false}
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {banner?.map((e, i) => {
              return (
                <SwiperSlide key={`ban_t1${i}`}>
                  <Link to={e.link}>
                    <img
                      draggable={false}
                      style={{ width: "100%", height: "100%" }}
                      src={base_url + e?.image}
                    />
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </section>
      </BannerSection>
   </>
  )
}

export default Banner4;

const BannerSection = styled.div`
  .Temp5_Banner {
    width: 100%;
    margin-top: 10px;
  }
`;