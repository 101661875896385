import React from "react";
import styled from "styled-components";
import whyUsSec from "../../Assets/Images/why_us_sec_2.png";

import ourPromise from "../../Assets/Images/our_promise.png";
import ourValues from "../../Assets/Images/our_values.png";
import competitiveProgramming from "../../Assets/Images/competitive_pricing.png";
import trustedSeller from "../../Assets/Images/trusted_seller.png";
import API from "../../ApiService/ApiService";

const WhyUsSection2 = ({ why_us, content_all }) => {
  const api = new API();
  // console.log("why_us", why_us);
  const why_us_array = [
    {
      id: 1,
      image: content_all?.gowri_feature_card_images?.[0]?.image,
      card_title: content_all?.gowri_feature_card_title_1,
      card_description: content_all?.gowri_feature_card_description_1,
    },
    {
      id: 2,
      image: content_all?.gowri_feature_card_images?.[1]?.image,
      card_title: content_all?.gowri_feature_card_title_2,
      card_description: content_all?.gowri_feature_card_description_2,
    },
    {
      id: 3,
      image: content_all?.gowri_feature_card_images?.[2]?.image,
      card_title: content_all?.gowri_feature_card_title_3,
      card_description: content_all?.gowri_feature_card_description_3,
    },
    {
      id: 4,
      image: content_all?.gowri_feature_card_images?.[3]?.image,
      card_title: content_all?.gowri_feature_card_title_4,
      card_description: content_all?.gowri_feature_card_description_4,
    },
  ]
  return (
    <Section>
      <div className="main_container">
        <div className="left_side">
          <img src={api.rootUrl1 + content_all?.gowri_feature_image?.image} alt="why us" />
          {/* <img src={api.rootUrl1 + why_us?.banner_id?.image} alt="why us" /> */}
        </div>
        <div className="right_side">
          <h2>{content_all?.gowri_feature_title}</h2>
          <p className="desc">{content_all?.gowri_feature_description}</p>
          <div className="grid_container">
            {why_us_array?.map((item) => (
              <div className="why_us_grid_container">
                <div className="icon">
                  <img src={api.rootUrl1 + item?.image} alt="trusted seller" />
                </div>
                <div className="why_us_grid_container_details">
                  <p className="heading">{item?.card_title}</p>
                  <p className="card_desc">
                   {item?.card_description}
                  </p>
                </div>
              </div>
            ))}

            {/* <div className="why_us_grid_container">
              <div className="icon">
                <img src={competitiveProgramming} alt="Competitive Pricing" />
              </div>
              <div className="why_us_grid_container_details">
                <p className="heading">Competitive Pricing</p>
                <p>
                  Reliable and Trusted - Your <br />
                  Go-To Seller for Plastic <br />
                  Buffet Plate Sets
                </p>
              </div>
            </div>
            <div className="why_us_grid_container">
              <div className="icon">
                <img src={ourPromise} alt="Our Promise" />
              </div>
              <div className="why_us_grid_container_details">
                <p className="heading">Our Promise</p>
                <p>
                  Reliable and Trusted - Your <br />
                  Go-To Seller for Plastic <br />
                  Buffet Plate Sets
                </p>
              </div>
            </div>
            <div className="why_us_grid_container">
              <div className="icon">
                <img src={ourValues} alt="Our Values" />
              </div>
              <div className="why_us_grid_container_details">
                <p className="heading">Our Values</p>
                <p>
                  Reliable and Trusted - Your <br />
                  Go-To Seller for Plastic <br />
                  Buffet Plate Sets
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default WhyUsSection2;

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;

  .main_container {
    margin: 0 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    .left_side {
      img {
        width: 575px;
      }
    }

    .right_side {
      h2 {
        color: #000;
        font-family: Brevia;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 25px;
      }
      .desc {
        margin-bottom: 25px;
      }
      p {
        color: #898787;
        font-family: Brevia;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 32px */
      }

      .grid_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px 0;

        .why_us_grid_container {
          display: flex;

          .icon {
            width: 35px;
          }

          .why_us_grid_container_details {
            margin-left: 15px;

            p {
              color: #898787;
              font-family: Brevia;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 150%; /* 21px */
            }

            .heading {
              color: #000;
              font-family: Brevia;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 16px;
              margin-bottom: 8px;
            }

            .card_desc{
              width: 180px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .main_container {
      margin: 0;
    }
  }
  @media screen and (max-width: 768px) {
    .main_container {
      width: 100%;
      margin: 0;
      grid-template-columns: repeat(1, 1fr);
      .left_side {
        justify-self: center;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .right_side {
      h2 {
        font-size: 30px !important;
        margin-bottom: 10px !important;
      }
      .grid_container {
        gap: 10px !important;
      }
    }
  }
`;
