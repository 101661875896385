import React, { useEffect } from "react";
import styled from "styled-components";
import {Button} from "antd";
import { Link, useNavigate } from "react-router-dom";
import API from "../../ApiService/ApiService";
import { companyGetAllFail, companyGetAllStart, companyGetAllSuccess } from "../../Redux/slices/company";
import crud_service from "../../ApiService/CrudService";
import { useDispatch, useSelector } from "react-redux";

const HomeContact1 = ({touchwith_us}) => {
  // console.log("touchwith_us", touchwith_us)
  
  const api = new API();
  const crud = new crud_service();
  const dispatch = useDispatch()

  const company = useSelector((state) => state?.company?.company?.data[0]);
  // console.log("company---->", company)


  return (
    <ContactAlign className="custom-contact-align">
      <div className="home_wrapper">
        <div className="contact_Main">
          <div className="Left_Section">
            <div className="Left_Title">
              <p className="Title">
                <span className="Span">
                  {touchwith_us?.banner_id?.banner_title}
                </span>
              </p>
            </div>
            <div className="Left_Mobile_Mail">
              <div className="Left_Mobile">
                <p className="Mobile">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 25 25"
                    fill="none"
                  >
                    <path
                      d="M22.0169 17.1664C21.8497 18.4373 21.2255 19.6038 20.2611 20.4481C19.2966 21.2924 18.0578 21.7568 16.776 21.7545C9.32923 21.7545 3.27052 15.6958 3.27052 8.24902C3.26822 6.96721 3.7326 5.72839 4.57692 4.76394C5.42125 3.79949 6.58777 3.17537 7.85863 3.00814C8.18 2.9689 8.50544 3.03464 8.78637 3.19556C9.0673 3.35648 9.28866 3.60394 9.41739 3.901L11.3982 8.32311V8.33436C11.4968 8.56176 11.5375 8.81002 11.5167 9.05698C11.4959 9.30394 11.4143 9.54191 11.2791 9.74963C11.2622 9.77495 11.2444 9.7984 11.2256 9.82184L9.27296 12.1365C9.97543 13.564 11.4685 15.044 12.9148 15.7483L15.1976 13.806C15.22 13.7871 15.2435 13.7696 15.2679 13.7534C15.4754 13.615 15.7142 13.5305 15.9626 13.5076C16.2111 13.4847 16.4613 13.524 16.6907 13.6221L16.7029 13.6278L21.1212 15.6076C21.4188 15.7359 21.6669 15.9571 21.8283 16.238C21.9898 16.519 22.0559 16.8447 22.0169 17.1664Z"
                      fill="black"
                    />
                  </svg>
                  +91-{company?.mobile_number}
                </p>
              </div>
              <div className="Right_Mobile">
                <p className="Mail">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <path
                      d="M22.75 5.43359H3.25C3.03451 5.43359 2.82785 5.5159 2.67548 5.66242C2.5231 5.80893 2.4375 6.00764 2.4375 6.21484V19.4961C2.4375 19.9105 2.6087 20.3079 2.91345 20.601C3.2182 20.894 3.63152 21.0586 4.0625 21.0586H21.9375C22.3685 21.0586 22.7818 20.894 23.0865 20.601C23.3913 20.3079 23.5625 19.9105 23.5625 19.4961V6.21484C23.5625 6.00764 23.4769 5.80893 23.3245 5.66242C23.1722 5.5159 22.9655 5.43359 22.75 5.43359ZM21.9375 19.4961H4.0625V7.99121L12.4505 15.3848C12.6004 15.5171 12.7965 15.5905 13 15.5905C13.2035 15.5905 13.3996 15.5171 13.5495 15.3848L21.9375 7.99121V19.4961Z"
                      fill="black"
                    />
                  </svg>
                  {" "}{company?.email_id}
                </p>
              </div>
            </div>
            <div className="Left_Location">
              <p className="Location">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 21 21"
                  fill="none"
                >
                  <path
                    d="M10.015 1.4248C8.18956 1.42688 6.43951 2.15293 5.14876 3.44368C3.85801 4.73444 3.13195 6.48448 3.12988 8.30988C3.12988 14.2013 9.38904 18.6508 9.65583 18.837C9.76108 18.9107 9.88646 18.9503 10.015 18.9503C10.1434 18.9503 10.2688 18.9107 10.3741 18.837C10.6409 18.6508 16.9 14.2013 16.9 8.30988C16.898 6.48448 16.1719 4.73444 14.8811 3.44368C13.5904 2.15293 11.8404 1.42688 10.015 1.4248ZM10.015 5.80621C10.5101 5.80621 10.9942 5.95305 11.4059 6.22816C11.8176 6.50326 12.1385 6.89428 12.328 7.35177C12.5175 7.80925 12.5671 8.31265 12.4705 8.79832C12.3739 9.28398 12.1355 9.73009 11.7853 10.0802C11.4352 10.4304 10.9891 10.6688 10.5034 10.7654C10.0177 10.862 9.51433 10.8125 9.05684 10.623C8.59936 10.4335 8.20834 10.1126 7.93323 9.70084C7.65813 9.28911 7.51129 8.80505 7.51129 8.30988C7.51129 7.64586 7.77507 7.00905 8.2446 6.53952C8.71412 6.06999 9.35094 5.80621 10.015 5.80621Z"
                    fill="black"
                  />
                </svg>
                {company?.company_addresss} <br />
                {company?.city} - {company?.pin_code}, {company?.state}.
              </p>
            </div>
            <div className="Left_Button">
              <Link to={touchwith_us?.banner_id?.button_link}>
                <Button className="Button">
                  Get Direction{" "}
                  <svg
                    width="26"
                    height="13"
                    viewBox="0 0 26 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.27051 6.80527H25.1159M25.1159 6.80527C25.1159 6.80527 20.6279 3.85369 19.7159 1.40527M25.1159 6.80527C25.1159 6.80527 21.1483 9.57865 19.7159 12.2053"
                      stroke="#0489D6"
                      strokeLinecap="square"
                    />
                  </svg>
                </Button>
              </Link>
            </div>
          </div>
          <div className="Right_Section">
            <img
              src={api.rootUrl1 + touchwith_us?.banner_id?.image}
              alt="Image"
            />
          </div>
        </div>
      </div>
    </ContactAlign>
  );
};

export default HomeContact1;

const ContactAlign = styled.div`
  &.custom-contact-align {
    width: 100%;
    height: 428.409px;
    flex-shrink: 0;
    background-color: rgba(219, 219, 219, 0.14);
  }

  .contact_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 30px;
  }

  .Left_Section .Left_Title {
    color: #000;
    font-family: Flama Trial;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .Left_Section .Left_Title Span {
    color: #56c20e;
    text-align: center;
    font-family: Flama Trial;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .Left_Section .Left_Mobile_Mail {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .Left_Section .Left_Mobile_Mail .Left_Mobile .Mobile,
  .Left_Section .Left_Mobile_Mail .Right_Mobile .Mail {
    color: #000;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  .Left_Location .Location {
    color: #000;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    /* line-height: 0%; */
  }

  .Left_Button .Button {
    color: #0489d6;
    text-align: center;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    border: none;
    background-color: transparent;
    padding-right: 5px;
  }

  .Right_Section img {
    mix-blend-mode: multiply;
    filter: opacity(1);
  }
`;
