import { createSlice } from "@reduxjs/toolkit";

const specificationdetailsSlice = createSlice({
  name:'specification',
  initialState:{
    specificationdetails:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    specificationdetailsGetAllStart:(state)=>{
      state.isFetching = true;
    },
    specificationdetailsGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.specificationdetails = action.payload;
    },
    specificationdetailsGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {specificationdetailsGetAllStart,specificationdetailsGetAllSuccess,specificationdetailsGetAllFail} = specificationdetailsSlice?.actions;
export default specificationdetailsSlice.reducer;



