import { createSlice } from "@reduxjs/toolkit";

const states = createSlice({
  name: "states",
  initialState: {
    states: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    statesGetAllStart: (state) => {
      state.isFetching = true;
    },
    statesGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.states = action.payload;
    },
    statesGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const { statesGetAllStart, statesGetAllSuccess, statesGetAllFail } =
  states?.actions;
export default states.reducer;
