import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { styles } from "../../ApiService/Data";
import API from "../../ApiService/ApiService";
import Default from "../../Assets/Images/default.png";
import fp1 from "../../Assets/Images/dry/p1.png";
import fp2 from "../../Assets/Images/dry/p2.png";
import hmain from "../../Assets/Images/dry/hmain.png";
import fp3 from "../../Assets/Images/dry/p3.png";
import { base_url } from "../../config";

const FP3 = ({ featuredproduct_type }) => {
  return (
    <>
      <FeatureProductSection>
        <div className="fp_section">
          <div className="wrapper">
            <h2>Featured Products</h2>
            <ul>
              {featuredproduct_type?.slice(0, 8)?.map((item) => {
                const category_name = item?.category[0]?.category_name
                  ?.toLowerCase()
                  .replace(/ /g, "-")
                  .replace(/[^\w-]+/g, "");
                const description = item?.description
                  ?.toLowerCase()
                  .replace(/ /g, "-")
                  .replace(/[^\w-]+/g, "");
                const productId = item?._id;

                const imageUrl = item?.prospecdocs[0]?.product_images[0]
                  ?.image_path
                  ? base_url +
                    item?.prospecdocs[0]?.product_images[0]?.image_path
                  : Default;

                return (
                  <li key={productId}>
                    <span className="hotsale">Hot Sale</span>
                    <div className="fp_box">
                      <div className="fp_image">
                        <Link
                          to={`/${category_name}/${description}?pid=${productId}`}
                        >
                          <img src={imageUrl} alt="Products" />
                        </Link>
                      </div>
                      <div className="fp_content">
                        <h4>{item?.product_name}</h4>
                        <div className="fp_price">
                          <span className="sp">
                            {styles?.currency}
                            {Number(item?.prospecdocs[0]?.sp).toFixed(0)}
                          </span>
                          <span className="mrp">
                            {styles?.currency}
                            {Number(item?.prospecdocs[0]?.mrp).toFixed(0)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </FeatureProductSection>
    </>
  );
};

export default FP3;

const H2 = styled.h2`
  font-size: 32px;
  margin: 0 0 50px;
  font-family: ${styles?.q_bold} !important;
  text-align: center;
  padding: 55px 0 0 0;
  &::before {
    content: "";
    position: absolute;
    background: url(${hmain});
    background-repeat: no-repeat;
    height: 46px;
    width: 46px;
    background-size: contain;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0px);
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;
const FeatureProductSection = styled.section`
  display: inline-block;
  width: 100%;
  position: relative;

  .fp_section {
    width: 100%;
    display: inline-block;
    position: relative;
  }

  ul {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 35px 25px;
  }
  ul li {
    border: 1px solid #ebebeb;
    width: 100%;
    display: inline-block;
    padding: 24px 20px;
    border-radius: 5px;
    text-align: center;
    position: relative;
  }
  ul li .fp_box,
  ul li .fp_box .fp_image {
    width: 100%;
    display: inline-block;
  }
  ul li .fp_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  ul li .fp_box .fp_image img {
    width: 100%;
  }
  ul li .fp_content {
    width: 100%;
    display: inline-block;
    position: relative;
  }
  ul li .fp_content h4 {
    font-size: 18px;
    font-family: ${styles?.q_medium} !important;
    line-height: 1.4;
  }
  ul li .fp_content .fp_price {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
    width: fit-content;
    margin: auto;
  }
  ul li .fp_content span.sp {
    font-size: 20px;
    font-family: ${styles?.q_bold};
  }
  ul li .fp_content span.mrp {
    text-decoration: line-through;
  }
  .hotsale {
    position: absolute;
    top: 13px;
    left: 13px;
    height: 45px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: ${styles?.themegreen};
    border-radius: 100%;
    line-height: 1.1;
    color: #fff;
    font-size: 12px;
    font-family: ${styles?.q_medium};
  }

  @media screen and (max-width: 992px) {
    ul {
      grid-template-columns: repeat(3, 1fr);
      gap: 35px 15px;
    }
  }

  @media screen and (max-width: 768px) {
    ul {
      grid-template-columns: repeat(2, 1fr);
      gap: 35px 15px;
    }
  }

  @media screen and (max-width: 480px) {
    ul li .fp_content h4 {
      font-size: 15px;
    }
    .hotsale {
      height: 35px;
      width: 35px;
      font-size: 10px;
    }
  }
`;
