import { createSlice } from "@reduxjs/toolkit";

const wiseListSlice = createSlice({
  name: "wishList",
  initialState: {
     wiseList:[],
    isFetching: false,
    error: false,
  },
  reducers: {
    wiseListStart: (state) => {
      state.isFetching = true
      // return{
      //   ...state,
      //   isFetching: true
      // }
    },
    wiseListSuccess: (state, action) => {
      state.isFetching = false
      state.cart = action.payload
      // return{
      //   ...state,
      //   isFetching: false,
      //   wiseList: action.payload
      // }
    },
    wiseListFailure: (state, action) => {
      state.isFetching = false
      state.error = true;
      // return{
      //   ...state,
      //   isFetching: false,
      //   error: action.payload
      // }
    },
   
  },
});

export const { wiseListStart, wiseListSuccess, wiseListFailure } = wiseListSlice.actions;
export default wiseListSlice.reducer
