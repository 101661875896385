import React from "react";
import Logo from '../Assets/Images/logo.png'
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user)?.setColor;

export const styles = {
  
  appColor: "#ee8f0a",
  background1: "#1890ff",
  gray: "#888",
  background: "#FAFAFA",
  h1: "35px",
  h2: "30px",
  p: "16px",
  border: "#d9d9d9e6",
  light: "#f2f2f2",
  color: "#000",
  currency: "₹",
  bg30: "#f8f8f8",
  bg60:"rgb(0 0 0 / 60%)",
  bg80: "rgb(0 0 0 / 80%)",
  bannergradient:"linear-gradient(to bottom, rgb(0 0 0 / 70%), transparent)",
  white70:"rgb(255 255 255 / 70%)",
  h1: '37px',
  h2: '33px',
  h3: '29px',
  h4: '34px',
  h5: '20px',
  h6: '16px',
  p: '16px',
  white:"#ffff",
  black: "f_black",
  extrabold: "f_extra_bold",
  bold: "f_bold",
  regular: "f_regular",
  medium: "f_medium",
  font:colorCustom?.fontfamily,
  colorapi:"red",
  color1:"rgb(153 206 85 / 12%)",
  color2:"rgb(247 97 194 / 12%)",
  color3:"rgb(235 187 51 / 12%)",
  color4:"rgb(163 43 41 / 12%)",
  color5:"rgb(36 112 112 / 12%)",
  bgcolorlight:"#f8f8f8",
  r_bold:"r_bold",
  r_italic:"r_italic",
  r_light:"r_light",
  r_regular:"r_regular",
  q_bold:"q_bold",
  q_medium:"q_medium",
  q_light:"q_light",
  q_regular:"q_regular",
  q_semibold:"q_semibold",
  a_bold:"a_bold",
  a_medium:"a_medium",
  a_light:"a_light",
  a_regular:"a_regular",
  a_semibold:"a_semibold",
  themebg:"#f4f4f4",
  themeblue:"#1c5fa8",
  themegreen:"#86bc42",
  themered:"#CF3E3B",
};

export const header = {
  title: "Logo",
  image: Logo,
  background: "#1890ff",
  gray: "#888",
};

export default styles;



