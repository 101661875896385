import React from "react";
import Footer1 from "../Footer/Footer1";
import Footer2 from "../Footer/Footer2";
import Footer3 from "../Footer/Footer3";
import Footer4 from "../Footer/Footer4";
import Footer5 from "../Footer/Footer5";
import Footer6 from "../Footer/Footer6";
import Footer7 from "../Footer/Footer7";
import Footer9 from "../Footer/Footer9";
import Footer8 from "../Footer/Footer8";
import Footer10 from "../Footer/Footer10";
import Footer11 from "../Footer/Footer11";
import Footer12 from "../Footer/Footer12";
import Footer13 from "../Footer/Footer13";

import { useSelector } from "react-redux";

const FooterMain = ({footer_type, company, socialmedia, home_custom, content_all}) => {
  // console.log("home_custom", home_custom)

  switch (footer_type) {
    // case "Theme-1":
    //   return <Footer1 company={company} socialmedia={socialmedia} />;
    // case "Theme-2":
    //   return <Footer2 company={company} socialmedia={socialmedia} />;
    // case "Theme-3":
    //   return <Footer3 company={company} socialmedia={socialmedia} />;
    // case "Theme-4":
    //   return <Footer4 company={company} socialmedia={socialmedia} />;
    // case "Theme-5":
    //   return <Footer5 company={company} socialmedia={socialmedia} />;
    // case "Theme-6":
    //   return <Footer6 company={company} socialmedia={socialmedia} />;
    // case "Theme-1":
    //   return <Footer7 company={company} socialmedia={socialmedia} />;
    // case "Theme-2":
    //   return <Footer8 company={company} socialmedia={socialmedia} />;
    // case "Theme-3":
    //   return <Footer9 company={company} socialmedia={socialmedia} />;
    // case "Theme-4":
    //   return <Footer10 company={company} socialmedia={socialmedia} home_custom={home_custom?.theme_4?.category} />;
    // case "Theme-5":
    //   return <Footer11 company={company} socialmedia={socialmedia} />;
    case "Footer7":
      return <Footer7 company={company} socialmedia={socialmedia} content_all={content_all?.Footer7} />;
    case "Footer8":
      return <Footer8 company={company} socialmedia={socialmedia} content_all={content_all?.Footer8} />;
    case "Footer9":
      return <Footer9 company={company} socialmedia={socialmedia} />;
    case "Footer10":
      return <Footer10 company={company} socialmedia={socialmedia} home_custom={home_custom?.theme_4?.category} content_all={content_all?.Footer10} />;
    case "Footer11":
      return <Footer11 company={company} socialmedia={socialmedia} />;
    case "Footer12":
      return <Footer12 company={company} socialmedia={socialmedia}  content_all={content_all?.Footer12} />; //one Wear footer
    case "Footer13":
      return <Footer13 company={company} socialmedia={socialmedia} home_custom={home_custom?.theme_4?.category}  content_all={content_all?.Footer13} />; //poornima footer
    default:
      return null;
      // default:
    //   return <Footer10 company={company} socialmedia={socialmedia} />;
  }
};

export default FooterMain;
