export const font = {
  bold:'var(--bold)',
  semibold:'var(--semibold)',
  medium:'var(--medium)',
  regular:'var(--regular)',
  light:'var(--light)',
};
export const theme = {
  
  bg:"#ed8f09",
  bg10:"rgb(237 143 9 / 10%)",
  bg20:"rgb(237 143 9 / 20%)",
  bg30:"rgb(237 143 9 / 30%)",
  bg40:"rgb(237 143 9 / 40%)",
  bg50:"rgb(237 143 9 / 50%)",
  bg60:"rgb(237 143 9 / 60%)",
  bg70:"rgb(237 143 9 / 70%)",
  bg80:"rgb(237 143 9 / 80%)",
  bg90:"rgb(237 143 9 / 90%)",
  bg100:"rgb(237 143 9 / 100%)",
  gray:"#888",
  medium_gray:"#d9d9d9",
  light_gray:"#f5f5f5",
  black:"#000",
  color:'#000',
  while:"#fff",
};