import React, { useState } from 'react'
import styled from 'styled-components';

import { Steps, Button, Divider } from 'antd'

import BillingAddress from './BillingAddress';
import PriceList from './PriceList';
import CartList from './CartList';

const { Step } = Steps;

const Checkout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(0);

  const onChange = (c) => {
    setCurrent(c);
  }

  const orderPlace = () => {

  }

  const content = (index) => {
    switch (index) {
      case 1:
        return <BillingAddress />;
      case 2:
        return <PriceList />;
      default:
        return <CartList />;
    }
  };
  
  const action = (index) => {
    switch (index) {
      case 0:
        return (
          <>
            <Button type="primary" onClick={() => onChange(1)}>
              Next
            </Button>
          </>
        );
      case 1:
        return (
          <>
            <div className='checkout_btn'>
              <Button type="dashed" onClick={() => onChange(0)}>
                Previous
              </Button>
              <Button type="primary" onClick={() => onChange(2)}>
                Next
              </Button>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className='checkout_btn'>
              <Button type="dashed" onClick={() => onChange(1)}>
                Previous
              </Button>
              <Button
                isLoading={isLoading}
                type="primary"
                onClick={() => orderPlace()}
              >
                Pay now
              </Button>
            </div>
          </>
        );
      default:
        return <CartList />;
    }
  };
  return (
    <CheckoutSection>
      <div className='checkout_page'>
      <div className='wrapper'>
        <h1>Checkout</h1>
        <div className='step_section'>
          <div className='step_head'>
          <Steps
            size="small"
            current={current}
            onChange={onChange}
            responsive
            className='step_form'
          >
            <Step title="Item Summary" />
            <Step title="Delivery Address" />
            <Step title="Payment" />
          </Steps>
          </div>
          {content(current)}
          <Divider />
          {action(current)}
        </div>
      </div>
    </div>
    </CheckoutSection>
  )
}

export default Checkout


const CheckoutSection = styled.section`
  width: 100%;
  margin: 60px 0;
  display: inline-block;
  position: relative;

  h1 {
    margin: 0 0 30px;
  }

  .step_head {
    margin: 0 auto 30px;
    max-width: 60%;
  }

  .checkout_btn {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
`;