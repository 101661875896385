import { createSlice } from "@reduxjs/toolkit";

const specificationSlice = createSlice({
  name:'specification',
  initialState:{
    specification:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    specificationGetAllStart:(state)=>{
      state.isFetching = true;
    },
    specificationGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.specification = action.payload;
    },
    specificationGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {specificationGetAllStart,specificationGetAllSuccess,specificationGetAllFail} = specificationSlice?.actions;
export default specificationSlice.reducer;



