import React from 'react'
import Contact1 from "./ContactUs/Contact1"
import Contact2 from "./ContactUs/Contact2"
import Contact3 from "./ContactUs/Contact3"
import Contact4 from "./ContactUs/Contact4"
import Contact5 from "./ContactUs/Contact5"

import { useSelector } from "react-redux";

const ContactUsMain = () => {
    const contactData = useSelector((state) => state?.contactus?.contactus)

    const contact_type =""
    switch (contact_type) {
        case "C1":
            return <Contact1 contactData={contactData}/>
        case "C2":
            return <Contact2 contactData={contactData}/>
        case "C3":
            return <Contact3 contactData={contactData}/>
        case "C4":
            return <Contact4 contactData={contactData}/>
        case "C5":
            return <Contact5 contactData={contactData}/>
        default:
            return <Contact1 contactData={contactData}/>;
    }
}

export default ContactUsMain