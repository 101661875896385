import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import API from "../../../ApiService/ApiService";
import { message, Row, Col, Divider, Button, Card } from "antd";
import styled from "styled-components";
import { ArrowRightOutlined } from "@ant-design/icons";
import SwiperCore, { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/virtual';
import styles from "../../../ApiService/Data";
const { Meta } = Card;

const BlogDetail = () => {
  const api = new API();
  const navigate = useNavigate();
  const [blogDetail, setBlogDetail] = useState({});
  const [blog, setBlog] = useState([]);
  const params = useParams();

  useEffect(() => {
    getSingleBlog();
    getAllBlogs();
  }, []);

  const getSingleBlog = () => {
    api.getSingleblogs(params?.id).then((res) => {
      // console.log("res--->", res);
      setBlogDetail(res?.data?.data);
    });
  };

  const getAllBlogs = () => {
    api.getblogs().then((res) => {
      // console.log("blog----->", res);
      setBlog(res?.data?.data);
    });
  };

  SwiperCore.use([Virtual]);

  const description = blogDetail?.description;

  const handleBlogClick = (id) => {
    // console.log("id", id);
    navigate(`/blog/${id}`);
    window.location.reload();
  };

  return (
    <>
      <BlogDetailContainer>
        <Title>
          <h1>{blogDetail?.title}</h1>
        </Title>
        <Content>
          <ImageContainer>
            <img src={api.rootUrl1 + blogDetail?.image} alt="Blog Image" />
          </ImageContainer>
          <Description>
            <div
              className="blog_content"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Description>
        </Content>
        <Divider />
      </BlogDetailContainer>

      <RelatedBlogs>
        <div className="title">
            <h3>Related Blogs</h3>
        </div>
        <Swiper spaceBetween={50} slidesPerView={3} virtual scrollbar={{ draggable: true }}>
          {blog?.map((item) => (
            <SwiperSlide key={item?._id} virtualIndex={item?._id}>
              <StyledCard hoverable>
                <CardContent>
                  <CardImageWrapper>
                    <img src={api.rootUrl1 + item?.image} alt="Blog Image" />
                  </CardImageWrapper>
                  <div className="description">
                    <Meta
                      title={item?.title}
                      description={item?.short_description}
                    />
                  </div>
                  <Divider />
                  <Button
                    className="Blog_Button"
                    onClick={() => handleBlogClick(item?._id)}
                  >
                    Continue Reading <ArrowRightOutlined />
                  </Button>
                </CardContent>
              </StyledCard>
            </SwiperSlide>
          ))}
        </Swiper>
      </RelatedBlogs>
    </>
  );
};

export default BlogDetail;

const BlogDetailContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
`;

const RelatedBlogs = styled.div`
 gap: 20px;
 
 
.title {
  text-align: center;
  margin-bottom: 20px;

}
  .swiper-container {
    padding: 20px;
  }

  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
`;

const Title = styled.div`
  text-align: center;
  margin-bottom: 20px;

  h1 {
    font-size: 36px;
    font-weight: bold;
  }
`;

const StyledCard = styled(Card)`
  width: 350px;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .description {
    flex: 1;
    max-height: 50px;
    overflow: hidden;
  }
`;

const CardImageWrapper = styled.div`
  img {
    width: 100% !important;
    height: 50% !important;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageContainer = styled.div`
  img {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 20px;
  }
`;

const Description = styled.div`
  .blog_content {
    font-size: 18px;
    line-height: 2;
    text-align: justify;
    color: ${styles?.gray};
  }

  .blog_content h2 {
    color: #000;
  }

  .blog_content li {
    list-style: circle;
  }
`