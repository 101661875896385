import React from "react";
import styled from "styled-components";
import "../../Assets/Css/style.css";
import {Button} from "antd";

const QuestionBanner = ({contact, content_all}) => {
  // console.log("contact--->", content_all);

  return (
    <QuestionBannerAlign>
      <div className="home_wrapper">
        <div className="Question_Banner">
          <div className="banner_content">
            <div className="Left_Section">
              <p className="Title">{content_all?.smile_mail_heading}</p>
              <svg
                className="svg"
                xmlns="http://www.w3.org/2000/svg"
                width="273"
                height="30"
                viewBox="0 0 273 35"
                fill="none"
              >
                <path
                  d="M2 29.6388C51.5 19.3054 174.8 0.138783 272 6.13878"
                  stroke="#F6A82D"
                  strokeWidth="10"
                />
              </svg>
            </div>
            <div className="Middle_Section">
              <p className="Mail_Title">email us @</p>
              <p className="Mail">{content_all?.smile_mail_link}</p>
            </div>
            <div className="Right_Section">
              <Button className="Call_Button"><a style={{textDecoration: "none"}} href={`mailto:${content_all?.smile_mail_link}`}>Mail us</a></Button>
            </div>
          </div>
        </div>
      </div>
    </QuestionBannerAlign>
  );
};

export default QuestionBanner;

const QuestionBannerAlign = styled.div`
padding-top: 100px;
margin-top: 20px;
  .Question_Banner {
    width: 100%;
    padding: 20px;
    flex-shrink: 0;
    background: #ccebff;
  }

  .banner_content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
  }

  .banner_content .Left_Section .Title {
    color: #000;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
    text-transform: uppercase;
  }

  .svg {
    margin-left: 120px;
    margin-top: -40px;
  }

  .Middle_Section .Mail_Title {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }

  .Middle_Section .Mail {
    color: #000;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }

  .Call_Button {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #f6a82d;
    color: #fafafa;
    border-radius: 0 !important;
    text-decoration: uppercase;
  }

  .Call_Button:focus {
    background: #f6a82d;
    color: #fafafa;
  }

  .Call_Button:hover {
    background: #f6a82d !important;
    color: #fafafa !important;
  }

  @media screen and (max-width: 767px) {
    .Question_Banner {
      width: 100%;
    }

    .banner_content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    .banner_content .Left_Section .Title {
      font-size: 28px;
      margin-left: 0; /* Adjust margin for smaller screens */
    }

    .svg {
      margin-left: 0; /* Adjust margin for smaller screens */
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .Question_Banner {
      width: 100%;
    }

    .banner_content {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    .banner_content .Left_Section .Title {
      font-size: 28px;
      margin-left: 0;
    }

    .svg {
      margin-left: 0;
    }
  }
`;
