import React, { useState, useEffect } from "react";
import styled from "styled-components";
import b1 from "../../Assets/Images/Agri/b1.png";
import b2 from "../../Assets/Images/Agri/b2.png";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { styles } from "../../ApiService/Data";
import API from "../../ApiService/ApiService";
import DefaultImg from "../../Assets/Images/default.png";
import { base_url } from "../../config";

const Hc25 = ({ props }) => {
  return (
    <>
      <Hc1Section>
        <div className="hc1_section">
          <div className="wrapper">
            <ul>
              {props?.content?.map((item) => {
                return (
                  <li>
                    <div className="hc1_box">
                      <div className="left">
                        <img
                          src={
                            item?.image ? base_url + item?.image : DefaultImg
                          }
                          alt="Product"
                        />
                      </div>
                      <div className="right">
                        <h4>{item?.title}</h4>
                        <h5>{item?.sub_title}</h5>
                        <Link
                          to={item?.link
                            .toLowerCase()
                            .replace(/ /g, "-")
                            .replace(/[^\w-]+/g, "")}
                        >
                          <Button>Shop Now</Button>
                        </Link>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </Hc1Section>
    </>
  );
};

export default Hc25;

const Hc1Section = styled.section`
  * {
    font-family: ${styles?.r_regular};
  }
  width: 100%;
  display: inline-block;
  position: relative;
  .hc1_section {
    display: inline-block;
    width: 100%;
    position: relative;
  }

  .hc1_section ul {
    display: grid;
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 45px;
  }
  .hc1_section ul li {
    background: #dbf0e2;
    padding: 35px 30px;
    width: 100%;
    border-radius: 14px;
    display: grid;
    align-items: center;
  }
  .hc1_section ul li .hc1_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .hc1_section ul li .hc1_box .left {
    width: 47%;
    display: inline-block;
  }
  .hc1_section ul li .hc1_box .right {
    width: 47%;
    display: flex;
    gap: 15px;
    text-align: center;
    flex-direction: column;
  }
  .hc1_section ul li .hc1_box .right h4 {
    font-size: 30px;
    text-transform: uppercase;
    margin: 0 !important;
    font-family: ${styles?.r_bold} !important;
  }
  .hc1_section ul li .hc1_box .right h5 {
    margin: 0 !important;
    font-size: 18px;
    font-family: ${styles?.r_regular};
  }
  .hc1_section ul li .hc1_box .right button {
    background: #4abb6d;
    border: 1px solid #4abb6d;
    border-radius: 35px;
    color: #fff;
    padding: 5px 16px;
    height: auto;
    font-size: 15px;
  }

  @media screen and (max-width: 768px) {
    .hc1_section ul {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (max-width: 480px) {
    .hc1_section ul li .hc1_box {
      flex-direction: column;
      gap: 40px;
    }
    .hc1_section ul li .hc1_box .right {
      width: 100%;
    }
    .hc1_section ul li .hc1_box .left {
      width: 100%;
      img {
        margin: auto;
        padding: 0 50px;
      }
    }
  }
`;
