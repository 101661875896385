import { createSlice } from "@reduxjs/toolkit";

const contactus = createSlice({
  name:'contactus',
  initialState:{
    contactus:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    contactusGetAllStart:(state)=>{
      state.isFetching = true;
    },
    contactusGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.contactus = action.payload;
    },
    contactusGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {contactusGetAllStart,contactusGetAllSuccess,contactusGetAllFail} = contactus?.actions;
export default contactus.reducer;



