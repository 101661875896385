import React from 'react'
import styled from "styled-components";
import Marquee from "react-fast-marquee";

const Marquee1 = ({content_all}) => {
  console.log("Marquee1--->", content_all);
  return (
    <BannerAlign>
        <div className="Offer_Section">
        <Marquee
          play={true}
          pauseOnHover={true}
          speed={50}
          direction="left"
          className="marquee"
        >
          <span>            
            <div
              className="text"             
            >
                {/* SHOP NOW */}
                {content_all?.onewear_marquee_text}
            </div>
          </span>
          <span>           
            <div
              className="text1"              
            > 
            {/* SHOP NOW */}
            {content_all?.onewear_marquee_text}
            </div>
          </span>
          <span>           
            <div
              className="text"
            >
               {/* SHOP NOW */}
               {content_all?.onewear_marquee_text}
            </div>
          </span>
          <span>            
            <div
              className="text1"             
            > 
            {content_all?.onewear_marquee_text}
            {/* SHOP NOW */}
            </div>
          </span>
          <span>            
            <div
              className="text"
            > 
            {content_all?.onewear_marquee_text}
            {/* SHOP NOW */}
            </div>
          </span>
          <span>            
            <div
              className="text1"             
            > 
            {content_all?.onewear_marquee_text}
            {/* SHOP NOW */}
            </div>
          </span>
          <span>            
            <div
              className="text"            
            > 
            {content_all?.onewear_marquee_text}
            {/* SHOP NOW */}
            </div>
          </span>
          <span>            
            <div
              className="text1"              
            > 
            {content_all?.onewear_marquee_text}
            {/* SHOP NOW */}
            </div>
          </span>
        </Marquee>
      </div>
    </BannerAlign>
  )
}

export default Marquee1;

const BannerAlign = styled.div`
    padding-bottom: 80px;
    .Offer_Section {
    /* padding-top: 5px; */
    width: 100%;
    height: 97px;
    flex-shrink: 0;
    background: #ffffff;
    color: #000;
    display: grid;
    place-items: center;
    /* border: 1px solid red; */
  }

  .marquee span {
    padding: 0 20px;
  }  

  .Offer_Section {
    .rfm-child {
      span {
        display: flex;
        .text {
          margin: 0px;
          font-family: Inter;
          font-size: 80px;
          font-weight: 900;
          color: #ffffff; /* Inner color */
            text-shadow: 
                -1px -1px 0 #727272,  
                1px -1px 0 #727272,
                -1px 1px 0 #727272,
                1px 1px 0 #727272;
        }
        .text1 {
            margin: 0px;
            font-family: Inter;
          font-size: 80px;
          font-weight: 900;
          color: #E96027; 
        }
        }
      }
    } 
  
`;