import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "../../Assets/Css/style.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/dyuken logo.png";
import { Input, Badge, Drawer, Space, Divider } from "antd";
import {
  CloseOutlined,
  MenuOutlined,
  MobileFilled,
  SearchOutlined,
} from "@ant-design/icons";
import API from "../../ApiService/ApiService";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../Redux/slices/user";

let cart = 0;

const Header9 = ({ home_custom }) => {
  // console.log("Header9--->", home_custom);
  // const [cart, setCart] = useState(0);
  const [wiseList, setWiseList] = useState([]);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [search, setSearch] = useState("");

  let token = localStorage.getItem("token") || "";
  let login = localStorage.getItem("login") || false;
  let userData = localStorage.getItem("user") || null;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const api = new API();

  const logout = () => {
    //data of the user will be deleted from local storage after logout and also set null value to userSlice
    dispatch(logoutUser(null));
    // dispatch(emptyRemoveAddress(null));
    // dispatch(emptyUpdateAddress(null));
    // dispatch(emptyUserAddress(null));
    // localStorage.setItem("user", "");
    // localStorage.setItem("token", "");
    // localStorage.setItem("login", false);
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    getAllCart();
    getAllWiseList();
  }, []);

  const getAllCart = () => {
    api
      .getcart()
      .then((res) => {
        // console.log("res", res);
        cart = res?.data?.data?.cart_item?.length;
      })
      .catch((err) => {
        console.log("err", err);
        cart = 0;
      });
  };
  const getAllWiseList = () => {
    api
      .getWishList()
      .then((res) => {
        setWiseList(res?.data?.data);
      })
      .catch((err) => {
        setWiseList([]);
      });
  };

  const topOffer = home_custom?.offer_text;

  const handleSearch = () => {
    if (search !== "") {
      navigate(`/products?product_name=${search}`);
    }
  };

  return (
    <HeaderAlign>
      <div className="home_wrapper">
        <div className="Top_Bar">
          <div
            className="Left_Section"
            style={{ color: "white" }}
            dangerouslySetInnerHTML={{ __html: topOffer }}
          ></div>
          <div className="Right_Section">
            <ul className="List">
              {home_custom?.menus?.map((item, index) => (
                <Link to={`/${item?.link}`} key={index}>
                  <li>{item?.link_text}</li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
        <div className="Header_Main">
          <div className="Header_Left">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="Header_Middle">
            <div className="Search_bar">
              <Input
                placeholder="Search here..."
                defaultValue={search}
                prefix={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    onClick={()=>handleSearch()}
                  >
                    <path
                      d="M10.5072 17.7389C14.5573 17.7389 17.8405 14.4557 17.8405 10.4056C17.8405 6.35551 14.5573 3.07227 10.5072 3.07227C6.45707 3.07227 3.17383 6.35551 3.17383 10.4056C3.17383 14.4557 6.45707 17.7389 10.5072 17.7389Z"
                      stroke="#272343"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.674 19.5723L15.6865 15.5848"
                      stroke="#272343"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
                onChange={(e) => setSearch(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key == "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
          </div>
          <div className="Header_Right">
            {login && token && userData ? (
              <div className="Icons">
                <div className="Profile">
                  <Link to="/my-profile">
                    <svg
                      className="svg_profile"
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.1702 14.2234C7.62449 14.2234 4.59766 14.7596 4.59766 16.9065C4.59766 19.0533 7.60524 19.6088 11.1702 19.6088C14.7149 19.6088 17.7427 19.0716 17.7427 16.9257C17.7427 14.7798 14.7342 14.2234 11.1702 14.2234V14.2234Z"
                        stroke="#272343"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.1701 11.1617C12.0033 11.1617 12.8178 10.9146 13.5107 10.4517C14.2035 9.98877 14.7435 9.33079 15.0624 8.56096C15.3812 7.79114 15.4647 6.94405 15.3021 6.1268C15.1395 5.30956 14.7383 4.55888 14.1491 3.96968C13.5599 3.38048 12.8092 2.97923 11.992 2.81667C11.1747 2.65411 10.3276 2.73754 9.55781 3.05642C8.78799 3.37529 8.13001 3.91528 7.66708 4.6081C7.20415 5.30093 6.95706 6.11547 6.95706 6.94872C6.95316 8.06203 7.39163 9.1313 8.17602 9.92137C8.96041 10.7114 10.0265 11.1576 11.1398 11.1617H11.1701Z"
                        stroke="#272343"
                        strokeWidth="1.429"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </div>
                <div className="Wishlist">
                  <Link to="/wish-list">
                    <svg
                      className="svg_wishlist"
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      viewBox="0 0 23 23"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.81658 10.7956C1.83299 7.72474 2.98158 4.21483 6.20641 3.17625C7.04263 2.90867 7.93072 2.84494 8.79658 2.99037C9.66245 3.13579 10.481 3.48616 11.1839 4.01225C12.5177 2.981 14.4582 2.63266 16.1522 3.17625C19.3762 4.21483 20.533 7.72474 19.5503 10.7956C18.0195 15.6631 11.1839 19.4122 11.1839 19.4122C11.1839 19.4122 4.39874 15.7199 2.81658 10.7956V10.7956Z"
                        stroke="#272343"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link>
                </div>
                <div className="Cart">
                  <Link to="/cart" style={{ textDecoration: "none" }}>
                    <Badge offset={[0, 30]} className="Cart_Badge">
                      <div className="Cart_Icon">
                        <svg
                          className="svg_Cart"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.56445 3.13934L4.47112 3.46934L5.35387 13.9863C5.38778 14.4001 5.57648 14.786 5.88231 15.0668C6.18815 15.3477 6.58864 15.5029 7.00387 15.5015H17.0047C17.4023 15.502 17.7867 15.3588 18.0871 15.0984C18.3876 14.8381 18.5839 14.478 18.64 14.0843L19.5109 8.07284C19.5341 7.91297 19.5256 7.75008 19.4858 7.59351C19.446 7.43693 19.3757 7.28973 19.279 7.16033C19.1823 7.03092 19.061 6.92185 18.9221 6.83935C18.7832 6.75684 18.6294 6.70253 18.4695 6.67951C18.4109 6.67309 4.77729 6.66851 4.77729 6.66851"
                            stroke="#272343"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.9912 10.0556H15.5331"
                            stroke="#272343"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.60131 18.6796C6.66854 18.6767 6.73565 18.6875 6.79861 18.7112C6.86157 18.7349 6.91907 18.7712 6.96766 18.8177C7.01625 18.8643 7.05491 18.9202 7.08133 18.982C7.10774 19.0439 7.12136 19.1105 7.12136 19.1778C7.12136 19.2451 7.10774 19.3117 7.08133 19.3736C7.05491 19.4354 7.01625 19.4913 6.96766 19.5379C6.91907 19.5844 6.86157 19.6207 6.79861 19.6444C6.73565 19.6682 6.66854 19.6789 6.60131 19.676C6.47288 19.6705 6.35153 19.6156 6.2626 19.5228C6.17367 19.4299 6.12402 19.3064 6.12402 19.1778C6.12402 19.0493 6.17367 18.9257 6.2626 18.8328C6.35153 18.74 6.47288 18.6851 6.60131 18.6796Z"
                            fill="#272343"
                            stroke="#272343"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.942 18.6796C17.0745 18.6796 17.2015 18.7322 17.2952 18.8259C17.3889 18.9196 17.4415 19.0467 17.4415 19.1792C17.4415 19.3117 17.3889 19.4387 17.2952 19.5324C17.2015 19.6261 17.0745 19.6788 16.942 19.6788C16.8095 19.6788 16.6824 19.6261 16.5887 19.5324C16.495 19.4387 16.4424 19.3117 16.4424 19.1792C16.4424 19.0467 16.495 18.9196 16.5887 18.8259C16.6824 18.7322 16.8095 18.6796 16.942 18.6796Z"
                            fill="#272343"
                            stroke="#272343"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="Text">
                        <span className="cart_text">Cart</span>
                      </div>
                      <span style={{ marginLeft: "10px" }}>{cart}</span>
                    </Badge>
                  </Link>
                </div>
                <div className="logout" onClick={() => logout()}>
                  <div className="Text">
                    <span className="cart_text">Logout</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="LoginContainer">
                <Link
                  to="/login"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="login">
                    <div className="Text">
                      <span className="cart_text">Login</span>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="Header_Menu">
          <div className="Menu_List">
            <ul className="List">
              <Link to="/" style={{ textDecoration: "none" }}>
                <li>Home</li>
              </Link>
              <Link to="/about" style={{ textDecoration: "none" }}>
                <li>
                  About Us{" "}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="9"
                    height="6"
                    viewBox="0 0 9 6"
                    fill="none"
                  >
                    <path
                      d="M1.34375 1.0625L4.60458 4.29562L7.69586 1.0625"
                      stroke="#141414"
                      strokeWidth="1.32748"
                      strokeLinecap="round"
                    />
                  </svg> */}
                </li>
              </Link>
              <Link to="/contact" style={{ textDecoration: "none" }}>
                <li>Contact Us</li>
              </Link>
              {/* <li>Blog</li> */}
              <Link to="/products" style={{ textDecoration: "none" }}>
                <li>Products</li>
              </Link>
              {/* <li>Kitchen & dinning</li> */}
            </ul>
          </div>
        </div>

        <Drawer
          open={mobileMenu}
          onClose={() => setMobileMenu(false)}
          placement="bottom"
          width="100%"
          height="100%"
          destroyOnClose
          closable
          bodyStyle={{
            backgroundColor: "#eceaea",
            fontSize: "18px",
            color: "#141414",
          }}
        >
          <div className="Top_Bar">
            <div className="Left_Section">
              <p
                className="Title"
                style={{
                  background: "#0489d6",
                  color: "#fff",
                  padding: "10px 20px",
                  margin: 0,
                  borderRadius: "5px",
                }}
              >
                Free shipping on all orders over 50% off
              </p>
            </div>
            <div className="Right_Section">
              <ul
                className="List"
                style={{ margin: "10px 0", listStyle: "none" }}
              >
                <Link
                  to="/about"
                  onClick={() => setMobileMenu(false)}
                  style={{
                    textDecoration: "none",
                    color: "#141414",
                    listStyleType: "circle",
                  }}
                >
                  <li>Home</li>
                </Link>
                <Link
                  to="/about"
                  onClick={() => setMobileMenu(false)}
                  style={{
                    textDecoration: "none",
                    color: "#141414",
                    listStyleType: "circle",
                  }}
                >
                  <li>Products</li>
                </Link>
                <Link
                  to="/about"
                  onClick={() => setMobileMenu(false)}
                  style={{
                    textDecoration: "none",
                    color: "#141414",
                    listStyleType: "circle",
                  }}
                >
                  <li>About Us</li>
                </Link>
                <Link
                  to="/contact"
                  onClick={() => setMobileMenu(false)}
                  style={{
                    textDecoration: "none",
                    color: "#141414",
                    listStyleType: "circle",
                  }}
                >
                  <li>Contact us</li>
                </Link>
              </ul>
            </div>
          </div>
          <Divider />
        </Drawer>
      </div>
      <div className="container">
        <div className="Mobile_Menu" onClick={() => setMobileMenu(true)}>
          <MenuOutlined />
        </div>
      </div>
    </HeaderAlign>
  );
};

export default Header9;

const HeaderAlign = styled.div`
  .Top_Bar {
    width: 100% !important;
    /* height: 46px; */
    flex-shrink: 0;
    background: #0489d6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px 0 20px;
  }

  //Drawer

  .Top_Bar .Left_Section .Title {
    color: #fff;
    font-family: Flama Trial;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 15.4px */
    text-transform: capitalize;
  }

  .DrawerContent Link {
    text-decoration: none;
    color: #141414;
  }

  .Top_Bar .Right_Section .List {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  .Top_Bar .Right_Section .List li {
    color: #fff;
    font-family: Flama Trial;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .Header_Main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 10px;
    background: #f6f6f6;
  }

  .Header_Main .Header_Left .logo {
    width: 150.367px;
    /* height: 48.664px; */
    flex-shrink: 0;
  }

  .Mobile_Menu {
    display: none;
  }

  .Header_Main .Header_Middle .Search_bar Input {
    width: 413px;
    height: 30px;
    flex-shrink: 0;
    border: none !important;
    outline: none;
    border-radius: 5px;
    background: var(--gray-scales-white, #fff);
  }

  :where(.css-dev-only-do-not-override-14wwjjs) .ant-input-affix-wrapper:hover,
  :where(.css-dev-only-do-not-override-14wwjjs) .ant-input-affix-wrapper:focus {
    /* ... other styles ... */
    border: none !important;
    outline: none !important;
  }

  :where(.css-dev-only-do-not-override-14wwjjs).ant-input-affix-wrapper {
    position: relative;
    display: inline-flex;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    border: none !important;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    background-color: #ffffff;
    background-image: none;
    border-radius: 6px;
    transition: all 0.2s;
    outline: none;
  }

  .ant-input-prefix {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 6px;
    left: auto;
    right: 10px;
    z-index: 1;
  }
  .Header_Main .Header_Right {
    width: 25%;
  }

  .Header_Main .Header_Right .Icons {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .Header_Main .Header_Right .LoginContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Profile,
  .Wishlist {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 8px 0px;
    background: var(--gray-scales-white, #fff);
    display: grid;
    place-content: center;
  }

  .Profile .svg_profile {
    flex-shrink: 0;
    color: #272343;
  }

  .Cart {
    cursor: pointer;
    width: 111.817px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 8px 0px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-inline: 10px;
  }

  .Cart .Text {
    padding-left: 10px;
    color: #272343;
    text-align: center;
    font-family: Flama Trial;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 15.4px */
    text-transform: capitalize;
  }

  .logout {
    cursor: pointer;
    width: 70px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 8px 0px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-inline: 10px;
  }
  .login {
    cursor: pointer;
    width: 60px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 8px 0px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding-inline: 10px;
  }

  :where(.css-dev-only-do-not-override-14wwjjs).ant-badge {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
  }

  .ant-scroll-number {
    background: #56c20e;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    position: absolute;
    top: -18px;
    left: 58px;
  }

  .Header_Menu {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #fbfbfb;
  }

  .Mobile_Menu {
    font-size: 25px;
    padding: 5px;
    cursor: pointer;
    color: #272343;
    text-align: right;
  }

  .Header_Menu .Menu_List .List {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    padding-top: 10px;
  }

  .Header_Menu .Menu_List .List li {
    color: #141414;
    text-align: center;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 17.6px */
    text-transform: capitalize;
  }

  @media screen and (max-width: 767px) {
    .Header_Middle,
    .Top_Bar,
    .Menu_List {
      display: none;
    }

    .Mobile_Menu {
      display: block;
      font-size: 25px;
      width: 100%;
      cursor: pointer;
      color: #272343;
    }

    .Header_Main {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .Cart {
      width: 50px;
    }

    .Cart .cart_text {
      display: none;
      width: 0;
    }

    .Cart_Badge {
      display: flex;
      align-items: center;
    }

    .Header_Main .Header_Right {
      width: 40%;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    /* Styles for tablets */
    .Header_Main {
      justify-content: space-between;
    }

    .Header_Right {
      width: 30%;
    }

    .Header_Menu {
      display: none;
    }

    .container {
      position: absolute;
      right: 0;
    }

    .Mobile_Menu {
      display: block;
      width: 100%;
      font-size: 25px;
      cursor: pointer;
      color: #272343;
    }
  }
`;
