import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Logo from "../../Assets/Images/gowri-logo 1.png";
import Tote from "../../Assets/Images/Tote.png";
import {
  UserOutlined,
  ShoppingCartOutlined,
  MenuUnfoldOutlined,
  LoginOutlined,
  LogoutOutlined
} from "@ant-design/icons";
import { Button, Drawer, Avatar, Badge, Modal, Dropdown } from "antd";
import Marquee from "react-fast-marquee";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../Redux/slices/user";
import { base_url } from "../../config";
import API from "../../ApiService/ApiService";

const { confirm } = Modal;

const Header8 = ({ home_custom, content_all }) => {
  // console.log("home_custom--->", home_custom);
  let token = localStorage.getItem("token") || "";
  let login = localStorage.getItem("login") || false;
  let userData = localStorage.getItem("user") || null;

  const api = new API();

  const [cart, setCart] = useState([]);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const logout = () => {
    //data of the user will be deleted from local storage after logout and also set null value to userSlice
    dispatch(logoutUser(null));
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    getAllCart();
  }, []);

  const getAllCart = () => {
    api
      .getcart()
      .then((res) => {
        setCart(res?.data?.data);
      })
      .catch((err) => {
        setCart([]);
      });
  };

  // console.log("cart", cart);

  const TopOffer = content_all?.topOffer;
  // const TopOffer = home_custom?.top_offer;

  return (
    <Section>
      <div className="containerDiv">
        <Drawer
          title="Menu"
          placement="left"
          onClose={onClose}
          open={open}
          width={300}
        >
          <p>
            <Link className="link" to="/">
              Home
            </Link>
          </p>

          <p>
            <Link className="link" to="/about">
              About Us
            </Link>
          </p>

          <p>
            <a className="link" href="#faq">
              FAQ
            </a>
          </p>

          <p>
            <Link className="link" to="/carrers">
              Carrers
            </Link>
          </p>

          <p>
            <Link className="link" to="/contact">
              Contact Us
            </Link>
          </p>
        </Drawer>
        <Marquee className="marquee" play={true} pauseOnHover={true}>
          <div className="morque_container">
            <p
              className="marquee-text"
              dangerouslySetInnerHTML={{
                __html: `${TopOffer}<span class="outer"><span class="inner"></span></span>`,
              }}
            ></p>
            <p
              className="marquee-text"
              dangerouslySetInnerHTML={{
                __html: `${TopOffer}<span class="outer"><span class="inner"></span></span>`,
              }}
            ></p>
            <p
              className="marquee-text"
              dangerouslySetInnerHTML={{
                __html: `${TopOffer}<span class="outer"><span class="inner"></span></span>`,
              }}
            ></p>
            <p
              className="marquee-text"
              dangerouslySetInnerHTML={{
                __html: `${TopOffer}<span class="outer"><span class="inner"></span></span>`,
              }}
            ></p>
            <p
              className="marquee-text"
              dangerouslySetInnerHTML={{
                __html: `${TopOffer}<span class="outer"><span class="inner"></span></span>`,
              }}
            ></p>
          </div>
        </Marquee>
      </div>
      <div className="header_container">
        <div className="nav_container">
          <div className="icon_container">
            <MenuUnfoldOutlined onClick={showDrawer} />
          </div>
          <Link to="/">
            <div className="logo_container">
            <img src={base_url + content_all?.gowri_logo?.image} alt="logo" />
              {/* <img src={Logo} alt="logo" /> */}
            </div>
          </Link>
          <div className="nav_links_container">
            <ul className="nav_links">
              <li className="nav_link">
                <Link className="link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav_link">
                <Link className="link" to="/about">
                  About Us
                </Link>
              </li>
              <li className="nav_link">
                <a className="link" href="#faq">
                  FAQ
                </a>
              </li>
              <li className="nav_link">
                <Link className="link" to="/products">
                  Products
                </Link>
              </li>
              <li className="nav_link">
                <Link className="link" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          {login && token && userData ?(<div className="profile_container">
              <Link to="/my-profile">
            <div className="profile_img_container">
                <UserOutlined style={{ color: "white" }} />
            </div>
              </Link>
            <Link to="/cart" style={{ textDecoration: "none" }}>
              <button className="btn">
                {" "}
                <img src={Tote} alt="tote" />
                <span>CART</span> 
                <p style={{ color: "white" }}>{cart?.total_quantity}</p>
              </button>
            </Link>
          </div>) : null}
          <div style={{ backgroundColor: "#006a68", color: "white" }}>
            {login && token && userData ? (
              <div className="Icons">
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#006a68",
                    outline: "none",
                    border: "none",
                    color: "white"
                  }}
                  onClick={logout}
                >
                  Logout
                </Button>
              </div>
            ) : (
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#006a68",
                    outline: "none",
                    border: "none",
                    color: "white"
                  }}
                >
                  <LoginOutlined /> Login
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Header8;

const Section = styled.section`
  width: 100%;
  .link {
    text-decoration: none;
    color: #000;
  }
  background-color: #f5f5ed;
  .containerDiv {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #d6fbe0;

    .morque_container {
      display: flex;
      align-items: center;
      width: 100%;
      p {
        margin: 0;
        display: flex;
        align-items: center;
      }

      .outer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: 1px solid black;
        border-radius: 50%;
        margin: 0 15px;
      }

      .inner {
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
      }
    }
  }
  .header_container {
    margin-top: 20px;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .nav_container {
      margin: 0 30px;
      width: 1200px;
      /* padding: 0 60px; */
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon_container {
        display: none;
      }

      .logo_container {
        img {
          width: 150px;
        }
      }

      .nav_links_container {
        width: 505px;
        .nav_links {
          color: #000;
          font-family: Brevia;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.8px;
          margin: 0 10px;
          padding: 0;

          display: flex;
          align-items: center;
          justify-content: space-between;

          .link {
            text-decoration: none;
            color: #000;
          }
        }
      }

      .profile_container {
        /* width: 170px; */
        display: flex;
        align-items: center;
        .cart_badge {
          display: none;
          font-size: 25px;
          color: #006a68;
        }
        .profile_img_container {
          width: 40px;
          height: 40px;
          background-color: #006a68;
          display: grid;
          place-content: center;
          border-radius: 50%;
        }

        .btn {
          font-family: Brevia;
          border-radius: 0;
          background-color: #01c834;
          width: 120px;
          color: white;
          display: flex;
          padding: 5px 10px;
          text-align: center;
          align-items: center;
          margin-left: 10px;
          text-decoration: none;
          img {
            margin-right: 10px;
          }
          p {
            margin: 0 0 0 10px;
            color: #006a68;
            font-family: Atyp Text;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-decoration: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .header_container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .nav_container {
      margin: 0 30px !important;
      padding: 0 !important;
    }
  }

  @media screen and (max-width: 768px) {
    .nav_links_container {
      display: none;
    }

    .icon_container {
      display: block !important;
      font-size: 20px;
    }

    .logo_container {
      img {
        width: 150px !important;
        padding: 10px;
      }
    }

    .profile_container {
      .btn {
        display: none !important;
      }
      .cart_badge {
        display: block !important;
        margin-left: 10px;
      }
    }
  }
`;
