import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { List, Button, Divider, Checkbox, Form, message, Modal } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import {
  addressGetAllFail,
  addressGetAllStart,
  addressGetAllSuccess,
} from "../Redux/slices/userAddress";
import {
  updateAddressStart,
  updateAddressSuccess,
  updateAddressFail,
} from "../Redux/slices/updateAddress";
import {
  removeAddressStart,
  removeAddressSuccess,
  removeAddressFail,
} from "../Redux/slices/removeAddress";

import { styles } from "../ApiService/Data";
import API from "../ApiService/ApiService";

import UpdateAddress from "./UpdateAddress";
import SideBar from "./SideBar";

const api = new API();
const {confirm} = Modal;
const BillingAddress = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [address, setAddress] = useState({});
  const dispatch = useDispatch();

  // const id = address?._id;

  const [form] = Form.useForm();
  let userAddressList = useSelector(
    (state) => state?.userAddress?.address?.data
  );
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    getUserAddress();
  }, []);

  const getUserAddress = async () => {
    setIsLoading(true);
    await api.getAll(
      dispatch,
      [addressGetAllStart, addressGetAllSuccess, addressGetAllFail],
      "buyerbillingdetails"
    );
    setIsLoading(false);
  };

  //delete user address
  const handleDelete = async (id) => {
    const res = await api.remove(
      dispatch,
      [removeAddressStart, removeAddressSuccess, removeAddressFail],
      "buyerbillingdetails",
      id
    );
    if (res?.data?.success) {
      message.success("Successfully Deleted");
      getUserAddress();
    } else {
      message.error(res?.response?.data?.message);
    }
    getUserAddress();
  };


  const showConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this address?",
      onOk() {
        handleDelete(id);
      },
      onCancel() {},
    });
  }
  //delivery address setting
  const setDeliveryAddress = async (id) => {
    const res = await api.update(
      dispatch,
      [updateAddressStart, updateAddressSuccess, updateAddressFail],
      "defaultaddress",
      id
    );
    if (res?.data?.success) {
      message.success(`Delivery Address Updated`);
      window.location.reload();
    } else {
      message.warning("Check your Data");
    }
    getUserAddress();
  };

  const BillingAddress = () => {};
  return (
    <BillingAddressSection>
      <UpdateAddress
        getUserAddress={getUserAddress}
        address={address}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div className="wrapper" style={{padding:"60px 40px"}}>
        <Title>My Address</Title>
        <div className="billing_address_section">
          <div className="sidebar">
            <SideBar />
          </div>
          <div className="billing_address_head">
            <Link to="/add-address">
              <Button type="primary" danger size="small">
                Add Address
              </Button>
            </Link>
            <Divider />
            <List
              className="edit-address"
              loading={isLoading}
              itemLayout="horizontal"
              dataSource={userAddressList}
              renderItem={(item) => (
                // console.log("item------->", item),
                <List.Item
                  actions={[
                    // <Link to={`/edit-address?id=${item._id}`}>
                    <div
                      className="edit"
                      onClick={() => {
                        setShowModal(true);
                        setAddress(item);
                        form.setFieldValue(address);
                      }}
                    >
                      <EditOutlined /> Edit
                    </div>,
                    <div
                      className="delete"
                      onClick={() => {
                        showConfirm(item._id);
                      }}
                    >
                      <DeleteOutlined /> Delete
                    </div>,
                    // </Link>
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Checkbox
                        checked={item?.is_default_address}
                        onClick={() => setDeliveryAddress(item._id)}
                      ></Checkbox>
                    }
                    title={`${item?.buyer_delivery_contact_number}`}
                    description={
                      // "test, test, Coimbatore, test, Tamil Nadu, INDIA - 656512. Alternate Mobile :6369021751"
                      `${item?.buyer_address_line_1}, ${item?.buyer_address_line_2}, ${item?.buyer_city} - ${item?.buyer_pincode}, ${item?.buyer_state}, ${item?.buyer_country} `
                    }
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </div>
    </BillingAddressSection>
  );
};

export default BillingAddress;

const BillingAddressSection = styled.section`
  margin: 40px 0 0 0;

  .warpper {
    max-width: 1200px;
  }

  .billing_address_section {
    width: 100%;
    margin: auto;
    /* border: 1px solid #e9e9e9; */
    /* border: 1px solid red; */
    border-radius: 5px;
    display: flex;
    gap: 30px;
  }
  .billing_address_head {
    text-align: right;
    width: 100%;
    padding: 20px;
  }
  .ant-list-item-meta-content {
    text-align: left;
  }
  .ant-list .ant-list-item {
    align-items: flex-start;
  }
  .edit {
    font-size: 13px;
    cursor: pointer;
  }
  .delete {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .Side_BarMain {
      min-width: 0% !important;
      width: 130px !important;
    }
    .Side_BarMain ul{
      padding: 0;
    }
    .laQDLI {
      min-width: 0% !important;
    }
  }


@media screen and (max-width: 500px) {
  .billing_address_section {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 425px) {
  .ant-list-item{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

@media screen and (max-width: 375px) {
  .ant-list-item{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

@media screen and (max-width: 320px) {
  .ant-list-item{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
}

/* @media screen and (min-width: 600px) and (max-width: 900px) {
  .billing_address_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
} */

`;

const Title = styled.h1`
  font-size: 30px;
  color: ${styles.color};
  margin: 30px 0 20px 0;
`;
