import React from "react";
import "../../Assets/Css/style.css";
import styled from "styled-components";
import image1 from "../../Assets/Images/Brand/a-1.png";
import image2 from "../../Assets/Images/Brand/a-2.png";
import image3 from "../../Assets/Images/Brand/a-3.png";
import image4 from "../../Assets/Images/Brand/a-4.png";
import API from "../../ApiService/ApiService";

const WhyUsSection3 = ({ why_us }) => {

  // console.log("WhyUsSection3----->", why_us);
  const api = new API();


  return (
    <WhySectionAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">{why_us?.banner_id?.banner_title}</p>
          <p className="Content">
           {why_us?.banner_id?.banner_description}
          </p>
        </div>
        <div className="WhyUs_Section_Main">
          <div className="Left_Section">
            <div className="Top_Title_Left">
              <p className="Title">
                Simply <span className="Green">Unique/</span>
                <br /> Simply <span className="Blue">Better</span>.
              </p>
            </div>
            <div className="Bottom_Main">
              {why_us?.whyus_id?.map((item, index) => {
                return (
                  <div className="Bottom_Box" key={index}>
                    <img src={api.rootUrl1 + item?.image} alt="image" />
                    <p className="Title">{item?.card_title}</p>
                    <p className="Content">{item?.card_description}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="Right_Section">
            <div className="Right_Section_Image">
              <img src={api.rootUrl1 + why_us?.banner_id?.image} alt="About Us" />
            </div>
          </div>
        </div>
      </div>
    </WhySectionAlign>
  );
};

export default WhyUsSection3;

const WhySectionAlign = styled.div`
  .Top_Title {
    text-align: center;
  }

  .Top_Title .Title {
    color: #1e1e1e;
    font-family: Flama Trial;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .Top_Title .Content {
    opacity: 0.8;
    color: #6c7275;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  .WhyUs_Section_Main {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 20px;
    margin-top: 50px;
  }

  .WhyUs_Section_Main .Left_Section {
    width: 50%;
    padding: 0 30px;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .WhyUs_Section_Main .Left_Section .Top_Title_Left .Title {
    color: #141718;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -2px;
  }

  .WhyUs_Section_Main .Left_Section .Top_Title_Left .Title .Green {
    color: #56c20e;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 90px; */
    letter-spacing: -2px;
  }

  .WhyUs_Section_Main .Left_Section .Top_Title_Left .Title .Blue {
    color: #0489d6;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 90px; */
    letter-spacing: -2px;
  }

  .WhyUs_Section_Main .Left_Section .Bottom_Main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 60px 0 0 0;
  }

  .WhyUs_Section_Main .Left_Section .Bottom_Main .Bottom_Box img {
    width: 50px;
    /* height: 50px; */
    flex-shrink: 0;
  }

  .WhyUs_Section_Main .Left_Section .Bottom_Main .Title {
    color: #000;
    font-family: Flama Trial;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  .WhyUs_Section_Main .Left_Section .Bottom_Main .Content {
    color: #6a6a6a;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .Right_Section .Right_Section_Image {
    /* width: 582.663px; */
    height: 450px;
    transform: rotate(0.315deg);
    flex-shrink: 0;
    border-radius: 10px;
    background: lightgray 50% / cover no-repeat;
  }

  .Right_Section .Right_Section_Image img {
    width: 400px;
    height: 450px;
  }

 @media screen and (max-width: 767px) {
   .WhyUs_Section_Main {
     display: flex;
     flex-direction: column;
     flex-wrap: wrap;
     align-items: center;
     justify-content: center;
   }

   .WhyUs_Section_Main .Left_Section {
    width: 100%;
   }

   .WhyUs_Section_Main .Right_Section  .Right_Section_Image { 
     width: 100%;
     background: transparent;
     padding: 0;

   }

   .Top_Title .Title {
    color: #1e1e1e;
    font-family: Flama Trial;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .Top_Title .Content {
    opacity: 0.8;
    color: #6c7275;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }

  .WhyUs_Section_Main .Left_Section .Top_Title_Left .Title {
    color: #141718;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -2px;
  }

  .WhyUs_Section_Main .Left_Section .Top_Title_Left .Title .Green {
    color: #56c20e;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 90px; */
    letter-spacing: -2px;
  }

  .WhyUs_Section_Main .Left_Section .Top_Title_Left .Title .Blue {
    color: #0489d6;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    /* line-height: 90px; */
    letter-spacing: -2px;
  }

  .WhyUs_Section_Main .Left_Section .Bottom_Main .Title {
    color: #000;
    font-family: Flama Trial;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  .WhyUs_Section_Main .Left_Section .Bottom_Main .Content {
    color: #6a6a6a;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

 }


`;
