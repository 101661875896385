import { createSlice } from "@reduxjs/toolkit";

const privacypolicy = createSlice({
  name: "privacypolicy",
  initialState: {
    privacypolicy: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    registerGetAllStart: (state) => {
      state.isFetching = true;
    },
    registerGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.privacypolicy = action.payload;
    },
    registerGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    privacypolicyGetAllStart: (state) => {
      state.isFetching = true;
    },
    privacypolicyGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.privacypolicy = action.payload;
    },
    privacypolicyGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    logoutprivacypolicy: (state, action) => {
      state.privacypolicy = action.payload;
    },
  },
});

export const {
  privacypolicyGetAllStart,
  privacypolicyGetAllSuccess,
  privacypolicyGetAllFail,
  logoutprivacypolicy,
  registerGetAllStart,
  registerGetAllSuccess,
  registerGetAllFail,
} = privacypolicy?.actions;
export default privacypolicy.reducer;
