import React from "react";
import image from "../../Assets/Images/GirlsHub Template/WhyUs.png";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import styled from "styled-components";
import Image1 from "../../Assets/Images/Poornima/WhyUsBanner.png";
import Image2 from "../../Assets/Images/Poornima/whyus1.png";
import Image3 from "../../Assets/Images/Poornima/whyus2.png";
import Image4 from "../../Assets/Images/Poornima/whyus3.png";
import API from "../../ApiService/ApiService";

const WhyUsSection6 = ({ whyus_banner, content_all }) => {
  // console.log("whyus_banner==>", whyus_banner);
  const api = new API();

  const WhyUsBannerArray = [
    {
      image: content_all?.poornima_why_sub_banner1?.image,
      description: content_all?.poornima_why_sub_desc1,
    },
    {
      image: content_all?.poornima_why_sub_banner2?.image,
      description: content_all?.poornima_why_sub_desc2,
    },
    {
      image: content_all?.poornima_why_sub_banner3?.image,
      description: content_all?.poornima_why_sub_desc3,
    },
  ]


  const navigate = useNavigate();
  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="HCFurniture_Main">
          <div className="Left_Section" style={{ padding: "60px" }}>
            <Link
            // to={banner?.banner?.banner_left?.button_link}
            >
              <img
                src={api.rootUrl1 + content_all?.poornima_why_banner?.image}
                alt="Banner 1"
              />
              {/* <img src={Image1} alt="Banner 1" /> */}
            </Link>
          </div>
          <div className="Right_Section">
            <div className="banner_right_section" style={{ padding: "50px" }}>
              <h2 style={{ marginBottom: "25px" }}>
                {/* WHO WE ARE */}
                {content_all?.poornima_why_title}
              </h2>
              <p style={{ marginBottom: "25px", color: "#A5A5A5" }}>
                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
                aspernatur, ratione, recusandae praesentium quo ad quae amet
                facere laboriosam voluptas molestiae numquam. Est nesciunt,
                neque architecto ducimus repellendus beatae exercitationem? */}
                {content_all?.poornima_why_desc}
              </p>
              {WhyUsBannerArray.map((item, index) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "25px",
                    }}
                  >
                    <img
                      src={api.rootUrl1 + item?.image || null}
                      alt=""
                      style={{ height: "50px", width: "50px" }}
                    />
                    <p style={{ marginLeft: "25px", marginTop: "10px" }}>
                      {/* Lorem ipsum dolor sit amet consectetur adipisicing. */}
                      {item?.description}
                    </p>
                  </div>
                );
              })}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "25px",
                }}
              >
                <img
                  src={Image3}
                  alt=""
                  style={{ height: "50px", width: "50px" }}
                />
                <p style={{ marginLeft: "25px", marginTop: "10px" }}>
                  Lorem ipsum dolor sit amet consectetur adipisicing.
                </p>
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "25px",
                }}
              >
                <img
                  src={Image4}
                  alt=""
                  style={{ height: "50px", width: "50px" }}
                />
                <p style={{ marginLeft: "25px", marginTop: "10px" }}>
                  Lorem ipsum dolor sit amet consectetur adipisicing.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </BannerAlign>
  );
};

export default WhyUsSection6;

const BannerAlign = styled.div`
  .home_wrapper {
    display: flex;
  }

  .HCFurniture_Main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .HCFurniture_Main .Left_Section,
  .HCFurniture_Main .Right_Section {
    width: 50%;
  }

  @media screen and (max-width: 1024px) {     
    max-width: 1024px;
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
     
    } 
      
  }

  @media screen and (max-width: 912px) {
    max-width: 912px;
    .HCFurniture_Main {
      flex-direction: column;
    }
   
  }
  @media screen and (max-width: 768px) {
    max-width: 768px;
    .HCFurniture_Main {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 592px) {
    max-width: 592px;
    .HCFurniture_Main {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 540px) {
    max-width: 540px;
    .HCFurniture_Main {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 480px) {
    max-width: 480px;
    .HCFurniture_Main {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 428px) {
    max-width: 428px;
    .HCFurniture_Main {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 425px) {
    max-width: 425px;
    .HCFurniture_Main {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 390px) {
    max-width: 390px;
    .HCFurniture_Main {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 375px) {
    max-width: 375px;
    .HCFurniture_Main {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 360px) {
    max-width: 360px;
    .HCFurniture_Main {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 320px) {
    max-width: 320px;
    .HCFurniture_Main {
      flex-direction: column;
    }
  }
`;
