import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
import { RightCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Link } from "react-router-dom";
import Default from '../../Assets/Images/default.png'
import API from '../../ApiService/ApiService';
import { base_url } from '../../config';

const FP2 = ({ featuredproduct_type }) => {
  // console.log('feature', featuredproduct_type);
  return (
    <>
   <FeatureProductSection>
  <Wrapper>
    <HeadText>
      <H2>FeatureProduct</H2>
      <Button>
        View All
        <RightCircleOutlined />
      </Button>
    </HeadText>
    <Ul>
      {featuredproduct_type?.length > 1 ? (
        featuredproduct_type
          .slice(0, 8)
          .map((data) => (
            <Li key={data._id}>
              <ImageAlign>
                <Link
                  to={
                    data?.category[0]?.category_name
                      ? `${data?.category[0]?.category_name
                          ?.toLowerCase()
                          .replace(/ /g, '-')
                          .replace(/[^\w-]+/g, '')}/${
                          data?.description
                            ?.toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        }?pid=${data?._id}`
                      : '#'
                  }
                >
                  <Image
                    src={
                      data?.prospecdocs[0]?.product_images[0]?.image_path
                          ? base_url +  data?.prospecdocs[0]?.product_images[0]?.image_path
                          : Default
                    }
                    alt={data?.description}
                  />
                </Link>
              </ImageAlign>
              <Content>
                <Title>{data?.description}</Title>
                <Price>
                  <Sp>
                    {data?.currency}
                    {data?.prospecdocs[0]?.sp}
                  </Sp>
                  <Mrp>
                    {data?.currency}
                    {data?.prospecdocs[0]?.mrp}
                  </Mrp>
                </Price>
                <Link
                  to={
                    data?.category[0]?.category_name
                      ? `${data?.category[0]?.category_name
                          ?.toLowerCase()
                          .replace(/ /g, '-')
                          .replace(/[^\w-]+/g, '')}/${
                          data?.product_description
                            ?.toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')
                        }?pid=${data?._id}`
                      : '#'
                  }
                >
                  <Button>Buy Now</Button>
                </Link>
              </Content>
            </Li>
          ))
      ) : (
        <p className="no_result_found">No Product Created yet</p>
      )}
    </Ul>
  </Wrapper>
</FeatureProductSection>

    </>
  )
}

export default FP2;

const FeatureProductSection = styled.section`
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0px 0;
`;
const Wrapper = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 10px;
`;
const HeadText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const H2 = styled.h2`
  color: ${styles.color};
  font-size: ${styles.h2};
`;
const Ul = styled.div`
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap:30px 25px;
  margin: 25px 0 0 0;
`;
const Li = styled.div`
  border: 1px solid ${styles.light};
  width: 100%;
  display: inline-block;
  border-radius: 5px;
`;
const ImageAlign = styled.div`
  width: 100%;
  background: transparent;
  width: 100%;
  position: relative;
`;
const Image = styled.img`
  max-width: 100%;
  display: block;
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  padding: 25px;
  border-top: 0px solid ${styles.light};
`;
const Title = styled.div`
  color:${styles.color};
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5;
`;
const Price = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
`;
const Sp = styled.div`
  font-size: 20px;
  color: ${styles.color};
  font-weight: 700;
`;
const Mrp = styled.div`
  font-size: 14px;
  color: ${styles.gray};
  text-decoration: line-through;
`;