import React, {useEffect} from "react";
import {styled} from "styled-components";
import {Col, Row, Button, Rate} from "antd";
import {base_url} from "../../config";
import {font, theme} from "../../ApiService/Theme";
import {Link, useNavigate} from "react-router-dom";
import {
  productGetAllStart,
  productGetAllSuccess,
  productGetAllFail,
} from "../../Redux/slices/product";
import {useDispatch} from "react-redux";
import crud_service from "../../ApiService/CrudService";
const crud = new crud_service();
const FP6 = ({featuredproduct_type}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const getProducts = async () => {
      await crud.getAll(
        dispatch,
        [productGetAllStart, productGetAllSuccess, productGetAllFail],
        "products",
        {
          page: 1,
          perpage: 8,
        },
        // "",
        (err, res) => {}
      );
    };
    getProducts();
    
  }, []);

  return (
    <Wrapper>
      <div className="product_align">
        <h2>Featured Product</h2>
        <ProductsWrapper>
          <div className="wrapper">
            <Row gutter={[16, 16]} className="rowContainer">
              {featuredproduct_type?.slice(0, 8)?.map((product) => (
                <Col key={product._id}>
                  <CardWrapper xl={6} lg={6} md={12} sm={24} xs={24}>
                    <Link
                      to={`/product/${product._id}`}
                      style={{textDecoration: "none"}}
                    >
                      <div className="cardImg">
                        <img
                          src={base_url + product.images}
                          alt={product?.product_name}
                        />
                      </div>
                    </Link>
                    <div className="productDetails">
                      <div>
                        <Link
                          to={`/product/${product._id}`}
                          style={{textDecoration: "none"}}
                        >
                          <p className="productName">{product?.product_name}</p>
                        </Link>
                        <p className="productPrice">
                          <span>
                            &#8377;
                            {product?.prospecdocs &&
                            product?.prospecdocs.length > 0
                              ? product?.prospecdocs[0].mrp
                              : 0.0}
                          </span>
                        </p>
                      </div>
                      <div className="rating_container">
                        <Rate allowHalf defaultValue={5} />
                      </div>
                      <div className="btnContainer">
                        <button onClick={() => {navigate(`/product/${product._id}`)}} className="addToCartBtn">View Detail</button>
                      </div>
                    </div>
                  </CardWrapper>
                </Col>
              ))}
            </Row>
          </div>
        </ProductsWrapper>
      </div>
    </Wrapper>
  );
};

export default FP6;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .product_align {
    max-width: 1200px;
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 35px;
      margin: 0 20px;
    }
  }

  .filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .btns {
    display: flex;
    .filter_btn {
      margin-right: 20px;
    }
  }

  button {
    padding: 5px 10px;
    font-size: 16px;
    border: 1px solid grey;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
  }
  button:hover {
    background-color: #717fe0;
    color: #fff;
  }
`;

const ProductsWrapper = styled.div`
  width: 100%;
  margin-top: 20px;

  .rowContainer {
    margin: 0 auto;
    justify-content: center;
  }
`;

const CardWrapper = styled.div`
  width: 260px;
  height: 400px;
  border: 1px solid #dedede;
  padding: 15px;
  /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); */
  border-radius: 5px;
  .cardImg {
    width: 100%;
    /* border: 1px solid transparent; */
    img {
      /* border: 1px solid transparent; */
      width: 100%;
      aspect-ratio: 2/2;
      object-fit: cover;
    }
  }

  .productDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    .productName {
      text-decoration: none;
      color: #142b43;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: capitalize;
      font-size: 16px;
      transition: 0.3s;
      cursor: pointer;
      margin-bottom: 0;
    }

    .productName:hover {
      color: #ed8f07;
    }
    .productPrice {
      font-weight: 600;
      font-size: 15px;
      color: #333333;
      margin-bottom: 0;
    }
    .btnContainer {
      /* border: 1px solid red; */
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btnContainer .addToCartBtn {
      color: #142b43;
      text-transform: capitalize;
      border-color: #142b43;
      transition: 0.3s;
      padding: 8px 70px;
    }

    .addToCartBtn:hover {
      background-color: #142b43;
      color: white;
      border: 1px solid #142b43;
    }
  }
  .rating_container {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .rating_container .ant-rate-star-half,
  .ant-rate-star-full,
  .ant-rate-star-zero {
    color: #ed8f07;
    font-size: 15px;
  }

@media screen and (min-width: 360px) and (max-width: 660px) {
   
}

`;
