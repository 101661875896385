import React from 'react'
import styled from 'styled-components'
import { font, theme } from '../../ApiService/Theme'
import { Link } from "react-router-dom";
import {base_url} from '../../config'
import default_image from '../../Assets/Images/default.png';
import { Button } from 'antd';
const Hc21 = ({ props }) => {
  return (
    <Hc1Section>
      <div className='hc1_section'>
        <div className='wrapper'>
          <H2>{props?.section_value?props?.section_value:""}</H2>
          <ul>
            {
              props?.content?.slice(0, 2).map((e, i) => {
                return (
                  <li key={i}>
                    <div className='hc1_box'>
                      <div className='left'>
                        <img src={e?.image?base_url+e?.image:default_image} alt={e?.title} />
                        <Link to={e?.link?e?.link:void 0}><Button>{e?.link_text?e?.link_text:"Shop Now"}</Button></Link>
                      </div>
                      <div className='right'>
                        <h4>{e?.title}</h4>
                        <h5>{e?.sub_title}</h5>
                      </div>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </Hc1Section>
  )
}

export default Hc21

const H2 = styled.h2`
   font-size:30px;
   margin : 0 0 35px;
   text-transform: uppercase;
   font-family: ${font?.regular} !important;
   letter-spacing: 0.7px;

   @media screen and (max-width:768px) {
    text-align: center;
   }

`
const Hc1Section = styled.section`
    width:100%;
    display: inline-block;
    position: relative;
    .hc1_section {
        display: inline-block;
        width: 100%;
        position: relative;
    }

    .hc1_section ul {
        display: grid;
        padding: 0;
        grid-template-columns: repeat(2,1fr);
        gap: 45px;
    }
    .hc1_section ul li {
        background: transparent;
    padding: 0 45px 45px 45px;
    width: 100%;
    border-radius: 0px;
    display: grid;
    align-items: center;
    position: relative;
    }
    .hc1_section ul li::before {
        content: "";
        position: absolute;
        border: 1px solid #bfbbbb;
        /* border-style: dashed; */
    }
    .hc1_section ul li .hc1_box {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .hc1_section ul li .hc1_box .left {
        width: 47%;
        display: inline-block;
        position: relative;
        z-index: 10;
        img {
            border-radius: 10px;
            width: 100%;
    height: 300px;
    object-fit: cover;
        }
        button {
            width: fit-content;
        margin: 30px auto 0 auto;
        border: 0;
        border-radius: 8px;
        padding: 7px 15px;
        display: flex;
        text-align: center;
        background: ${theme?.bg};
        color: ${theme?.while};
        font-family: ${font?.regular} !important;
        }
    }
    .hc1_section ul li .hc1_box .right {
        width: 47%;
        display: flex;
        gap: 15px;
        text-align: center;
        flex-direction: column;
        padding: 50px 0;
    }
    .hc1_section ul li .hc1_box .right::before {
        content: "";
    position: absolute;
    border: 1px solid ${theme?.light_gray};
    /* border-style: dashed; */
    height: 55%;
    width: 100%;
    right: 0;
    bottom: 0;
    z-index: 1;
    border-radius: 10px;
}
    .hc1_section ul li .hc1_box .right h4 {
        font-size: 30px;
        text-transform: uppercase;
        margin: 0 !important;
        font-family: ${font?.bold} !important;
        color: ${theme?.black};
    }
    .hc1_section ul li .hc1_box .right h5 {
        margin: 0 !important;
        font-size: 15px;
        color: ${theme?.gray};
        font-family: ${font?.light};
        font-style: italic;
    }
    .hc1_section ul li .hc1_box .right button {
        padding: 0 !important;
    background: transparent;
    border: 0;
    padding: 0;
    margin: auto;
    font-size: 13px;
    font-style: italic;
    -webkit-text-decoration: underline;
    text-decoration: underline;
    font-weight: 600;
    font-family: ${font?.regular} !important;
    -webkit-letter-spacing: 0.7px;
    -moz-letter-spacing: 0.7px;
    -ms-letter-spacing: 0.7px;
    letter-spacing: 0.7px;
    display: flex;
    }

    @media screen and (max-width:1200px) {
        .hc1_section ul li {
            padding: 35px;
        }
        .hc1_section ul li .hc1_box .right::before {
            height: 65%;
        }
    }


    @media screen and (max-width:992px) {
        .hc1_section ul {
            grid-template-columns: repeat(1,1fr);
        }
        .hc1_section ul li {
            padding: 25px;
        }
        .hc1_section ul li:nth-child(even) .hc1_box {
            flex-direction: row-reverse;
        }
        .hc1_section ul li .hc1_box .right::before {
    height: 100%;
}
.hc1_section ul li .hc1_box {
    align-items: center;
}
.hc1_section ul li .hc1_box .left button {
    display: none;
}


    }


    @media screen and (max-width:768px) {
        .hc1_section ul li {
        border: 0;
        background: #fff;
        border-radius: 15px;
    box-shadow: 0 0 15px rgb(0 0 0 / 5%);
    }
    .hc1_section ul li .hc1_box .right::before {
        content: inherit;
    }









    }


    @media screen and (max-width:480px) {
         .hc1_section ul li .hc1_box .right h4 {
    font-size: 25px;
        }
        .hc1_section ul li {
            padding: 25px 15px;
        }


    }





`