import {createSlice} from "@reduxjs/toolkit";

const rating = createSlice({
  name: "rating",
  initialState: {
    rating: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    ratingGetAllStart: (state) => {
      state.isFetching = true;
    },
    ratingGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.profile = action.payload;
    },
    ratingGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {ratingGetAllStart, ratingGetAllSuccess, ratingGetAllFail} =
  rating.actions;
export default rating.reducer;
