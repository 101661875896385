import React from "react";
import styled from "styled-components";
import { Carousel, Button } from "antd";
import API from "../../../ApiService/ApiService";
import image from "../../../Assets/Images/OneWear/OneWearAbout.jpg";
import '../../../Assets/Fonts/fonts.css';

const Ab6 = ({content_all}) => {
  const api = new API();

  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="HCFurniture_Main">
          <div className="Left_Section">
            <div className="overlay">
              <p>{content_all?.onewear_about_title}</p>
              {/* <p>ABOUT ONEWEAR</p> */}
              <h4>ONEWEAR</h4>
              <p>
                {content_all?.onewear_about_description}
              </p>
              {/* <p>
                simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s,
              </p> */}
              <Button className="custom-button" style={{ borderRadius: 0 }} onClick={() => (window.location.href = `/${content_all?.onewear_about_btn_link}`)}>
                {/* SIGN UP */}
                {content_all?.onewear_about_btn_txt}
              </Button>
            </div>
          </div>
          <div className="Right_Section">
            {/* <Link to={`/${content_all?.right_banner_img_top_link}`}> */}
            <img src={api?.rootUrl1 + content_all?.onewear_about_banner?.image} alt="banner2" />
            {/* <img src={image} alt="banner2" /> */}
            {/* </Link> */}
          </div>
        </div>
      </div>
    </BannerAlign>
  );
};

export default Ab6;

const BannerAlign = styled.div`  
  padding-bottom: 80px;
  .home_wrapper {
    display: flex;
    background-color: #2f2f2f;
    padding: 60px 80px;
    width: 100%;
    height: 100%;
  }

  .HCFurniture_Main {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
    width: 80%;
    height: 60vh;
    margin: auto;
    gap: 20px;
  }

  .HCFurniture_Main .Left_Section,
  .HCFurniture_Main .Right_Section {
    width: 50%;
    height: 100%;
    /* border: 1px solid red; */
  }

  /* .HCFurniture_Main .Left_Section img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0px -10%;
  } */
  .HCFurniture_Main .Right_Section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .Left_Section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .overlay {
    /* border: 1px solid red; */
  }

  .overlay p,
  .overlay h4,
  .overlay .custom-button {
    margin-bottom: 20px; /* Adjust the value as needed */
  }

  p {
    margin-bottom: 0px;
    color: #ffffff;
    font-family: Inter;
    font-size: 12px;
  }

  h4 {
    margin-bottom: 0px;
    color: #e96027;
    font-family: Montserrat;
  }

  .custom-button {
    border: 1px solid #ffffff !important;
    color: #000 !important;
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .custom-button:hover {
    background-color: #c23b2c !important;
    color: #000 !important;
    border: 1px solid #c23b2c !important;
  }

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    max-width: 1024px;
  }

  @media screen and (min-width: 912px) and (max-width: 991px) {
    max-width: 912px;
  }

  @media screen and (min-width: 820px) and (max-width: 911px) {
    max-width: 820px;
    .home_wrapper {
      padding: 60px 10px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 819px) {
    max-width: 768px;
    .home_wrapper {
      padding: 60px 10px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    max-width: 540px;
    .home_wrapper {
      padding: 60px 10px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 430px) and (max-width: 539px) {
    max-width: 430px;
    .home_wrapper {
      padding: 60px 5px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 425px) and (max-width: 429px) {
    max-width: 425px;
    .home_wrapper {
      padding: 60px 5px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 414px) and (max-width: 424px) {
    max-width: 414px;
    .home_wrapper {
      padding: 60px 5px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 412px) and (max-width: 413px) {
    max-width: 412px;
    .home_wrapper {
      padding: 60px 5px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 390px) and (max-width: 411px) {
    max-width: 390px;
    .home_wrapper {
      padding: 60px 5px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 389px) {
    max-width: 375px;
    .home_wrapper {
      padding: 60px 5px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 360px) and (max-width: 374px) {
    max-width: 360px;
    .home_wrapper {
      padding: 60px 5px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 359px) {
    max-width: 320px;
    .home_wrapper {
      padding: 60px 5px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {
    max-width: 280px;
    .home_wrapper {
      padding: 60px 5px;
    }
    .HCFurniture_Main {
      flex-direction: column;
    }
    .HCFurniture_Main .Left_Section,
    .HCFurniture_Main .Right_Section {
      width: 100%;
    }
  }
`;
