import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
     cart:[],
    isFetching: false,
    error: false,
  },
  reducers: {
    cartStart: (state) => {
      state.isFetching = true
    },
    cartSuccess: (state, action) => {
      state.isFetching = false
      state.cart = action.payload
    },
    cartFailure: (state) => {
      state.isFetching = false
      state.error = true;
    },
   
  },
});

export const { cartStart, cartSuccess, cartFailure } = cartSlice.actions;
export default cartSlice.reducer
