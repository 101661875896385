import React from "react";
import styled from "styled-components";
import { Divider } from "antd";
import API from "../../ApiService/ApiService";
import catImage from "../../Assets/Images/toppng 1.png";
import Default from "../../Assets/Images/default.png";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";

const generateRandomShade = (color) => {
  const opacity = Math.random() * 0.5 + 0.5;
  return `${color}${Math.floor(opacity * 255).toString(16)}`;
};

const getColorByIndex = (index) => {
  const originalColors = [
    "#FFCCCC",
    "#FFF1CC",
    "#DFFFCC",
    "#CCFFE7",
    "#CCF3FF",
    "#D9CCFF",
    "#FFCCCC",
  ];
  return originalColors[index % originalColors.length];
};
const TC7 = ({ category, content_all }) => {
  // console.log("category7 inside--->", category);
  // console.log("category7 inside--->", content_all);
  const api = new API();

  return (
    <CategoryAlign>
      <div className="home_wrapper">
        <Divider />
        <TopCategorySection>
          <div className="TopCategory_Main">
            {content_all?.smile_category?.map((category, index) => (
              <Link
                key={index}
                to={`/Products?catName=${category?.name
                  ?.replace(" ", "-")
                  ?.toLowerCase()
                  ?.replace(/ /g, "")
                  ?.replace(/[^\w-]+g/, "")}`}
              >
                <div className="top_category" key={index}>
                  <CategoryImageWrapper
                    style={{
                      background: generateRandomShade(getColorByIndex(index)),
                      borderRadius: "50%",
                    }}
                  >
                    <img
                      src={
                        category?.image
                          ? api.rootUrl1 + category?.image
                          : Default
                      }
                      alt={`Category ${index + 1}`}
                    />
                  </CategoryImageWrapper>
                  <CategoryName>
                    <Tooltip title={category?.name}>
                      <p>{category?.name}</p>
                    </Tooltip>
                  </CategoryName>
                </div>
              </Link>
            ))}
          </div>
        </TopCategorySection>
        <Divider />
      </div>
    </CategoryAlign>
  );
};

export default TC7;

const CategoryAlign = styled.div`
  .home_wrapper {
    max-width: 2000px;
  }
`;

const TopCategorySection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .TopCategory_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 50px;
  }

  Link,
  a {
    text-decoration: none;
  }

  .top_category {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  /* @media screen and (min-width: 320px) and (max-width: 1100px) {
  
  .TopCategory_Main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
 
}
} */

  @media screen and (min-width: 520px) and (max-width: 768px) {
    .TopCategory_Main {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 519px) {
    .TopCategory_Main {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }
`;

const CategoryImageWrapper = styled.div`
  height: 50px;
  width: 50px;
  padding: 5px;
  border-radius: 50%;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  Link {
    text-decoration: none;
  }
`;

const CategoryName = styled.div`
  max-width: 10ch;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  Link {
    text-decoration: none;
  }
`;
