import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {styles} from "../../ApiService/Data";
import headbg from "../../Assets/Images/Agri/head_icon.png";
import {Button} from "antd";
import top1 from "../../Assets/Images/Agri/t1.png";
import top2 from "../../Assets/Images/Agri/t2.png";
import top3 from "../../Assets/Images/Agri/t3.png";
import top4 from "../../Assets/Images/Agri/t4.png";
import top5 from "../../Assets/Images/Agri/t5.png";
import top6 from "../../Assets/Images/Agri/t6.png";
import topbg from "../../Assets/Images/Agri/top_center.jpg";
import Default from "../../Assets/Images/Agri/product.png";
import API from "../../ApiService/ApiService";
import {useNavigate} from "react-router-dom";
import {base_url} from "../../config";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user).setColor;

const TC5 = ({category}) => {
  const api = new API();
  const navigate = useNavigate();

  return (
    <>
      {category?.length > 0 ? (
        <TopCategorySection>
          <div className="top_category_section">
            <div className="wrapper">
              <div className="agri_head">
                <h1>Popular Category</h1>
              </div>
              <div className="top_category_align">
                <ul>
                  {category?.slice(0, 6)?.map((item) => {
                    return (
                      <li key={item?._id}>
                        <div className="left">
                          <img
                            src={
                              item?.image ? api.rootUrl1 + item?.image : Default
                            }
                            alt="Top Category"
                          />
                        </div>
                        <div className="right">
                          {/* <h4>{item?.name}</h4> */}
                          <h4>{item?.name}</h4>
                          <Link
                            to={`/Products?catName=${item?.name
                              .replace(" ", "-")
                              .toLowerCase()
                              .replace(/ /g, "")
                              .replace(/[^\w-]+g/, "")}`}
                            style={{color: "black"}}
                          >
                            <Button>Shop Now</Button>
                          </Link>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="View_All">
                <Button
                  className="view_all_button"
                  onClick={() => navigate("/all-categories")}
                >
                  View All Categories
                </Button>
              </div>
            </div>
          </div>
        </TopCategorySection>
      ) : (
        ""
      )}
    </>
  );
};

export default TC5;

const TopCategorySection = styled.section`
  .View_All {
    text-align: center;
  }

  * {
    font-family: ${styles?.r_regular};
  }
  width: 100%;
  display: inline-block;
  position: relative;
  .top_category_section {
    width: 100%;
    display: inline-block;
    position: relative;
  }
  .agri_head {
    width: 100%;
    display: inline-block;
    margin: 0 0 55px;

    h1,
    h2 {
      margin: 0 !important;
      font-family: ${styles?.r_regular} !important;
      font-weight: 600;
      letter-spacing: 0px;
      font-size: 37px;
      text-transform: uppercase;
      text-align: center;
      padding: 55px 0 0 0;
      position: relative;
      &::before {
        content: "";
        background: url(${headbg});
        background-repeat: no-repeat;
        position: absolute;
        height: 48px;
        width: 38px;
        background-size: contain;
        background-position: center top;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
  }

  .top_category_align {
    width: 100%;
    display: inline-block;
    position: relative;
  }
  .top_category_align ul {
    display: grid;
    padding: 0;
    margin: 0;
    list-style: none;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px 40%;
    position: relative;
  }
  .top_category_align ul::before {
    content: "";
    position: absolute;
    background: url(${topbg});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 37%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  .top_category_align ul li {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    justify-content: space-between;
  }
  .top_category_align ul li .left {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 140px;
  }
  .top_category_align ul li:nth-child(odd) .left::before {
    content: "";
    position: absolute;
    background: #c8ffd9;
    height: 105px;
    width: 105px;
    border-radius: 100%;
    bottom: 0;
    right: 0;
    z-index: 3;
  }
  .top_category_align ul li:nth-child(odd) .left::after {
    content: "";
    position: absolute;
    border: 1px solid #c8ffd9;
    height: 95px;
    width: 95px;
    border-radius: 100%;
    top: 0;
    left: 0;
    z-index: 5;
  }
  .top_category_align ul li:nth-child(even) .left::before {
    content: "";
    position: absolute;
    background: #c8ffd9;
    height: 105px;
    width: 105px;
    border-radius: 100%;
    bottom: 0;
    left: 0;
    z-index: 3;
  }
  .top_category_align ul li:nth-child(even) .left::after {
    content: "";
    position: absolute;
    border: 1px solid #c8ffd9;
    height: 95px;
    width: 95px;
    border-radius: 100%;
    top: 0;
    right: 0;
    z-index: 5;
  }

  .top_category_align ul li .left img {
    height: 100px;
    width: 100px;
    object-fit: fill;
    z-index: 10;
    border-radius: 50%;
  }

  .top_category_align ul li .right {
    width: 53%;
    display: inline-block;
  }
  .top_category_align ul li:nth-child(odd) {
    flex-direction: row-reverse;
  }
  .top_category_align ul li:nth-child(odd) .right {
    text-align: right;
  }
  .top_category_align ul li h4 {
    text-transform: uppercase;
    font-size: 21px;
    line-height: 1.4;
    margin: 0 0 15px;
  }
  button {
    color: #000;
    border: 1px solid #12a25f;
    position: relative;
    z-index: 10;
  }

  @media screen and (min-width: 660px) and (max-width: 990px) {
    .View_All {
      margin-left: -20%;
    }

    .agri_head {
      h1,
      h2 {
        font-size: 30px !important;
        padding: 42px 0px 0 0;
        text-align: center;
      }
    }
    .agri_head {
      margin-left: -10%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .agri_head h1::before,
    .agri_head h2::before {
      height: 37px;
    }
    .top_category_align {
      width: 100% !important;
      margin-left: -10%;
    }
    .top_category_align ul {
      background: none;
      gap: 45px 25px;
      width: 100% !important;
    }

    .top_category_align ul li,
    .top_category_align ul li:nth-child(odd) {
      flex-direction: column;
      gap: 35px;
      width: 100% !important;
    }
    .top_category_align ul li:nth-child(odd) .right,
    .top_category_align ul li .right {
      width: 100% !important;
      text-align: center;
    }
    .top_category_align ul::before {
      content: "";
      position: absolute;
      background: url(${topbg});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      width: 35%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }

  @media screen and (min-width: 360px) and (max-width: 480px) {
    .View_All {
      margin-left: -65%;
      margin-top: 20px;
    }

    .agri_head {
      h1,
      h2 {
        font-size: 30px !important;
        padding: 42px 0px 0 0;
        text-align: center;
      }
    }
    .agri_head {
      margin-left: -34%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .agri_head h1::before,
    .agri_head h2::before {
      height: 37px;
    }
    .top_category_align {
      width: 42% !important;
      margin-left: -4%;
    }
    .top_category_align ul {
      background: none;
      gap: 45px 25px;
      width: 100% !important;
    }

    .top_category_align ul li,
    .top_category_align ul li:nth-child(odd) {
      flex-direction: column;
      gap: 35px;
      width: 100% !important;
    }
    .top_category_align ul li:nth-child(odd) .right,
    .top_category_align ul li .right {
      width: 100% !important;
      text-align: center;
    }
    .top_category_align ul::before {
      content: inherit;
    }
  }
`;
