import React from "react";
import styled from "styled-components";
import "../../Assets/Css/style.css";
import { Button } from "antd";
import Banner from "../../Assets/Images/GirlsHub Template/Banner.png";
import Marquee from "react-fast-marquee";
import API from "../../ApiService/ApiService";
import { Navigate, useNavigate } from "react-router-dom";
import BannerImage from "../../Assets/Images/product.webp"

const api = new API();

const Banner10 = ({ banner, offertext, content_all }) => {
  // console.log("banner", banner)
  // console.log("offertext", offertext)
  // console.log("content_all==>banner", content_all)
  const navigate = useNavigate();
  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="Banner_Main">
          <div className="Left_Section">
            <div className="Left_Content">
              <p className="content">{content_all?.banner_description}</p>
            </div>
            <div className="Left_Button">
              <Button
                className="Button"
                onClick={() =>
                  (window.location.href = `/${content_all?.banner_btn_link}`)
                }
              >
                {content_all?.btn_txt_banner}
              </Button>
            </div>
          </div>
          <div className="Right_Section">
            <div className="Right_Image">
              {/* <img src={BannerImage} alt="Banner1" /> */}
              <img src={process.env.REACT_APP_BASE + content_all?.banner_img?.image} alt="Banner1" />
              {/* <img src={api.rootUrl1 + banner?.image} alt="Banner1" /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="Offer_Section">
        <Marquee
          play={true}
          pauseOnHover={true}
          speed={50}
          direction="left"
          className="marquee"
        >
          <span>
            <svg
              width="25"
              height="25"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 0L20.3215 11.6785L32 16L20.3215 20.3215L16 32L11.6785 20.3215L0 16L11.6785 11.6785L16 0Z"
                fill="white"
              />
            </svg>{" "}
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: content_all?.banner_marquee_text }}
            />
          </span>
          <span>
            <svg
              width="25"
              height="25"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 0L20.3215 11.6785L32 16L20.3215 20.3215L16 32L11.6785 20.3215L0 16L11.6785 11.6785L16 0Z"
                fill="white"
              />
            </svg>{" "}
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: content_all?.banner_marquee_text }}
            />
          </span>
          <span>
            <svg
              width="25"
              height="25"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 0L20.3215 11.6785L32 16L20.3215 20.3215L16 32L11.6785 20.3215L0 16L11.6785 11.6785L16 0Z"
                fill="white"
              />
            </svg>{" "}
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: content_all?.banner_marquee_text }}
            />
          </span>
          <span>
            <svg
              width="25"
              height="25"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 0L20.3215 11.6785L32 16L20.3215 20.3215L16 32L11.6785 20.3215L0 16L11.6785 11.6785L16 0Z"
                fill="white"
              />
            </svg>{" "}
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: content_all?.banner_marquee_text }}
            />
          </span>
          <span>
            <svg
              width="25"
              height="25"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 0L20.3215 11.6785L32 16L20.3215 20.3215L16 32L11.6785 20.3215L0 16L11.6785 11.6785L16 0Z"
                fill="white"
              />
            </svg>{" "}
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: content_all?.banner_marquee_text }}
            />
          </span>
          <span>
            <svg
              width="25"
              height="25"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 0L20.3215 11.6785L32 16L20.3215 20.3215L16 32L11.6785 20.3215L0 16L11.6785 11.6785L16 0Z"
                fill="white"
              />
            </svg>{" "}
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: content_all?.banner_marquee_text }}
            />
          </span>
          <span>
            <svg
              width="25"
              height="25"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 0L20.3215 11.6785L32 16L20.3215 20.3215L16 32L11.6785 20.3215L0 16L11.6785 11.6785L16 0Z"
                fill="white"
              />
            </svg>{" "}
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: content_all?.banner_marquee_text }}
            />
          </span>
          <span>
            <svg
              width="25"
              height="25"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 0L20.3215 11.6785L32 16L20.3215 20.3215L16 32L11.6785 20.3215L0 16L11.6785 11.6785L16 0Z"
                fill="white"
              />
            </svg>{" "}
            <div
              className="text"
              dangerouslySetInnerHTML={{ __html: content_all?.banner_marquee_text }}
            />
          </span>
        </Marquee>
      </div>
    </BannerAlign>
  );
};

export default Banner10;

const BannerAlign = styled.div`
  background: linear-gradient(254deg, #e6c1fb 2.77%, #feeee8 96.17%);

  .home_wrapper {
    display: flex;
    justify-content: center;
    /* width: 100%; */
     /* height: 100vh; */
    /* background: linear-gradient(254deg, #e6c1fb 2.77%, #feeee8 96.17%); */
    border-top: 1px solid linear-gradient(254deg, #e6c1fb 2.77%, #feeee8 96.17%);
  }

  .Banner_Main {
    display: flex;
    /* align-items: center; */
    justify-content: space-evenly;
    gap: 20px;
    /* background-image: url(${Banner}); */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    width: 100%;
    position: relative;
    left: -99px;
    top: 95px;
    /* border: 1px solid green; */
  }

  .Banner_Main .Left_Section {
    position: relative;
    top: -150px;
    /* border: 1px solid blue; */
  }

  .Left_Section .Left_Content {
    color: #000;
    font-family: Moneta;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 92.4px */
    letter-spacing: 2.52px;
    margin: 250px 0 0 0;
    padding-bottom: 20px;
  }
  .content {
    width: 520px;
  }

  .Left_Button .Button {
    display: inline-flex;
    padding: 20px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 24px;
    background: #fff;
    border: none;
    outline: none;
  }

  .Left_Button .Button:hover {
    background: #fff;
    color: #2f4076;
  }

  .Offer_Section {
    padding-top: 5px;
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    background: #2e4076;
    color: #fff;
    display: grid;
    place-items: center;
  }

  .marquee span {
    padding: 0 20px;
  }

  .Banner_Main .Right_Section {
    /* border: 1px solid red; */
    mix-blend-mode: multiply;
    filter: contrast(1);
    margin-right: -200px;
  }
  .Right_Section .Right_Image {
    /* border: 1px solid black; */
    /* border: 1px solid red; */
    width: 375px;
  }

  .Offer_Section {
    .rfm-child {
      span {
        display: flex;
        p {
          margin: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .Banner_Main {
      left: -99px;
      gap: 0px !important;
    }

    .Left_Section .Left_Content {
      font-size: 40px;
      letter-spacing: 2px;
    }

    .content {
      width: 325px;
    }   

    .Right_Section .Right_Image {
      width: 300px;
    }
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    max-width: 540px;
    .Banner_Main {
      left: 15px;
      gap: 0px !important;
    }

    .Left_Section .Left_Content {
      font-size: 22px;
      letter-spacing: 2px;
    }

    .content {
      width: 500px;
      text-align: center;
    } 
    
    .Left_Button{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Right_Section {
      display: none;
    }
  }

  @media screen and (min-width: 430px) and (max-width: 539px) {
    /* max-width: 430px; */
    .Banner_Main {
      left: 15px;
      gap: 0px !important;
    }

    .Left_Section .Left_Content {
      font-size: 22px;
      letter-spacing: 2px;
    }

    .content {
      width: 400px;
      text-align: center;
    }

    .Left_Button{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Right_Section {
      display: none;
    }    
  }

  @media screen and (min-width: 320px) and (max-width: 429px) {
    .Banner_Main {
      left: 2px;
      gap: 0px !important;
    }

    .Left_Section .Left_Content {
      font-size: 22px;
      letter-spacing: 2px;
    }

    .content {
      width: 300px;
      text-align: center;
    } 
    
    .Left_Button{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Right_Section {
      display: none;
    }
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {
    .Banner_Main {
      left: 2px;
      gap: 0px !important;
    }

    .Left_Section .Left_Content {
      font-size: 22px;
      letter-spacing: 2px;
    }

    .content {
      width: 260px;
      text-align: center;
    }

    .Left_Button{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .Right_Section {
      display: none;
    }
  }
`;
