import React from 'react'
import Ab1 from "./AboutUs/Ab1"
import Ab2 from "./AboutUs/Ab2"
import Ab3 from "./AboutUs/Ab3"
import Ab4 from "./AboutUs/Ab4"
import Ab5 from "./AboutUs/Ab5"
import { useSelector } from 'react-redux'

const AboutUsMain = () => {
    const AboutUs = useSelector((state) => state?.aboutus?.aboutus);
    const about_type =""
    switch (about_type) {
        case "AB1":
            return <Ab1  />
        case "AB2":
            return <Ab2 />
        case "AB3":
            return <Ab3 />
        case "AB4":
            return <Ab4 />
        case "AB5":
            return <Ab5 />
        default:
            return <Ab1 AboutUs={AboutUs}/>;
    }
}

export default AboutUsMain