import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Avatar, Tooltip, Space } from "antd";
import { Link } from "react-router-dom";
import API from "../../ApiService/ApiService";
import { MailFilled, PhoneFilled, MobileFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
import { base_url } from "../../config";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user).setColor;

const Footer3 = ({ company,socialmedia }) => {
// console.log('company', company);

  const year = new Date().getFullYear();
  const FooterLinks = [
    { path: "/", title: "Home" },
    { path: "/about", title: "About Us" },
    { path: "/contact", title: "Contact Us" },
    { path: "/enquiry", title: "Enquiry" },
  ];

  const OtherLinks = [
    { path: "/privacy-policy", title: "Privacy Policy" },
    { path: "/terms-and-condition", title: "Terms and Conditions" },
    { path: "/refund-policy", title: "Refund Policy" },
    { path: "/delivery-policy", title: "Delivery Policy" },
    { path: "/return-policy", title: "Return Policy" },
    { path: "/cancellation-policy", title: "Cancellation Policy" },
  ];


  return (
   <>
    <FooterSection>
      <section className="Template4_Footer bg_light">
        <div className="Template4_Footer_Align">
          <div className="Template4_Footer_Align_Top">
            {company?.map && (
              <iframe
                src={company?.map}
                width="100%"
                height="230"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            )}
          </div>
          <div className="Template4_Footer_Align_Bottom">
            <div className="Template4_Footer_Logo">
              <img src={ base_url + company?.logo} />
            </div>
            <div className="Template4_Footer_Address">
              <p>
                {company?.company_addresss},
                {company?.city}, Pincode - {company?.pin_code},
                <br />
                {company?.state}
              </p>
            </div>
            <div className="Template4_Footer_Social_Media">
              <Space>
                {/* {socialmedia?.map((e, i) => (
                  <Tooltip title={e?.label} key={`smL${i}`}>
                    <a href={e?.link} target="_blank">
                      <Avatar gap={10} src={base_url + e?.icon}></Avatar>
                    </a>
                  </Tooltip>
                ))} */}
              </Space>
            </div>
            <div className="Template4_Footer_MailUs">
              {company?.email_id && (
                <p>
                  <a href={"mailto:" + company?.email_id}>
                    <MailFilled style={{ color: "#434242" }} /> {company?.email_id}
                  </a>
                </p>
              )}
              {/* {company?.landline_no && (
                <p>
                  <a href={"tel:" + company?.landline_no}>
                    <PhoneFilled style={{ color: "#434242" }} />{" "}
                    {company?.landline_no}
                  </a>
                </p>
              )} */}
              {company?.mobile_number && (
                <p>
                  <a href={"tel:+91" + company?.mobile_number}>
                    <MobileFilled style={{ color: "#434242" }} />{" "}
                    {company?.mobile_number}
                  </a>
                </p>
              )}
            </div>
            <div>
              <ul className="Template4_Footer_QuickLinks">
                {OtherLinks.map((e, i) => (
                  <li key={`qL_${i}`}>
                    <Link to={e?.path}>{e?.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <footer className="Template4_Footer_CopyRights">
          &copy; {year} Designed by{" "}
          <a href="https://blazon.in" target="_blank">
            Blazon
          </a>
        </footer>
      </section>
      </FooterSection>
   </>
  )
}

export default Footer3;

const FooterSection = styled.div`


.Template4_Footer * {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}
.Template4_Footer {
  display: inline-block;
  padding: 40px 0 0 0;

  width: 100%;
  text-align: center;
}
.Template4_Footer .Template4_Footer_Align {
  display: inline-block;
  width: 100%;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Top {
  display: inline-block;
  width: 100%;
  position: relative;
  margin: 0 0 0px;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Top iframe {
  padding: 0%;
  outline: none;
  height: 350px;
  width: 100%;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom {
  width: 100%;
  display: inline-block;
  padding: 60px 0;
 
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom .Template4_Footer_Logo {
  width: 100%;
  display: inline-block;
  margin: 0 0 15px;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom .Template4_Footer_Logo img {
  height: 80px;
  display: flex;
  margin: auto;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom .Template4_Footer_Address {
  width: 100%;
  display: inline-block;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom .Template4_Footer_Address p {
  color: ${styles?.color};
  line-height: 1.5;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom .Template4_Footer_Social_Media {
  margin: 15px 0;
  display: inline-block;
  width: 100%;
  position: relative;
}

.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom .Template4_Footer_MailUs {
  display: flex;
  width: fit-content;
  margin: 20px auto;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom .Template4_Footer_MailUs p a {
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${styles?.color};
  font-size: 17px;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom ul.Template4_Footer_QuickLinks {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  list-style: none;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom ul.Template4_Footer_QuickLinks li {
  padding: 0 15px 0 0;
  margin: 0 15px 0 0;
  position: relative;
  color: ${styles?.color};
  font-size: 14px;
  line-height: 1.5;
  
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom ul.Template4_Footer_QuickLinks li::before {
  content: '';
  position: absolute;
  height: 12px;
  top:50%;
  right:0;
  transform: translate(0,-50%);
  width: 1px;
  background: ${styles?.gray};
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom ul.Template4_Footer_QuickLinks li a {
  color: ${styles?.color};
  font-size: 14px;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom ul.Template4_Footer_QuickLinks li:last-child {
  padding: 0%;
  margin: 0%;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom ul.Template4_Footer_QuickLinks li:last-child::before {
  content: inherit;
}
.Template4_Footer_CopyRights {
  background: ${styles?.light};
  padding: 12px 0;
  display: inline-block;
  width: 100%;
}
.Template4_Footer_CopyRights a {
  color: ${styles?.colorapi} !important;
  font-weight: 600;
}
.bg_light {
  background: ${styles?.bgcolorlight};
}

@media screen and (max-width:580px) {
 .Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom .Template4_Footer_Logo img {
   height: 60px;
 }

}

@media screen and (max-width:480px) {
  .Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom .Template4_Footer_MailUs p a {
  font-size: 15px;
}
.Template4_Footer .Template4_Footer_Align .Template4_Footer_Align_Bottom .Template4_Footer_MailUs {
  gap:20px;
}
}

@media screen and (max-width:380px) {
.Template4_Footer * {
  word-wrap: break-word;
}

}




`;