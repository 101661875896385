import React from "react";
import styled from "styled-components";
import API from "../../ApiService/ApiService";

const Banner8 = ({homecustom, content_all}) => {
  // console.log("homecustom--->", homecustom);
  const api = new API();

  return (
    <Section>
      <div className="main_container">
        {/* {homecustom?.map((e, i) => { */}
          {/* return ( */}
            <>
              <div className="banner_left_section">
                <h2 className="big">{content_all?.banner_main_desc}</h2>

                <p>{content_all?.banner_sec_desc}</p>
                <button onClick={() => window.location.href = `/products`}>{content_all?.banner_btn_txt}</button>
              </div>
              <div className="banner_right_section">
                {/* <img src={api.rootUrl1 + e?.image} alt="banner" /> */}
                <img src={api.rootUrl1 + content_all?.gowri_main_banner_img?.image} alt="banner" />
              </div>
            </>
          {/* ); */}
        {/* })} */}
      </div>
    </Section>
  );
};

export default Banner8;

const Section = styled.section`
  background-color: #f5f5ed;
  /* height: 690px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: center;
  .main_container {
    /* height: 100%; */
    width: 1200px;
    display: flex;
    align-items: center;
    margin: 0 25px;
    .banner_left_section {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .small {
        display: none;
      }

      h2 {
        color: #000;
        font-family: "HvDTrial Brevia", sans-serif;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        -webkit-font-smoothing: antialiased;
      }

      p {
        color: #000;
        font-family: Brevia;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      button {
        width: 150px;
        padding: 12px 12px 15px 12px;
        border: none;
        background-color: #01c834;
        color: white;
      }
    }

    .banner_right_section {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      mix-blend-mode: multiply;
      filter: contrast(1);
      img {
        height: 650px;
        width: 650px;
        /* clipPath: circle(50% at 50% 50%); */
      }
    }
  }

  @media screen and (max-width: 992px) {
    .main_container {
      margin: 0 30px !important;
    }
    .banner_left_section {
      h2 {
        font-size: 30px !important;
      }

      p {
        font-size: 23px !important;
      }
      button {
        width: 100px !important;
        padding: 10px 10px 13px 10px !important;
      }
    }

    .banner_right_section {
      img {
        height: 465px !important;
        width: 465px !important;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .main_container {
      margin: 0 30px !important;
    }
    .banner_left_section {
      h2 {
        font-size: 25px !important;
      }

      p {
        font-size: 18px !important;
      }
      button {
        width: 100px !important;
        padding: 5px 5px 8px 5px !important;
      }
    }

    .banner_right_section {
      img {
        height: 353px !important;
        width: 353px !important;
      }
    }
  }
  @media screen and (max-width: 486px) {
    .main_container {
      margin: 10px 30px !important;
      flex-direction: column;
    }
    .banner_left_section {
      width: 100% !important;
      align-items: center;
      padding: 20px 0;
      .small {
        display: block !important;
      }

      .big {
        display: none !important;
      }
      h2 {
        font-size: 19px !important;
      }

      p {
        font-size: 15px !important;
      }
      button {
        width: 100px !important;
        padding: 3px 0 5px 0 !important;
      }
    }

    .banner_right_section {
      width: 100% !important;
      img {
        /* height: 230px !important; */
        width: 100% !important;
      }
    }
  }
`;
