import { createSlice } from "@reduxjs/toolkit";

const templateOrderArraySlice = createSlice({
  name:'templateorder',
  initialState:{
    templateorder:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    templateorderGetAllStart:(state)=>{
      state.isFetching = true;
    },
    templateorderGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.templateorder = action.payload;
    },
    templateorderGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {templateorderGetAllStart,templateorderGetAllSuccess,templateorderGetAllFail} = templateOrderArraySlice?.actions;
export default templateOrderArraySlice.reducer;



