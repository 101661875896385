import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../ApiService/ApiService";
import Default from "../../Assets/Images/default.png";
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
import { base_url } from "../../config";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user).setColor;

const Hc32 = ({ props }) => {
  return (
    <>
    <Hc3>
        <section className="Temp1_HC3">
          <div className="Wrapper_Full">
            {props?.title && <H2>{props?.title}</H2>}
            <div className="Temp1_HC3_Top">
              <div className="Temp1_HC3_Top_Align">
                {props?.content?.slice(0, 1).map((e, i) => {
                  return (
                    <div key={`hc3_1_${i}`} className="Temp1_HC3_Top_Left">
                      <div className="Temp1_HC3_Box">
                        <div className="Temp1_HC3_Box_Bg">
                          <img
                            src={e?.image ? base_url + e?.image : Default}
                            alt={e?.sub_title}
                          />
                        </div>

                        <div className="Temp1_HC3_Box_Content">
                          <p>{e?.sub_title}</p>
                          <h3>{e?.title}</h3>

                          {e?.link_text && (
                            <div className="Temp1_HC3_Box_Btn">
                              <Link
                                to={e?.link
                                  .toLowerCase()
                                  .replace(/ /g, "-")
                                  .replace(/[^\w-]+/g, "")}
                              >
                                <button>
                                  <span>{e?.link_text}</span>
                                </button>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {props?.content?.slice(1, 2).map((e, i) => {
                  return (
                    <div key={`hc3_1_${i}`} className="Temp1_HC3_Top_Right">
                      <div className="Temp1_HC3_Box">
                        <div className="Temp1_HC3_Box_Bg">
                          <img
                            src={e?.image ? base_url + e?.image : Default}
                            alt={e?.sub_title}
                          />
                        </div>

                        <div className="Temp1_HC3_Box_Content">
                          <p>{e?.sub_title}</p>
                          <h3>{e?.title}</h3>

                          {e?.link_text && (
                            <div className="Temp1_HC3_Box_Btn">
                              <Link
                                to={e?.link
                                  .toLowerCase()
                                  .replace(/ /g, "-")
                                  .replace(/[^\w-]+/g, "")}
                              >
                                <button>
                                  <span>{e?.link_text}</span>
                                </button>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="Temp1_HC3_Bottom_Align">
                <div className="Temp1_HC3_Bottom_Align_Box">
                  {props?.content?.slice(2, 5).map((e, i) => {
                    return (
                      <div key={`hc3_1_${i}`} className="Temp1_HC3_Box">
                        <div className="Temp1_HC3_Box_Bg">
                          <img
                            src={e?.image ? base_url + e?.image : Default}
                            alt={e?.sub_title}
                          />
                        </div>

                        <div className="Temp1_HC3_Box_Content">
                          <p>{e?.sub_title}</p>
                          <h3>{e?.title}</h3>

                          {e?.link_text && (
                            <div className="Temp1_HC3_Box_Btn">
                              <Link
                                to={e?.link
                                  .toLowerCase()
                                  .replace(/ /g, "-")
                                  .replace(/[^\w-]+/g, "")}
                              >
                                <button>
                                  <span>{e?.link_text}</span>
                                </button>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Hc3>
    </>
  )
}

export default Hc32;

const H2 = styled.h2`
  font-size: ${styles?.h2};
  font-family: ${styles?.font} !important;
  color: ${colorCustom?.color ? colorCustom?.color : styles?.color} !important;
  line-height: 1.4;
  margin: 0 0 25px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Hc3 = styled.div`
  .Temp1_HC3 {
    display: inline-block;
    width: 100%;
    position: relative;
  }
  .Temp1_HC3 .Temp1_HC3_Top,
  .Temp1_HC3 .Temp1_HC3_Top .Temp1_HC3_Bottom_Align {
    width: 100%;
    display: inline-block;
  }
  .Temp1_HC3 .Temp1_HC3_Bottom_Align {
    width: 100%;
    display: flex;
    margin: 25px 0 0 0;
  }
  .Temp1_HC3 .Temp1_HC3_Top .Temp1_HC3_Top_Left {
    width: 60%;
    padding: 0 25px 0 0;
    display: inline-block;
  }
  .Temp1_HC3 .Temp1_HC3_Top .Temp1_HC3_Top_Right {
    width: 40%;
    padding: 0 0px 0 0;
    display: inline-block;
  }
  .Temp1_HC3 .Temp1_HC3_Box {
    display: flex;
    min-height: 350px;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 40px;
    overflow: hidden;
  }
  .Temp1_HC3 .Temp1_HC3_Box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 7;
    background: ${styles?.bg60};
  }
  .Temp1_HC3 .Temp1_HC3_Box .Temp1_HC3_Box_Bg {
    width: 100%;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 5;
    background-repeat: no-repeat !important;
    background-position: center center;
    background-size: cover;
    transition: all 0.7s ease-in-out;
  }
  .Temp1_HC3 .Temp1_HC3_Box:hover .Temp1_HC3_Box_Bg {
    transition: all 0.7s ease-in-out;
    transform: scale(1.2);
  }
  .Temp1_HC3 .Temp1_HC3_Box .Temp1_HC3_Box_Content {
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    gap: 15px;
    flex-direction: column;
  }
  .Temp1_HC3 .Temp1_HC3_Bottom_Align_Box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
  }
  .Temp1_HC3 .Temp1_HC3_Box .Temp1_HC3_Box_Content p {
    font-size: 14px;
    color: ${styles?.white};
    margin: 0;
  }
  .Temp1_HC3 .Temp1_HC3_Box .Temp1_HC3_Box_Content h3 {
    font-size: ${styles?.h2};
    color: ${styles?.white} !important;
    font-family: ${styles?.bold} !important;
    margin: 0;
  }
  .Temp1_HC3 .Temp1_HC3_Box .Temp1_HC3_Box_Content .Temp1_HC3_Box_Btn {
    display: inline-block;
    width: 100%;
  }
  .Temp1_HC3 .Temp1_HC3_Box .Temp1_HC3_Box_Content .Temp1_HC3_Box_Btn button {
    padding: 6px 16px;
    outline: none;
    position: relative;
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
    cursor: pointer;
    font-family: "f_regular";
    font-size: 16px;
    margin: 5px 0 0 0;
  }

  @media screen and (max-width: 768px) {
    .Temp1_HC3 .Temp1_HC3_Top .Temp1_HC3_Top_Left,
    .Temp1_HC3 .Temp1_HC3_Top .Temp1_HC3_Top_Right {
      width: 100%;
      padding: 0;
    }
    .Temp1_HC3 .Temp1_HC3_Top .Temp1_HC3_Top_Align,
    .Temp1_HC3 .Temp1_HC3_Bottom_Align_Box {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      text-align: center;
      gap: 25px;
    }
  }
`;