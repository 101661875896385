import React from 'react'
import styled from "styled-components";
import {Button} from "antd";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import API from "../../ApiService/ApiService";
import BannerImage from "../../Assets/Images/OneWear/OneWearOfferBannerSingle.jpg"
import '../../Assets/Fonts/fonts.css';

const OfferBanner3 = ({content_all}) => {

  const api = new API();

  const navigate = useNavigate();

    const contentStyle = {
        height: '80vh',
        width: '100%',
        objectFit: 'cover',
      };
      
      const overlayStyle = {
        position: 'absolute',
        bottom: 0,
        left: 0,
        // transform: 'translate(-50%, -50%)',
        color: '#fff',
        // textAlign: 'center',
        width: '50%',
      };
      
      const slideStyle = {
          position: 'relative',
        };

  return (
    <OfferbannerALign>
    <div className="home_wrapper">
      {/* {offer?.banner?.map((item, index) =>( */}
      {/* <Link to={`/${content_all?.offer_banner_img_top_link}`} style={{textDecoration: "none"}}> */}
        <div 
        className="Offer_Banner_Main" 
        // key={index}
        style={slideStyle}
        >
            <img src={api?.rootUrl1 + content_all?.onewear_offer_banner?.image} style={contentStyle} />
            {/* <img src={BannerImage} style={contentStyle} /> */}
          {/* <div className="OfferTitle"></div>
          <div className="Offer_Coupon"></div>
          <div className="Shop_Button">
          </div> */}
          <div className="overlay" style={overlayStyle}>
            <h2>{content_all?.onewear_offer_banner_title}</h2>
            {/* <h2>FASHION</h2> */}
            <p style={{fontSize: '14px', fontFamily: 'Inter'}}>{content_all?.onewear_offer_banner_description}</p>   
            {/* <p style={{fontSize: '14px', fontFamily: 'Inter'}}>The Epitome of Premium Casual Fashion</p>    */}
            <Link to={`/${content_all?.onewear_offer_banner_btn_link}`} style={{textDecoration: "none"}}>        
              <Button 
              className="custom-button" 
              ghost 
              style={{
                borderRadius: 0, 
                fontWeight: 'bold', 
                fontFamily: 'Montserrat', 
                fontWeight: '400'
                }}
              >
                {/* SIGN UP */}
                {content_all?.onewear_offer_banner_btn_txt}
              </Button>
            </Link>
          </div>
          
        </div>
      {/* </Link> */}
      {/* ))} */}
    </div>
  </OfferbannerALign>
  )
}

export default OfferBanner3;

const OfferbannerALign = styled.div`
    padding-bottom: 80px;
 .home_wrapper {
   width: 100%;
   padding: 0px 0px;
 }

 .Offer_Banner_Main {
   width: 100%;   
 }

 .overlay {
    padding-left: 20px;
    /* padding-right: 20px; */
    padding-top: 20px;
    padding-bottom: 20px;
    h2{
        font-size: 100px !important;
        font-family: Inter;
        font-weight: 700;
    }    
}

    .custom-button:hover {
  background-color: #ffffff !important;
  color: #C23B2C !important;
  border: 1px solid #ffffff !important;
}

 @media screen and (min-width: 992px) and (max-width: 1199px) {
    max-width: 1024px;

    .overlay {
            padding-left: 1.5rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            
            h2 {
                font-size: 2.5rem !important;
            }

            p {
                font-size: 1.25rem;
            }
        }

        .custom-button {
            font-size: 0.9rem;
            padding: 0.4rem 0.8rem;
        }
  }

  @media screen and (min-width: 912px) and (max-width: 991px) {
    max-width: 912px;

    .overlay {
            padding-left: 1.5rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            
            h2 {
                font-size: 2.5rem !important;
            }

            p {
                font-size: 1.25rem;
            }
        }

        .custom-button {
            font-size: 0.9rem;
            padding: 0.4rem 0.8rem;
        }
  }

  @media screen and (min-width: 820px) and (max-width: 911px) {
    max-width: 820px;

    .overlay {
            padding-left: 1.5rem;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            
            h2 {
                font-size: 2.5rem !important;
            }

            p {
                font-size: 1.25rem;
            }
        }

        .custom-button {
            font-size: 0.9rem;
            padding: 0.4rem 0.8rem;
        }
  }

  @media screen and (min-width: 768px) and (max-width: 819px) {
    max-width: 768px;

    .overlay {
            padding-left: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            
            h2 {
                font-size: 2rem !important;
            }

            p {
                font-size: 1rem;
            }
        }

        .custom-button {
            font-size: 0.8rem;
            padding: 0.3rem 0.6rem;
        }
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    max-width: 540px;

    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 430px) and (max-width: 539px) {
    max-width: 430px;
    
    img{
        height: 170px !important;
    }
    
    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 425px) and (max-width: 429px) {
    max-width: 425px;

    img{
        height: 170px !important;
    }

    .overlay {
      .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }
}

  @media screen and (min-width: 414px) and (max-width: 425px) {
    max-width: 414px;

    img{
        height: 170px !important;
    }

    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 412px) and (max-width: 413px) {
    max-width: 412px;

    img{
        height: 170px !important;
    }

    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;            
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 390px) and (max-width: 411px) {
    max-width: 390px;  

    img{
        height: 170px !important;
    }
    
    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 375px) and (max-width: 389px) {
    max-width: 375px;

    img{
        height: 170px !important;
    }

    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 360px) and (max-width: 374px) {
    max-width: 360px;

    img{
        height: 170px !important;
    }

    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 320px) and (max-width: 359px) {
    max-width: 320px;

    img{
        height: 170px !important;
    }

    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {
    max-width: 280px;   

    img{
        height: 170px !important;
    }
    
    .overlay {
            padding-left: 0.5rem;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            width: 80% !important;
            
            h2 {
                font-size: 1.5rem !important;
            }

            p {
                font-size: 0.9rem;
            }
        }

        .custom-button {
            font-size: 0.7rem;
            padding: 0.2rem 0.4rem;
        }
  }

`;