import React from "react";
import API from "../../ApiService/ApiService";
import styled from "styled-components";
import { Link } from "react-router-dom";

const HCFurniture = ({homecustom}) => {
  // console.log("HCFurniture--->", homecustom);
 const api = new API();

  return (
    <BannerAlign>
    <div className="home_wrapper">
      <div className="HCFurniture_Main">
        {homecustom?.map((e, i) => (
          // console.log("e----------->", e),
          <div className="Left_Section" key={e?._id}>
            <Link to={e?.button_link}>
              <img src={api.rootUrl1 + e?.image} alt="Banner 1" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  </BannerAlign>
  );
};

export default HCFurniture;

const BannerAlign = styled.div`
  .home_wrapper {
    display: flex;
  }

  .HCFurniture_Main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .Left_Section,
  .Right_Section {
    /* Add any additional styles for Left_Section and Right_Section if needed */
  }
`;
