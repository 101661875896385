import React from "react";
import { Col, Form, Input, Row, Button, Divider, message } from "antd";
import { EnvironmentOutlined, PhoneOutlined , MailOutlined } from "@ant-design/icons";
import styled from "styled-components";
import banner from "../../../Assets/Images/banner.webp";
import API from "../../../ApiService/ApiService";
import { Tooltip } from 'antd/lib';

import { useSelector } from "react-redux";
import PageUnderConstruction from "../PageUnderConstruction";
const ContactPoornima = ({contactData, content_all}) => {
  const api = new API();
  const [form] = Form.useForm();
  const address = contactData?.data?.[0];
  const [form1] = Form.useForm();
  const onFinish = (values) => {
    // console.log("values", values);
    api.addenquiry(values).then((res) => {
      // console.log("res----->", res);
      if (res?.status == 201) {
        message.success(res?.data?.message);
        form.resetFields();
      } else {
        message.error("Something went wrong");
      }
    });
  };
  return (
    <>      
      {/* {contactData?.data?.length ?  */}
      <ContactSection>
        {/* <img src={process.env.REACT_APP_BASE+address?.image} /> */}
        <Row className="contact_container" gutter={16}>
          <Col
            sm={24}
            xs={24}
            xl={12}
            md={12}
            xxl={12}
            className="Left_Section"
          >
            {/* <h5>Contact Information</h5> */}
            {/* <div dangerouslySetInnerHTML={{ __html: address?.description }}></div> */}
            <Row gutter={24}>
              <Col span={24}>
                <h6>Get in Touch With Us</h6>
                <Row gutter={[16, 16]}>                  
                  <Col span={24} className="icons_align">
                    <div className="icon">
                      <PhoneOutlined rotate={90} />
                    </div>
                    <div>
                      <Tooltip title="Call Us">
                        <a
                          href={`tel:${content_all?.poornima_contact}`}
                          style={{ color: "black", textDecoration: "none" }}
                        >
                          {content_all?.poornima_contact}
                        </a>
                      </Tooltip>
                    </div>
                  </Col>
                  <Col span={24} className="icons_align">
                    <div className="icon">
                      <MailOutlined />
                    </div>
                    <div>
                      <Tooltip title="Mail Us">
                        <a
                          href={`mailto:${content_all?.poornima_email}`}
                          style={{ color: "black", textDecoration: "none" }}
                        >
                          {content_all?.poornima_email}
                        </a>
                      </Tooltip>
                    </div>
                  </Col>
                  <Col span={24} className="icons_align">
                    <div className="icon">
                      <EnvironmentOutlined />
                    </div>
                    <div>
                      <Tooltip title="Visit Us">
                        <p >
                          {/* {
                          address?.addressline_1 + "," + address?.addressline_2 }<br />
                          {address?.city + ", " +address?.district + ", "+ address?.state + "- " + address?.pincode
                        } */}
                        {content_all?.poornima_address}
                        </p>
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={12}></Col>
            </Row>
          </Col>
          <Divider className="divider" />
          <Col
            sm={12}
            xl={12}
            md={12}
            xxl={12}
            className="Right_Section"
          >
            <h5>ENQUIRY</h5>
            {/* <p>Use the form below to get in touch with the sales team</p> */}
            <Form onFinish={onFinish} form={form}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    rules={[
                      { required: true, message: "Please enter your Name!" },
                    ]}
                  >
                    <Input placeholder="Name*" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="mobile_number"
                    rules={[
                      {
                        required: true,
                        pattern: /^[6-9]\d{9}$/,
                        message: "Please enter your Phone!",
                      },
                    ]}
                  >
                    <Input placeholder="Phone*" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        pattern:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter your Email!",
                      },
                    ]}
                  >
                    <Input placeholder="Email*" />
                  </Form.Item>
                </Col>                
                <Col span={12}>
                  <Form.Item
                    name="subject"
                    rules={[
                      { required: true, message: "Please enter your Subject!" },
                    ]}
                  >
                    <Input placeholder="Subject*" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="message"
                    rules={[
                      { required: true, message: "Please enter your Message!" },
                    ]}
                  >
                    <Input.TextArea placeholder="message*" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button
                    htmlType="submit"
                    className="submit_btn"
                    // type="primary"
                    style={{ width: "100%" }}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </ContactSection>
     {/* :     <PageUnderConstruction/>} */}
    </>
  );
};

export default ContactPoornima;

const ContactSection = styled.section`
  margin: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
  .contact_container {
    max-width: 1300px;
    margin-top: 30px;
    padding: 20px;
  }

padding: 20px;
  .divider {
    display: none;
  }

  .icons_align {
    display: flex;
    .icon {
      margin-right: 10px;
    }
    p {
      margin-bottom: 0px;
      width: 300px;
    }
  }

  .ant-form {
    width: 100% !important;
  }

  .ant-input{
    border-radius: 0px;
  }

  .ant-input:hover {
    border-color: #ed8f09;
  }
  .ant-input:focus {
    border-color: #ed8f09;
  }
  .submit_btn {
    /* margin-top: 25px; */
    border-radius: 0px;
    background-color: #88AB8E;
    color: white;
  }

  @media screen and (max-width: 768px) {
    /* .contact_container {
      padding: 20px;
    } */

    /* .Right_Section {
      margin: 30px 0 0 0;
    } */

    .divider {
      display: block;
    }
  }
.contact_container {
  max-width: 1300px;
  margin-top: 30px;
}
 
`;
