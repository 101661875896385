import Home from '../Components/Pages/Home';
import Login from '../Ecommerce/Login';
import Shop from '../Ecommerce/Shop';
import Products from '../Ecommerce/Products';
import Cart from '../Ecommerce/Cart';
import NoPages from '../Components/Pages/NoPages';
import Checkout from '../Ecommerce/Checkout';
import MyProfile from '../Ecommerce/MyProfile';
import Register from '../Ecommerce/Register'
import BillingAddress from '../Ecommerce/BillingAddress';
import MyOrder from '../Ecommerce/MyOrder';
import AddAddress from '../Ecommerce/AddAddress';
import AboutUsMain from '../Components/Pages/AboutUsMain';
import ContactUsMain from '../Components/Pages/ContactUsMain';
import CheckoutMain from '../Components/Pages/CheckoutMain';
import ResultPage from '../Components/Pages/Result';
import OrderDetail from '../Ecommerce/OrderDetail';
import PrivacyPolicy from '../Components/Pages/PrivacyPolicy/PrivacyPolicy';
import AllCategories from '../Components/Pages/AllCategories';
import RefundPolicy from '../Components/Pages/RefundPolicy/RefundPolicy';
import DeliveryPolicy from '../Components/Pages/DeliveryPolicy/DeliveryPolicy';
import ReturnPolicy from '../Components/Pages/ReturnPolicy/ReturnPolicy';
import CancellationPolicy from '../Components/Pages/CancellationPolicy/CancellationPolicy';
import TermsAndCondition from '../Components/Pages/TermsAndCondition/TermsAndCondition';
import BlogDetail from '../Components/Pages/Blogs/BlogDetail';
import ForgotPassword from '../Components/Pages/ForgotPassword';
import VerifyOtp from '../Components/Pages/VerifyOtp';
import ChangePassword from '../Components/Pages/ChangePassword';
import WishList from '../Ecommerce/WishList';

export const commonRouter = [
  {
    exact: true,
    index: true,
    path: "/",
    element: <Home />,
  },
  {
    exact: true,
    path: "/my-profile",
    element: <MyProfile />,
  },
  {
    exact: true,
    path: "/delivery-address",
    element: <BillingAddress />,
  },
  {
    exact: true,
    path: "/add-address",
    element: <AddAddress />,
  },
  {
    exact: true,
    path: "/my-order",
    element: <MyOrder />,
  },
  {
    exact: true,
    path: "/checkout",
    element: <CheckoutMain />,
  },
  {
    exact: true,
    path: "/login",
    element: <Login />,
  },
  {
    exact: true,
    path: "/register",
    element: <Register />,
  },
  {
    exact: true,
    path: "/Products/:id",
    element: <Shop />,
  },
  {
    exact: true,
    path: "/Products",
    element: <Shop />,
  },
  {
    exact: true,
    path: "/product/:id",
    element: <Products />,
  },
  {
    exact: true,
    path: "/all-categories",
    element: <AllCategories />,
  },
  {
    exact: true,
    path: "/cart",
    element: <Cart />,
  },
  {
    exact: true,
    path: "/wish-list",
    element: <WishList />,
  },
  {
    exact: true,
    path: "/about",
    element: <AboutUsMain />,
  },
  {
    exact: true,
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    exact: true,
    path: "/refund-policy",
    element: <RefundPolicy />,
  },
  {
    exact: true,
    path: "/terms-and-condition",
    element: <TermsAndCondition/>,
  },
  {
    exact: true,
    path: "/delivery-policy",
    element: <DeliveryPolicy />,
  },
  {
    exact: true,
    path: "/return-policy",
    element: <ReturnPolicy />,
  },
  {
    exact: true,
    path: "/cancellation-policy",
    element: <CancellationPolicy />,
  },
  {
    exact: true,
    path: "/contact",
    element: <ContactUsMain />,
  },
  {
    exact: true,
    path: "*",
    element: <NoPages />,
  },
  {
    exact: true,
    path: "/result",
    element: <ResultPage />,
  },
  {
    exact: true,
    path: "/my-order/order/:id",
    element: <OrderDetail />,
  },
  {
    exact: true,
    path: "/blog/:id",
    element: <BlogDetail />,
  },
  {
    exact: true,
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    exact: true,
    path: "/verify-otp",
    element: <VerifyOtp />,
  },
  {
    exact: true,
    path: "/change-password",
    element: <ChangePassword />,
  },

];
