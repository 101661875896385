import React from "react";
import styled from "styled-components";
import image from "../../Assets/Images/GirlsHub Template/Review.png";
import {Rate} from "antd";

const ClientReview3 = () => {
  const reviews = [
    {
      image: image,
      name: "Sowimya K.",
      review_content:
        "“Terimakasih banyak, kini ruanganku menjadi lebih mewah dan terlihat mahalkini ruanganku menjadi lebih mewah dan terlihat mahal“",
      role: "Home maker",
      rating: "4",
    },
    {
      image: image,
      name: "Sowimya K.",
      review_content:
        "“Terimakasih banyak, kini ruanganku menjadi lebih mewah dan terlihat mahalkini ruanganku menjadi lebih mewah dan terlihat mahal“",
      role: "Home maker",
      rating: "4",
    },
    {
      image: image,
      name: "Sowimya K.",
      review_content:
        "“Terimakasih banyak, kini ruanganku menjadi lebih mewah dan terlihat mahalkini ruanganku menjadi lebih mewah dan terlihat mahal“",
      role: "Home maker",
      rating: "4",
    },
  ];

  return (
    <ReviewAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">This is What Customer Say</p>
        </div>
        <div className="Client_Review_Main">
          {reviews?.map((review, index) => (
            <div className="Client_Review_Box">
              <div className="Review_Left">
                <img src={review?.image} />
              </div>
              <div className="Review_right">
                <div className="Content">
                  <p>{review?.review_content}</p>
                </div>
                <div className="Review_Rating">
                  <Rate rating={review?.rating} />
                </div>
                <div className="Line"></div>
                <div className="Review_Name">
                  <p>{review?.name}</p>
                  <p>{review?.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ReviewAlign>
  );
};

export default ClientReview3;

const ReviewAlign = styled.div`
  .home_wrapper {
    padding: 0 40px;
  }

  .Top_Title {
    color: #000;
    font-family: Moneta;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  .Client_Review_Main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .Client_Review_Main .Client_Review_Box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 30px;
    /* border-radius: 10px; */
    background: #fff;
    box-shadow: 0px 20px 60px 0px rgba(46, 33, 61, 0.08);
  }

  .Client_Review_Main .Client_Review_Box .Review_Left {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Client_Review_Main .Client_Review_Box .Review_Left img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .Client_Review_Main .Client_Review_Box .Content {
    color: #484848;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;
  }

  .Client_Review_Main .Client_Review_Box .Line {
    border: 1px solid #f0f0f0;
    margin: 10px 0;
  }

  .Client_Review_Main .Client_Review_Box .Review_Name {
    color: #484848;
    font-family: Euclid Circular A;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .Client_Review_Main .Client_Review_Box .Review_Name p:nth-child(2) {
    color: #484848;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
