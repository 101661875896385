import React from "react";
import BannerMain from "../Pages/BannerMain";
import styled from "styled-components";
import OurFavouriteMain from "../Pages/OurFavouriteMain";
import DynamicSection from "../Pages/DynamicSection";
import TopCategoryMain from "../Pages/TopCategoryMain";
import NewArrivalSectionMain from "../Pages/NewArrivalSectionMain";
import OfferBannerMain from "../Pages/OfferBannerMain";
import TrendingOfMonthMain from "../Pages/TrendingOfMonthMain";
import WhyWeUsSectionMain from "../Pages/WhyWeUsSectionMain";
import HCGirlsHub from "../HomeTwoBanner/HCGirlsHub";
import HCGirlsHub2 from "../HomeTwoBanner/HCGirlsHub2";
import FeatureProductMain from "../Pages/FeatureProductMain";
import ClientReviewMain from "../Pages/ClientReviewMain";
import InstagramSectionMain from "../Pages/InstagramSectionMain";
import FeaturesMain from "../Pages/FeaturesMain";

const Template10 = ({homecustom}) => {
  // console.log("homecustom", homecustom)  
  return (
    <HomeSection>
      <BannerMain banner_type="BA10" banner={homecustom} />
      <div className="section_wrap">
        <TopCategoryMain  topcategory_type="TC10" category={homecustom?.category}/>
        <OurFavouriteMain parallax_type="OfferBanner" favourites={homecustom?.favorite_products}/>
        <HCGirlsHub banner={homecustom?.banner_custom} />        
        <NewArrivalSectionMain new_arraival={homecustom?.new_arrival} />
        <OfferBannerMain offer_type="OB2" offer={homecustom?.customize_offer_banner}/>
        <TrendingOfMonthMain trending={homecustom} />
        <WhyWeUsSectionMain why_us_type="why_us_4" whyus_banner={homecustom?.whyus_banner} />
        <HCGirlsHub2 banner={homecustom?.customize_banner} />
        <FeatureProductMain featureProduct={homecustom?.featured_products} />
        {/* <ClientReviewMain /> */}
        <InstagramSectionMain followusoninstagram={homecustom?.follow_us_on_instagram} />
        {/* <FeaturesMain features_type="F2" homecustom={homecustom?.other_supports} /> */}
      </div>
    </HomeSection>
  );
};

export default Template10;

const HomeSection = styled.section`
  overflow-x: hidden;
  .section_wrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* margin: 20px 0; */
    gap: 40px 0;
  }
`;
