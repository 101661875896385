import React from "react";
import styled from "styled-components";
import { Button } from "antd";
import { Link } from "react-router-dom";
import API from "../../ApiService/ApiService";

const overlayStyle = {
  position: "absolute",
  bottom: "0",
  left: "0",
  color: "#000",
  paddingLeft: "20px",
  paddingBottom: "10px",
  paddingTop: "10px",
  width: "50%",
};

const slideStyle = {
  position: "relative",
  height: "300px", // Fixed height for the container
};

const TC12 = ({ content_all }) => {
  const api = new API();

  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="HCFurniture_Main">
          {content_all?.onewear_category?.slice(0, 2).map((item, index) => (
            <div className="Left_Section" style={slideStyle} key={index}>
              <Link to={`/Products?catName=${item?.name}`}>
                <img src={api?.rootUrl1 + item?.image} alt="banner1" />
              </Link>
              <div className="overlay" style={overlayStyle}>
                <h4>{item?.name}</h4>
                <Button
                  onClick={() =>
                    (window.location.href = `/Products?catName=${item?.name}`)
                  }
                  className="custom-button"
                  ghost
                  style={{ borderRadius: 0 }}
                >
                  SHOP NOW
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </BannerAlign>
  );
};

export default TC12;

const BannerAlign = styled.div`
  .home_wrapper {
    display: flex;
    padding: 80px 100px;
    width: 100%;
    height: 100%;
  }

  .HCFurniture_Main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 60%;
    margin: auto;
    gap: 20px;
  }

  .HCFurniture_Main .Left_Section {
    width: 50%;
    height: 300px; /* Fixed height for the container */
    overflow: hidden;
    position: relative;
  }

  .HCFurniture_Main .Left_Section img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container without leaving empty space */
    position: absolute;
    top: 0;
    left: 0;
  }

  .overlay {
    background: rgba(255, 255, 255, 0.7);

    h4 {
      margin-bottom: 10px;
    }
  }

  .custom-button {
    border: 1px solid #000 !important;
    color: #000 !important;
    font-size: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .custom-button:hover {
    background-color: #c23b2c !important;
    color: #ffffff !important;
    border: 1px solid #c23b2c !important;
  }

  @media screen and (max-width: 1199px) {
    .home_wrapper {
      padding: 60px 80px;
    }
  }

  @media screen and (max-width: 991px) {
    .home_wrapper {
      padding: 40px 60px;
    }
  }

  @media screen and (max-width: 767px) {
    .home_wrapper {
      padding: 20px 40px;
    }
    .HCFurniture_Main {
      flex-direction: column;
      gap: 10px;
    }
    .HCFurniture_Main .Left_Section {
      width: 100%;
      height: 200px; /* Adjust height for smaller screens */
    }
    .custom-button {
      font-size: 0.9em;
      padding: 5px 8px;
    }
    .overlay h4 {
      font-size: 1em;
    }
  }

  @media screen and (max-width: 540px) {
    .home_wrapper {
      padding: 10px 20px;
    }
    .HCFurniture_Main {
      gap: 5px;
    }
    .HCFurniture_Main .Left_Section {
      height: 150px; /* Adjust height for smaller screens */
    }
    .custom-button {
      font-size: 0.8em;
      padding: 4px 6px;
    }
    .overlay h4 {
      font-size: 0.9em;
    }
  }

  @media screen and (max-width: 360px) {
    .home_wrapper {
      padding: 5px 10px;
    }
    .HCFurniture_Main .Left_Section {
      height: 120px; /* Adjust height for smaller screens */
    }
    .custom-button {
      font-size: 0.7em;
      padding: 3px 5px;
    }
    .overlay h4 {
      font-size: 0.8em;
    }
  }
`;
