import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import API from "../../ApiService/ApiService";
import '../../Assets/Fonts/fonts.css';

const overlayStyle = {
  position: "absolute",
  top: "0",
  left: "0",
  transform: "translate(0%, 90%)",
  color: "#E96027",
  textAlign: "center",
  paddingLeft: "20px",
  paddingBottom: "10px",
  paddingTop: "10px",
  width: "100%",
};

const slideStyle = {
  position: "relative",
};

const FollowUs = ({ content_all }) => {
  const api = new API();

  return (
    <BannerAlign>
      <div className="home_wrapper">
        <div className="HCFurniture_Main">
          <div className="Left_Section">
            <img src={api.rootUrl1 + content_all?.onewear_follow_images?.[0]?.image} alt="banner1" />
          </div>
          <div className="middle_Section" style={slideStyle}>            
              <img src={api.rootUrl1 + content_all?.onewear_follow_images?.[1]?.image} alt="banner2" />
            <div className="overlay" style={overlayStyle}>
              <p>FOLLOW US</p>
              <Link to={`/${content_all?.onewear_follow_link}`} style={{ textDecoration: "none", color: "#E96027" }}>
                <h4>{content_all?.onewear_follow_txt}</h4>
              </Link>
            </div>
          </div>
          <div className="Right_Section">
            <img src={api.rootUrl1 + content_all?.onewear_follow_images?.[2]?.image} alt="banner3" />
          </div>
        </div>
      </div>
    </BannerAlign>
  );
};

export default FollowUs;

const BannerAlign = styled.div`
  background-color: #ECECEC !important;
  .HCFurniture_Main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 50vh;
    margin: auto;
    background-color: #ECECEC;
  }

  .HCFurniture_Main .Left_Section,
  .HCFurniture_Main .Right_Section,
  .HCFurniture_Main .middle_Section {
    width: 33.33%;
    height: 100% !important;
    /* border: 1px solid red; */
  } 

  .HCFurniture_Main .Right_Section img,
  .HCFurniture_Main .Left_Section img,
  .HCFurniture_Main .middle_Section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  h4 {
    font-family: Inter;
    font-size: 50px;
    text-align: center;
  }
  p {
    font-family: Inter;
    font-size: 12px;
    text-align: center;
  }

  @media screen and (max-width: 1024px) {
    .overlay {
      transform: translate(0%, 70%) !important;
    }
  }

  @media screen and (max-width: 853px) {
    .HCFurniture_Main {
      height: 300px !important;
    }
    .overlay {
      transform: translate(0%, 50%) !important;
    }
    h4 {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 820px) {
    .HCFurniture_Main {
      height: 300px !important;
    }
    .overlay {
      transform: translate(0%, 50% !important);
    }
    h4 {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    .HCFurniture_Main {
      height: 300px !important;
    } 
    .overlay {
      transform: translate(0%, 80%) !important;
    }
    h4 {
      font-size: 30px;
    }
  }
  @media screen and (max-width: 540px) {
    .HCFurniture_Main {
      height: 250px !important;
    } 
    .overlay {
      transform: translate(-12%, 45%) !important;
    }
    h4 {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 431px) {
    .HCFurniture_Main {
      height: 100px !important;
    } 
    .overlay {
      transform: translate(0%, 10% !important);
      padding-left: 0px !important;
    }
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 10px;
    }
  }

  @media screen and (max-width: 425px) {
    .HCFurniture_Main {
      height: 100px !important;
    }    
    .overlay {
      transform: translate(0%, 10%) !important;
      padding-left: 0px !important;
    }
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 10px;
    }
  }

  @media screen and (max-width: 344px) {
    .overlay {
      transform: translate(0%, 10%) !important;
      padding-left: 0px !important;
    }
    h4 {
      font-size: 15px;
    }
    p {
      font-size: 6px;
    }
  }

  @media screen and (max-width: 320px) {
    .overlay {
      transform: translate(0%, 10%) !important;
      padding-left: 0px !important;
    }
    h4 {
      font-size: 15px;
    }
    p {
      font-size: 6px;
    }
  }
`;
