import React, { useState, useEffect } from "react";
import styled from "styled-components";
import HoverImage from "../../Assets/Images/GirlsHub Template/girls.png";
import { Link } from "react-router-dom";
import { Button } from "antd";
import zIndex from "@mui/material/styles/zIndex";
import API from "../../ApiService/ApiService";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

const TC10 = ({ category, content_all }) => {
  // console.log("category", category)
  // console.log("content_all==>category", content_all)
  const api = new API();
  const [hover, setHover] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(4);
  const [spaceBetween, setSpaceBetween] = useState(10);

  const updateSlidesPerView = () => {
    const windowWidth = window.innerWidth;
  
    if (windowWidth >= 1200) {
      setSlidesPerView(4);
      setSpaceBetween(10); // Use positive values here
    } else if (windowWidth >= 992) {
      setSlidesPerView(3);
      setSpaceBetween(10);
    } else if (windowWidth >= 768) {
      setSlidesPerView(2);
      setSpaceBetween(10);
    } else {
      setSlidesPerView(1);
      setSpaceBetween(10);
    }
  };

  useEffect(() => {
    updateSlidesPerView();
    window.addEventListener('resize', updateSlidesPerView);

    return () => {
      window.removeEventListener('resize', updateSlidesPerView);
    };
  }, []);

  return (
    <CategoryAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">OUR CATEGORIES</p>
          {/* <p className="Title">{category?.section_title}</p> */}
        </div>
        <div className="Category_Main">
          <Swiper
           breakpoints={{
            1200: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            992: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerView}
          >
            {content_all?.category?.map((item, index) => {
              return (
                <SwiperSlide key={`TC10_${index}`}>
                    <Link key={index} to={`/Products?catName=${item?.name}`}>
                    {/* {console.log("item", item)} */}
                    <div
                      className="Category_Box"
                      onMouseEnter={() => setHover(index)}
                      onMouseLeave={() => setHover(null)}
                      key={index}
                    >
                      <div
                        className="Category_Image"
                        style={{
                          borderRadius: hover === index ? "380px" : "380px",
                          background:
                            hover === index
                              ? "linear-gradient(0deg, rgba(99, 97, 97, 0.5) 0%, rgba(133, 128, 128, 0.5) 100%), #cfcbcb 50% / cover no-repeat"
                              : "none",
                          zIndex: hover === index ? -1 : 0,
                        }}
                      >
                        <img src={api?.rootUrl1 + item?.image} alt="" />
                        {hover === index && (
                          <div className="Category_Hover_Image">
                            <img src={HoverImage} alt="" />
                          </div>
                        )}
                      </div>
                      <div className="Category_Name">
                        <p className="Name">{item?.name}</p>
                      </div>
                      {hover === index && (
                        <div className="Category_Button">
                          <Button className="Button">{content_all?.btn_txt_cat}</Button>
                        </div>
                      )}
                    </div>
                </Link>
                  </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </CategoryAlign>
  );
};

export default TC10;

const CategoryAlign = styled.div`
margin-top: 100px;
  .home_wrapper {
    padding: 0 40px;
  }

  .Top_Title .Title {
    color: #000;
    font-family: Moneta;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  .Category_Main {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
  }

  .Category_Box {
    width: 300px;
    height: 480px;
    position: relative;
    /* background-color: #2f4076; */
  }

  .Category_Image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;    

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #d8d1d1cc;
    }
  }

  .Category_Hover_Image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      background-color: transparent;
    }
  }

  .Category_Name .Name {
    color: #fff;
    text-align: center;
    font-family: Moneta;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    position: absolute;
    bottom: 150px;
    left: 0;
    right: 0;
  }

  .Category_Button {
    position: absolute;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
  }

  .Category_Button .Button {
    width: 130px;
    height: 40px;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 32px;
    background: #fff;
    color: #2f4076;
    border: none;
  }

  .Category_Button .Button:hover {
    background-color: #fff;
    color: #2f4076;
  }

  @media screen and (min-width: 912px) and (max-width: 991px) {
    .Category_Box {
      width: 360px;
      height: 540px;
    }
  }

  @media screen and (min-width: 820px) and (max-width: 911px) {
    .Category_Box {
      width: 340px;
      height: 520px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 819px) {
    .Category_Box {
      width: 300px;
      height: 450px;
    }    
  }

  @media screen and (min-width: 539px) and (max-width: 767px) {
    .Category_Box {
      width: 400px;
      height: 600px;
      margin-left: 30px;
      /* border: 1px solid red; */
    }
    .Top_Title .Title {
      margin-left: 90px;
      font-size: 35px;
    }
    
  }

  @media screen and (min-width: 430px) and (max-width: 538px) {
    .Category_Box {
      width: 350px;
      height: 530px;
    }
    .Top_Title .Title {
      margin-left: 50px;
      font-size: 35px;
    }
    
  }

  @media screen and (min-width: 425px) and (max-width: 429px) {
    /* border: 1px solid red; */
    .Category_Box {
      /* border: 1px solid red; */
      width: 350px;
      font-size: 25px;
    }
  }

  @media screen and (min-width: 414px) and (max-width: 424px) {
    .Category_Box {
      width: 320px;
      margin-left: 7px;
    }
    .Top_Title .Title {
      margin-left: 35px;
      font-size: 35px;
    }    
  }

  @media screen and (min-width: 412px) and (max-width: 413px) {
    .Category_Box {
      width: 320px;
      margin-left: 7px;
    }
    .Top_Title .Title {
      margin-left: 35px;
      font-size: 35px;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 411px) {
    /* border: 1px solid red; */
    .Category_Box {
      /* border: 1px solid red; */
      width: 300px;
    }
    .Top_Title .Title {
      /* border: 1px solid red; */
      margin-left: 20px;
      font-size: 33px;
    }
  }

  @media screen and (min-width: 360px) and (max-width: 375px) {
    .Category_Box {
      width: 280px;
    }
    .Top_Title .Title {
      margin-left: 20px;
      font-size: 25px;
    }
    
  }

  @media screen and (min-width: 320px) and (max-width: 359px) {
    /* border: 1px solid red; */
    .Category_Box {
      /* border: 1px solid red; */
      width: 250px;
      height: 400px;
    }
    .Top_Title .Title {
      margin-left: 20px;
      font-size: 25px;
    }
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {
    .Category_Box {
      width: 220px;
      height: 400px;
    }
    .Top_Title .Title {
      margin-left: 20px;
      font-size: 25px;
    }
    .home_wrapper {
      padding: 0 30px;
    }
  }
`;
