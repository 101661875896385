import React, {useState, useEffect, createContext} from "react";
import styled from "styled-components";
import "../../Assets/Css/style.css";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cartStart, cartFailure, cartSuccess } from "../../Redux/slices/cart";
import image1 from "../../Assets/Images/Template-9/pro-1.png";
import image2 from "../../Assets/Images/Template-9/pro-2.png";
import image3 from "../../Assets/Images/Template-9/pro-3.png";
import image4 from "../../Assets/Images/Template-9/pro-4.png";
import {Rate, Button, message, Modal, Input, Form, Checkbox, Radio, Tag} from "antd";
import styles from "../../ApiService/Data";
import API from "../../ApiService/ApiService";
import crud_service from "../../ApiService/CrudService";
import {
  userGetAllStart,
  userGetAllSuccess,
  userGetAllFail,
} from "../../Redux/slices/user";

const crud = new crud_service();

let user = "";

const BestSeller2 = ({homecustom}) => {
  // console.log("BestSeller2--->", homecustom);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const loginTrue = localStorage.getItem("login");  
  const getUser = localStorage.getItem("user");

  const [hover, setHover] = useState(false);
  const api = new API();
  const dispatch = useDispatch();

  const [loginForm] = Form.useForm();

  const navigate  = useNavigate();

  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSaving, setSaving] = useState(false);

  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

  const getSingleProduct = (id) => {
    api.getSingleProduct(id).then((res) => {      
      addToCart(res?.data?.data?.productspec);
    });
  };

  const addToCart = async(ID) => {
    // console.log("addToCartID", ID);
    setAddToCartLoading(true);
    if (!loginTrue) {
      setAddToCartLoading(false);  
      setOpen(true);
    } else {
      const combinedData = {
        buyer_id: user?._id,
        qty: 1,
        product_id: ID[0]?.product_id,
        product_spec: ID[0]?._id,
      };
      await api.addCart(combinedData).then((res) => {
        if (res?.status == 200) {
          dispatch(cartStart);
          api.getcart().then((res) => {
            dispatch(cartSuccess(res?.data));
          });
          message.success(res?.data?.message);
          setAddToCartLoading(false);
          window.location.reload();
        } else {
          dispatch(cartFailure);
          message.error(res?.response?.data?.message);
          setAddToCartLoading(false);
        }
      });
    }
  };

  const login = async (values) => {
    let user;
    try {
      user = await api.create(
        dispatch,
        [userGetAllStart, userGetAllSuccess, userGetAllFail],
        "login",
        values
      );
    } catch (error) {
      message.error("Something went wrong");
      console.error("Login error:", error);
    }
    if (user?.status === 200) {
      // console.log("res--------->", user);
      message.success("Login Successful");
      localStorage.setItem("token", user?.data?.token);
      localStorage.setItem("user", JSON.stringify(user?.data?.data));
      localStorage.setItem("login", true);
      localStorage.setItem("buyer_name", values?.buyer_number);
      localStorage.setItem("buyer_pasword", values?.buyer_password);
      window.location.reload();
    } else {
      message.error(user?.response?.data?.message);
    }
  };

  const handleOk = () => {
    loginForm.resetFields();
    setOpen(false);
  };
  const handleCancel = () => {
    loginForm.resetFields();
    setOpen(false);
  };

  // console.log("singleProducts", singleProducts);

  // useEffect(() => {
  //   getSingleProduct();
  //   // getRating();
  //   // getProductDiscountDetails();
  // }, []);    

  return (
    <BestSellerAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">{homecustom?.section_title}</p>
          <Button className="Button" onClick={()=>navigate("/products")}>
            View All
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="14"
              viewBox="0 0 42 14"
              fill="none"
            >
              <path
                d="M1.83496 6.95688H39.3349M39.3349 6.95688C39.3349 6.95688 34.8469 4.00531 33.9349 1.55688M39.3349 6.95688C39.3349 6.95688 35.3674 9.73026 33.9349 12.3569"
                stroke="#0489D6"
                strokeWidth="2"
                strokeLinecap="square"
              />
            </svg>
          </Button>
        </div>
        <div className="Best_Seller_Main">
          {homecustom?.product_id?.map((data, index) => (
            <div
              className="Best_Seller_Box"
              key={index}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(null)}
              
            >
              <div
                className="Best_Seller_image"
                style={{
                  borderRadius: hover === index ? "8px 0px 0px 0px" : "0px",
                  position: "relative",
                  zIndex: hover === index ? 1 : 0,
                  backgroundColor:
                    hover === index
                      ? "rgba(0, 0, 0, 0.50)"
                      : null,
                }}
              >
                <img src={api.rootUrl1 + data?.images} alt="" />
                {hover === index && (
                  <div className="View_Detail_Button">
                    <Button className="view_Button" onClick={()=> navigate(`/product/${data?._id}`)}>View Details</Button>
                  </div>
                )}
              </div>
              <div className="Best_Seller_Description">
                <p className="Best_Seller_Category">{data?.category[0]?.name}</p>
                <p className="Best_Seller_ProductName">{data?.product_name}</p>
                <div className="Best_Seller_Rating">
                  <div className="Best_Seller_Rating_Star">
                    <Rate
                    disabled
                      className="Best_Seller_Rating_Star"
                      defaultValue={data?.rating}
                    />
                  </div>

                  <div className="Best_Seller_Rating_Count">
                    {data?.ratingCount}
                  </div>
                </div>

                {/* <div className="Best_Seller_Price">
                  <p className="Best_Seller_SP">{styles.currency + data?.sp}</p>
                  <p className="Best_Seller_MRP">
                    {styles.currency + data?.mrp}
                  </p>
                </div> */}
                <div className="Best_Seller_Button" onClick={() => getSingleProduct(data?._id)}>
                  <svg
                    className="Cart_Icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                  >
                    <rect
                      width="24"
                      height="24"
                      transform="translate(-0.000976562 0.344238)"
                      fill="#0489D6"
                    />
                    <path
                      d="M2.74902 3.59424L4.82902 3.95424L5.79202 15.4272C5.82901 15.8787 6.03487 16.2996 6.36851 16.606C6.70215 16.9124 7.13904 17.0818 7.59202 17.0802H18.502C18.9358 17.0807 19.3551 16.9246 19.6829 16.6405C20.0106 16.3565 20.2248 15.9636 20.286 15.5342L21.236 8.97624C21.2613 8.80183 21.252 8.62414 21.2086 8.45333C21.1652 8.28252 21.0886 8.12193 20.9831 7.98077C20.8776 7.8396 20.7453 7.72061 20.5938 7.63061C20.4422 7.5406 20.2745 7.48135 20.1 7.45624C20.036 7.44924 5.16302 7.44424 5.16302 7.44424"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.124 11.1392H16.897"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.15349 20.5471C7.22683 20.544 7.30004 20.5557 7.36872 20.5816C7.43741 20.6075 7.50014 20.647 7.55314 20.6978C7.60615 20.7486 7.64833 20.8096 7.67714 20.8771C7.70596 20.9446 7.72081 21.0172 7.72081 21.0906C7.72081 21.164 7.70596 21.2367 7.67714 21.3042C7.64833 21.3717 7.60615 21.4327 7.55314 21.4835C7.50014 21.5342 7.43741 21.5738 7.36872 21.5997C7.30004 21.6256 7.22683 21.6373 7.15349 21.6341C7.01338 21.6281 6.881 21.5682 6.78399 21.467C6.68697 21.3657 6.63281 21.2309 6.63281 21.0906C6.63281 20.9504 6.68697 20.8156 6.78399 20.7143C6.881 20.613 7.01338 20.5531 7.15349 20.5471Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.4337 20.5471C18.5782 20.5471 18.7168 20.6045 18.819 20.7067C18.9213 20.809 18.9787 20.9476 18.9787 21.0921C18.9787 21.2367 18.9213 21.3753 18.819 21.4775C18.7168 21.5797 18.5782 21.6371 18.4337 21.6371C18.2891 21.6371 18.1505 21.5797 18.0483 21.4775C17.9461 21.3753 17.8887 21.2367 17.8887 21.0921C17.8887 20.9476 17.9461 20.809 18.0483 20.7067C18.1505 20.6045 18.2891 20.5471 18.4337 20.5471Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              <div className="Top_Offer">
                <p className="Best_Seller_Offer">{data?.offer}</p>
              </div>
              {/* <div className="Top_Wishlist">
                <svg
                  className="Best_Seller_Wishlist"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="20"
                  viewBox="0 0 19 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.49267 9.71587C1.66312 7.12595 2.63183 4.1657 5.35164 3.28977C6.0569 3.0641 6.80591 3.01034 7.53617 3.133C8.26644 3.25565 8.95679 3.55115 9.54963 3.99484C10.6745 3.12509 12.3112 2.83131 13.7399 3.28977C16.4589 4.1657 17.4346 7.12595 16.6058 9.71587C15.3147 13.8211 9.54963 16.9831 9.54963 16.9831C9.54963 16.9831 3.82706 13.869 2.49267 9.71587V9.71587Z"
                    stroke="#272343"
                    strokeWidth="1.26509"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div> */}
            </div>
          ))}
        </div>
      </div>
      <Modal
          open={open}
          onCancel={handleCancel}
          onOk={handleOk}
          footer={null}
          title="Login"
          width={400}
        >
          <Form
            layout="vertical"
            form={loginForm}
            name="Login_Form"
            onFinish={login}
          >
            <Form.Item
              label="Enter Mobile number"
              name="buyer_number"
              rules={[
                {
                  required: true,
                  message: "Please enter your Mobile number!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Enter Password"
              name="buyer_password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={isSaving}
                className="primary_btn"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          <Or>or</Or>

          <NewAccount>
            <Link to="/register">Click Here</Link>
            to Register a Account
          </NewAccount>
        </Modal>
    </BestSellerAlign>
  );
};

export default BestSeller2;

const BestSellerAlign = styled.div`
  .home_wrapper {
    padding: 0 40px 0 40px;
  }

  p {
    padding: 0;
    margin: 0;
  }

  .Best_Seller_image {
    position: relative;
    transition: border-radius 0.3s;
  }

  .Best_Seller_image img {
    mix-blend-mode: multiply;
    filter: opacity(1);
  }

  .Best_Seller_image:hover {
    border-radius: 8px 0px 0px 0px;
    background: rgba(0, 0, 0, 0.5);
  }

  .View_Detail_Button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .View_Detail_Button .view_Button {
    width: 137.66px;
    height: 38.521px;
    flex-shrink: 0;
    border-radius: 8px 0px;
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    text-align: center;
    font-family: Flama Trial;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
    letter-spacing: -0.14px;
    text-transform: capitalize;
  }

  .View_Detail_Button .view_Button:hover {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
  }

  .Top_Title {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    gap: 700px;
    padding: 20px 0;
  }

  .Top_Title .Title {
    color: #1e1e1e;
    font-family: "Flama Trial";
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .Top_Title .Button {
    width: 100.346px;
    height: 39.733px;
    flex-shrink: 0;
    color: #0489d6;
    text-align: center;
    font-family: Flama Trial;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    border: none;
  }

  .Best_Seller_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 30px;
    padding: 0 20px;
    width: 100%;
    height: 100%;
  }

  .Best_Seller_Main .Best_Seller_Box {
    width: 250px;
    height: 400px;
    flex-shrink: 0;
    border-radius: 8px 0px;
    border: 1px solid #e7e7e7;
    background: #fff;
  }

  .Best_Seller_Main .Best_Seller_Box:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .Best_Seller_Main .Best_Seller_Box .Best_Seller_image {
    width: 250px;
    height: 260px;
    flex-shrink: 0;
    border-radius: 8px 0px 0px 0px;
    background: #ebf6fc;
    display: grid;
    place-content: center;
  }

  .Best_Seller_Main .Best_Seller_Box .Best_Seller_image img {
    width: 183.474px;
    height: 215.475px;
    flex-shrink: 0;
  }

  .Best_Seller_Main .Best_Seller_Box .Best_Seller_Description {
    padding: 10px 20px;
    line-height: 30px;
    /* border: 1px solid red; */
  }

  .Best_Seller_Main
    .Best_Seller_Box
    .Best_Seller_Description
    .Best_Seller_Category {
    color: #8d8d8d;
    font-family: Flama Trial;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .Best_Seller_Main
    .Best_Seller_Box
    .Best_Seller_Description
    .Best_Seller_ProductName {
    color: #000;
    font-family: Flama Trial;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis ;
  }

  .Best_Seller_Main
    .Best_Seller_Box
    .Best_Seller_Description
    .Best_Seller_Rating {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .Best_Seller_Main
    .Best_Seller_Box
    .Best_Seller_Description
    .Best_Seller_Rating
    .Best_Seller_Rating_Star {
    display: flex;
    /* width: 70px; */
    /* height: 12px; */
    align-items: center;
    gap: 2px;
    color: #343839;
    font-family: Flama Trial;
    font-size: 14px;
  }

  .Best_Seller_Main
    .Best_Seller_Box
    .Best_Seller_Description
    .Best_Seller_Rating
    .Best_Seller_Rating_Count {
    color: var(--neutral-07100, #141718);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .Best_Seller_Main
    .Best_Seller_Box
    .Best_Seller_Description
    .Best_Seller_Price
    ul {
    line-height: 0;
    margin: 0 !important;
    padding: 0 !important;
  }

  .Best_Seller_Main
    .Best_Seller_Box
    .Best_Seller_Description
    .Best_Seller_Price
    .Best_Seller_SP {
    color: var(--black-900, #121212);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px; /* 170% */
    letter-spacing: -0.6px;
  }

  .Best_Seller_Main
    .Best_Seller_Box
    .Best_Seller_Description
    .Best_Seller_Price
    .Best_Seller_MRP {
    color: var(--neutral-04100, #6c7275);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
    text-decoration-line: line-through;
  }

  .Top_Offer {
    width: 55px;
    height: 23.532px;
    flex-shrink: 0;
    border-radius: 8px 0px;
    /* background: #56c20e; */
    background: transparent;
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -430px;
    left: 10px;
  }

  .Top_Offer .Best_Seller_Offer {
    color: var(--neutral-01100, #fefefe);
    text-align: center;
    font-family: Flama Trial;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
  }

  .Top_Wishlist {
    width: 37.109px;
    height: 37.109px;
    flex-shrink: 0;
    border-radius: 6.747px 0px;
    background: rgba(255, 255, 255, 0.8);
    display: grid;
    place-items: center;
    position: relative;
    top: -460px;
    left: 200px;
  }

  .Top_Wishlist .Best_Seller_Wishlist {
    width: 18.555px;
    height: 18.555px;
    flex-shrink: 0;
  }

  .Best_Seller_Button {
    width: 44px;
    height: 44px;
    flex-shrink: 0;
    border-radius: 8px 0px;
    background: #0489d6;
    display: grid;
    place-items: center;
    /* position: relative;
    left: 160px;
    top: -50px; */
  }

  @media screen and (max-width: 767px) {
    .home_wrapper {
      padding: 0 20px;
    }

    .Top_Title {
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: center;
    }

    .Top_Title .Title {
      font-size: 25px;
    }

    .Top_Title .Button {
      font-size: 14px;
    }

    .Best_Seller_Main {
      display: grid;
      /* grid-template-columns: 1fr; */
      gap: 20px;
      padding: 0;
      align-items: center;
      justify-content: center;
    }

    .Best_Seller_Main .Best_Seller_Box {
      /* width: 70%; */
      height: auto;
    }

    .Best_Seller_Main .Best_Seller_Box .Best_Seller_image {
      width: 100%;
      height: auto;
      border-radius: 0;
    }

    .Best_Seller_Main .Best_Seller_Box .Best_Seller_image img {
      width: 100%;
      height: auto;
    }

    .Best_Seller_Description {
      /* width: 100%; */
    }
  }
`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;

const NewAccount = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
