import React, { useState, useEffect, createContext } from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import styled from "styled-components";
import ProductImg from "../../Assets/Images/product_img.png";
import {
  getSigleProductFail,
  getSigleProductStart,
  getSigleProductSuccess,
} from "../../Redux/slices/singleProduct";
import { useDispatch, useSelector } from "react-redux";
import { cartStart, cartFailure, cartSuccess } from "../../Redux/slices/cart";
import {
  wiseListStart,
  wiseListSuccess,
  wiseListFailure,
} from "../../Redux/slices/wishList";
import {
  HomeOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UpOutlined,
  DownOutlined,
  HeartOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Divider,
  Row,
  Col,
  Tabs,
  Tooltip,
  Alert,
  Modal,
  Button,
  Image,
  List,
  Rate,
  Form,
  Input,
  message,
  Card,
  Radio,
  Skeleton,
  Tag,
} from "antd";
import { Comment } from "@ant-design/compatible";
import Default from "../../Assets/Images/default.png";
import API from "../../ApiService/ApiService";
import moment from "moment";
import crud_service from "../../ApiService/CrudService";
import { theme, font } from "../../ApiService/Theme";
import App from "../../App";
import styles from "../../ApiService/Data";

import {
  userGetAllStart,
  userGetAllSuccess,
  userGetAllFail,
} from "../../Redux/slices/user";
import { slice } from "lodash";

const crud = new crud_service();

const { TextArea } = Input;

const ProductContext = createContext();
let specificationSet;
let user = "";

const HomeProduct2 = ({ homecustom, content_all }) => {

  console.log("HomeProduct2content_all", content_all);

  const location = useLocation();
  const loginTrue = localStorage.getItem("login");
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const api = new API();
  // const filterForDefaultSize = homecustom.map((item) => item?.specification[1]?._id)
  // const filterForDefaultColor = homecustom.map((item) => item?.specification[0]?._id)
  // const filterForDefaultSpec = homecustom?.map((item) => (item?._id))
  const [products, setProducts] = useState([]);
  const [specData, setSpecData] = useState([]);
  const [isVideoVisible, setVideoVisible] = useState(false);
  const [sizeID, setSizeID] = useState();
  const [sizeSpecID, setSizeSpecID] = useState();
  const [colorID, setColorID] = useState();
  const [colorSpecID, setColorSpecID] = useState();
  const [count, setCount] = useState(1);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [wishListLoading, setWishListLoading] = useState(false);
  const [totalProducts, setTotalProducts] = useState([]);
  const [filterSpecs, setFilterSpecs] = useState([]);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [rating, setRating] = useState([]);
  const getUser = localStorage.getItem("user");
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const dispatch = useDispatch();
  const params = useParams();
  const [isSaving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const [reviews, setreviews] = useState([]);
  const [discountsrules, setProductsDiscountDetails] = useState([]);

  const [loginForm] = Form.useForm();

  const navigate = useNavigate();

  // console.log("loginTrue--->", loginTrue);

  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

  useEffect(() => {
    console.log("i am in useeffect")
    getSingleProduct();
    // getRating();
    // getProductDiscountDetails();
  }, [content_all?.gowri_main_products?.[0]?.value]);
  // console.log("homecustom??", homecustom);
  // console.log("params??", params);
  const prod_id = content_all?.gowri_main_products?.[0]?.value;
  // const prod_id = homecustom[0]?.product_id;
  const InitialStaticImageCheck = content_all?.gowri_main_products?.[0]?.label;
  // const InitialStaticImageCheck = homecustom[0]?.product_images[0]?.image_path;
  // const InitialStaticImageNameCheck =
  //   homecustom[0]?.specification[0]?.specification_details?.toUpperCase();

  // console.log("InitialStaticImageNameCheck", InitialStaticImageNameCheck);

  const getSingleProduct = () => {
    api.getSingleProduct(prod_id).then((res) => {
      console.log("res??", res)
      setProducts(res?.data?.data?.product);
      setTotalProducts(res?.data?.data);
      setSpecData(res?.data?.data?.productspec);
      setreviews(res?.data?.data?.reviews);
    });
  };

  console.log("specData--->", specData);
  console.log("products--->", products);
  console.log("totalProducts--->", totalProducts);

  const filterForDefaultSize = specData.map((item) => item?.specification[1]?._id)

  console.log("filterForDefaultSize--->", filterForDefaultSize)

  // console.log("products", products?.product_name);

  // console.log("homeproduxct--->", homecustom);

  const colors = [
    "#E33932",
    "#F38A19",
    "#2566BC",
    // "#E1D11F",
    // "#22AA67",
    // "#D577B0",
    // "#938888",
    // "#F1B7B1",
    // "#B4D2D3",
    // "#1F1B17",
  ];
  const [selectedColor, setSelectedColor] = useState(
    // homecustom[0]?.specification[0]?.specification_details
  );
  const [qty, setQty] = useState(0);
  const [selectedValue, setSelectedValue] = useState();

  const handleColorChange = async (a, b) => {
    // console.log("colorSpecID", b);
    // console.log("colorID", a);
    setColorID(a);
    setColorSpecID(b);

    let values = {
      size_id: sizeID,
      sizespec_id: sizeSpecID,
      color_id: a,
      colorspec_id: b,
    };
    // console.log("values", values);

    let param = {
      spec_ids: [b, sizeSpecID],
      detail_ids: [a, sizeID],
    };

    // console.log("param", param);
    try {
      const res = await api.getproductspec(param, prod_id);
      const data = res?.data?.data?.productspec;
      // console.log("data", data);
      setSpecData(data);

      const filteredSpecs = data?.map((spec) => {
        // console.log("spec", spec);
        return spec?.specification?.map((insidespec) => {
          return insidespec?.specification_details;
        });
      });
      setFilterSpecs([...filteredSpecs.flat()]);
      setSkeletonLoading(false);
      localStorage.setItem("spec_data", JSON.stringify(data));
    } catch (err) {
      message.error("Something Went Wrong");
      setSkeletonLoading(false);
    }
  };

  const handleIncreaseQty = () => {
    setQty((pre) => {
      return pre + 1;
    });
  };

  const handleDecreaseQty = () => {
    setQty((pre) => {
      if (qty > 0) {
        return pre - 1;
      } else {
        return 0;
      }
    });
  };

  const handleSizeChange = async (b, a) => {
    console.log("sizeSpecID", b);
    console.log("sizeID", a);
    setSizeID(a);
    setSizeSpecID(b);
    let values = {
      size_id: a,
      sizespec_id: b,
      color_id: colorID,
      colorspec_id: colorSpecID,
    };
    // console.log("values", values);
    let param = {
      spec_ids: [b, colorSpecID ? colorSpecID : ""],
      detail_ids: [a, colorID ? colorID : ""],
    };
    // console.log("param", param);
    try {
      const res = await api.getproductspec(param, prod_id);
      const data = res?.data?.data?.productspec;
      console.log("data", data);
      setSpecData(data);
    } catch (err) {
      message.error("Something Went Wrong");
      setSkeletonLoading(false);
    }
  };

  const handleRadioChange = (e) => {
    // console.log("handleRadioChange", e.target.value);
    const value = e.target.value;
    // let newSize, newCost;

    setSelectedValue(value);
  };

  // const AddProductToCart = () => {
  //   const { size, cost } = selectedValue;
  //   const value = {
  //     color : selectedColor,
  //     qty: qty,
  //     size: size,
  //     price: cost,
  //   }
  //   console.log("value", value);
  // };

  // const handleThumbnailClick = (imagePath) => {
  //   setMainImage(imagePath);
  // };

  const addToCart = () => {
    setAddToCartLoading(true);

    if (!loginTrue) {
      setAddToCartLoading(false);

      setOpen(true);
    } else {
      const combinedData = {
        buyer_id: user?._id,
        qty: qty,
        product_id: prod_id,
        // product_spec: specData[0]?._id ? specData[0]?._id : filterForDefaultSpec[0],
      };
      console.log("combinedData", combinedData);

      api.addCart(combinedData).then((res) => {
        if (res?.status == 200) {
          dispatch(cartStart);
          api.getcart().then((res) => {
            // console.log("res--->", res);
            dispatch(cartSuccess(res?.data));
          });
          message.success("Added Successfully");
          window.location.reload();
          setAddToCartLoading(false);
        } else {
          dispatch(cartFailure);
          message.error("Something went wrong");
          setAddToCartLoading(false);
        }
      });
    }
  };

  const addToWiseList = () => {
    setWishListLoading(true);

    if (!loginTrue) {
      setWishListLoading(false);

      setOpen(true);
    } else {
      const combinedData = {
        buyer_id: user?._id,
        qty: qty,
        product_id: params?.id,
        product_spec: specData[0]?._id,
      };

      api.addWiseList(combinedData).then((res) => {
        console.log(res);
        if (res?.data?.success) {
          dispatch(wiseListStart);
          api.getWishList().then((res) => {
            // console.log("res--->", res);
            dispatch(wiseListSuccess(res?.data));
          });
          message.success("Added Successfully");
          setWishListLoading(false);
        } else {
          dispatch(wiseListFailure(res?.data));
          message.error(res?.response?.data?.message);
          setWishListLoading(false);
        }
      });
    }
  };

  const handleChangeIncrease = (type) => {
    // console.log("type", type);
    if (type === "minus") {
      setCount(count - 1);
    } else if (type === "plus") {
      setCount(count + 1);
    }
  };

  // console.log("specData", specData);

  const primaryArray = [];
  const secondaryArray = [];

  totalProducts?.product?.specification?.slice(0, 1)?.map((item) => {
    item?.specification_details?.map((e) => {
      return primaryArray.push(e?.specification_details);
    });
  });
  totalProducts?.product?.specification?.slice(1)?.map((item) => {
    item?.specification_details?.map((e) => {
      return secondaryArray.push(e?.specification_details);
    });
  });

  const htmlContent = totalProducts?.product?.product_description;

  const items = [
    {
      key: "1",
      label: (
        <div className="label">
          <h5>Descriptions</h5>
        </div>
      ),
      children: <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>,
    },
    {
      key: "2",
      label: (
        <div className="label">
          <h5>Specifications</h5>
        </div>
      ),
      children: (
        <div className="specifications">
          {totalProducts?.product?.specification?.map((spec) => (
            <>
              <p>
                <b>{spec?.specification?.name} :</b>{" "}
                {spec?.specification_details?.map((item) => (
                  <>{item?.specification_details}, </>
                ))}
              </p>
            </>
          ))}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="label">
          <h5>Additional Information</h5>
        </div>
      ),
      children: (
        <div>
          <h5>Packing Details</h5>
          <p>
            <b>Height:</b> {totalProducts?.product?.packing_details?.height}
          </p>
          <p>
            <b>Breath:</b> {totalProducts?.product?.packing_details?.breadth}
          </p>
          <p>
            <b>Length:</b> {totalProducts?.product?.packing_details?.length}
          </p>
          <p>
            <b>Weight:</b> {totalProducts?.product?.packing_details?.weight}{" "}
            {totalProducts?.product?.packing_details?.weight_type}
          </p>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div className="label">
          <h5>Review</h5>
        </div>
      ),
      children: (
        <div className="review_section">
          <div className="review_left">
            {/* <h2>Review</h2> */}
            <Card
              bordered={true}
              className="Review_Section"
              title={reviews?.length + " " + "Reviews"}
              style={{ height: "400px", overflowX: "auto" }}
            >
              <List
                className="comment-list"
                itemLayout="horizontal"
                dataSource={reviews}
                renderItem={(item) => (
                  <li key={item?._id}>
                    <Comment
                      // actions={item?.actions}
                      author={item?.buyer_id?.buyer_name}
                      avatar={Default}
                      content={item?.review}
                      datetime={moment
                        .utc(item?.createdAt)
                        .local()
                        .startOf("seconds")
                        .fromNow()}
                    />
                    <div className="rating_design">
                      <Rate disabled defaultValue={item?.rating} />
                    </div>
                  </li>
                )}
              />
            </Card>
            <br />
          </div>
        </div>
      ),
    },
  ];

  const login = async (values) => {
    let user;
    try {
      user = await api.create(
        dispatch,
        [userGetAllStart, userGetAllSuccess, userGetAllFail],
        "login",
        values
      );
    } catch (error) {
      message.error("Something went wrong");
      console.error("Login error:", error);
    }
    if (user?.status === 200) {
      // console.log("res--------->", user);
      message.success("Login Successful");
      localStorage.setItem("token", user?.data?.token);
      localStorage.setItem("user", JSON.stringify(user?.data?.data));
      localStorage.setItem("login", true);
      localStorage.setItem("buyer_name", values?.buyer_number);
      localStorage.setItem("buyer_pasword", values?.buyer_password);
      window.location.reload();
    } else {
      message.error(user?.response?.data?.message);
    }
  };

  const handleOk = () => {
    loginForm.resetFields();
    setOpen(false);
  };
  const handleCancel = () => {
    loginForm.resetFields();
    setOpen(false);
  };  
 
  // console.log("specData", specData);
  // console.log("filterForDefaultSize", filterForDefaultSize);
  // console.log("filterForDefaultColor", filterForDefaultColor);
  // console.log("filterForDefaultSpec", filterForDefaultSpec[0]);

  return (
    <Section>
      <div className="main_container">
        <div className="product_img_container">
          <div className="background_container">
            <img
              src={
                specData[0]?.product_images[0]?.image_path
                  ? api.rootUrl1 + specData[0]?.product_images[0]?.image_path
                  : api.rootUrl1 + InitialStaticImageCheck
              }
              alt="plate"
            />
            <h3>{products?.product_name}</h3>
          </div>
        </div>
        <div className="product_details">
          <h2>{products?.product_name}</h2>
          <p className="size_title">Available Sizes</p>         
        <Radio.Group
            className="custom-radio-group"
            defaultValue={filterForDefaultSize?.[0]}
            onChange={handleRadioChange}
            style={{
              marginTop: 16,
            }}
          >
            {specData?.map((item) => (
              <Radio.Button
                key={item?.specification[1]?._id}
                value={item?.specification[1]?._id}
                onChange={() => {
                  handleSizeChange(
                    item?.specification[1]?.specification_id?._id,
                    item?.specification[1]?._id
                  );
                }}
              >
                <div className="first">
                  <p className="size">
                    {item?.specification[2]?.specification_details}
                  </p>
                  <p className="cost">&#8377; {item?.mrp}/Plate</p>
                </div>
              </Radio.Button>
            ))}
            {/* <Radio.Button value="c">
              <div className="second">
                <p className="size">13 Inch</p>
                <p className="cost">&#8377; 52 / Plate</p>
              </div>
            </Radio.Button>
            <Radio.Button value="d">
              <div className="third">
                <p className="size">13 Inch Heavy</p>
                <p className="cost">&#8377; 54 / Plate</p>
              </div>
            </Radio.Button> */}
          </Radio.Group>
          

          <p className="color_title">Available Colors</p>
          <div className="color_container">
            {specData?.map((item, index) => (
              <div
                key={index}
                value={item?.specification[0]?._id}
                className={`color-${index} ${
                  selectedColor == item?.specification[0]?.specification_details
                    ? "selected"
                    : ""
                }`}
                style={{
                  backgroundColor:
                    item?.specification[0]?.specification_details,
                }}
                onClick={() => {
                  setSelectedColor(
                    item?.specification[0]?.specification_details
                  );
                  // setColorID(item?.specification[0]?._id);
                  // setColorSpecID(item?.specification[0]?.specification_id?._id);
                  handleColorChange(
                    item?.specification[0]?._id,
                    item?.specification[0]?.specification_id?._id
                  );
                }}
              >
                {" "}
                {selectedColor ===
                  item?.specification[0]?.specification_details && (
                  <div className="white-dot"></div>
                )}
              </div>
            ))}
          </div>
          <p className="qty_title">Quantity</p>
          <div className="qty_cart_container">
            <div className="qty_container">
              <Button className="minus" onClick={handleDecreaseQty}>
                <MinusOutlined />
              </Button>
              <Input 
              value={qty} 
              type="number" 
              readOnly 
              min={1} 
              style={{ 
                textAlign: 'right', 
                border: 'none',     
              }}
              />
              {/* <span>{qty}</span> */}
              <Button className="plus" onClick={handleIncreaseQty}>
                <PlusOutlined />
              </Button>
            </div>
            <Button disabled={qty < 1} onClick={() => addToCart()} className="add_to_cart_btn">
              Add To Cart
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onCancel={handleCancel}
        onOk={handleOk}
        footer={null}
        title="Login"
        width={400}
      >
        <Form
          layout="vertical"
          form={loginForm}
          name="Login_Form"
          onFinish={login}
        >
          <Form.Item
            label="Enter Mobile number"
            name="buyer_number"
            rules={[
              {
                required: true,
                message: "Please enter your Mobile number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Enter Password"
            name="buyer_password"
            rules={[
              {
                required: true,
                message: "Please enter your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={isSaving}
              className="primary_btn"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
        <Or>or</Or>

        <NewAccount>
          <Link to="/register">Click Here</Link>
          to Register a Account
        </NewAccount>
      </Modal>
    </Section>
  );
};

export default HomeProduct2;

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0 0 0;
  .main_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    width: 1200px;

    /* height: 100%; */

    .product_img_container {
      /* width: 50%;
        border: 1px solid red; */
    }

    .background_container {
      width: 450px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: -1000;
      /* border: 1px solid red; */
      ::before {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 400px;
        content: "";
        z-index: -1000;
        border-radius: 48px;
        background: #fffbdc;
      }

      h3 {
        color: #f3891a;
        font-family: Brevia;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 25px;
      }

      img {
        position: relative;
        top: -50px;
        mix-blend-mode: multiply;
        filter: contrast(1);
        /* z-index: -1000; */
      }
    }

    .product_details {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        margin-bottom: 25px;
      }

      .size_title {
        color: #8d8d8d;
        font-family: Brevia;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .color_title {
        color: #8d8d8d;
        font-family: Brevia;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 25px 0 15px 0;
      }

      .custom-radio-group {
        margin-top: 0px !important;
        border: 0.5px solid #000;
        width: 392px;
        .ant-radio-button-wrapper {
          height: 65px;
          width: 130px;
          border-radius: 0 !important;
          outline: none;
          border: none;
          padding: 10px;

          &:focus {
            outline: none;
            border: none;
          }
        }

        .ant-radio-button-wrapper-checked:not(
            .ant-radio-button-wrapper-disabled
          )::before {
          background-color: transparent;
        }

        .ant-radio-button-checked {
          background-color: #d3fcdd;
          outline: none;
          border: none;
        }

        p {
          margin: 0px;
        }

        .size {
          color: #000;
          font-family: Brevia;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .cost {
          margin-top: 10px;
          color: #7e7e7b;
          font-family: Brevia;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      .color_container {
        display: grid;
        grid-template-columns: repeat(5, 30px);
        gap: 15px;
        div {
          position: relative;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: none;
          cursor: pointer;

          .white-dot {
            position: absolute;
            width: 15px;
            height: 15px;
            background-color: white;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      .qty_title {
        color: #8d8d8d;
        font-family: Brevia;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 25px 0 15px 0;
      }

      .qty_cart_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 330px;
        .qty_container {
          width: 150px;
          height: 50px;
          border: 0.5px solid #000;
          display: flex;
          align-items: center;
          justify-content: space-between;

          button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background-color: transparent;
            width: 30%;
            height: 100%;
            text-align: center;
            color: #000;
            font-family: Brevia;
            font-style: normal;
            font-weight: 400;
            line-height: 10px;
          }
          .minus {
            font-size: 40px;
          }
          .plus {
            font-size: 32px;
          }

          span {
            color: #000;
            font-family: Brevia;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }

        .add_to_cart_btn {
          background-color: #01c834;
          color: white;
          border: none;
          width: 50%;
          height: 100%;
          /* padding: 15px 20px 15px 20px; */
          border-radius: 0;
          line-height: 40px;
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .main_container {
      width: 1000px !important;

      .background_container {
        width: 380px;
        ::before {
          height: 350px;
        }
      }
    }
  }
  @media screen and (max-width: 786px) {
    .main_container {
      width: 700px !important;
      grid-template-columns: 0.6fr 0.7fr;
    }

    .product_img_container {
      width: 280px !important;
    }

    .background_container {
      width: 280px !important;
      ::before {
        height: 280px !important;
      }
    }

    .custom-radio-group {
      width: 362px !important;
      .ant-radio-button-wrapper {
        width: 120px !important;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .main_container {
      width: 440px !important;
      grid-template-columns: repeat(1, 1fr) !important;
      gap: 20px !important;
      .product_img_container {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .background_container {
        width: 350px !important;
        ::before {
          height: 300px !important;
        }
      }
    }
  }
  @media screen and (max-width: 360px) {
    .main_container {
      width: 280px !important;
      .product_img_container {
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .background_container {
        width: 280px !important;
        ::before {
          height: 300px !important;
        }
      }

      .custom-radio-group {
        width: 302px !important;
        .ant-radio-button-wrapper {
          width: 100px !important;
        }
      }

      .size {
        font-size: 12px !important;
      }

      .cost {
        font-size: 12px !important;
      }
    }

    .qty_container {
      width: 100px !important;
      height: 35px !important;
    }
    .add_to_cart_btn {
      padding: 10px !important;
    }
  }
`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;

const NewAccount = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
