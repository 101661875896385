import React from 'react'
import styled from 'styled-components'
import { font, theme } from '../../ApiService/Theme'
import { Link } from "react-router-dom";
import { base_url } from '../../config'
import default_image from '../../Assets/Images/default.png';


const Hc31 = ({ props }) => {
  return (
    <Hc2Section>
      <div className='hc2_section'>
        <div className='wrapper'>
          <H2>{props?.section_value ? props?.section_value : ""}</H2>
          <div className='hc2_align'>
            <div className='hc2_left'>
              <ul>
                {
                  props?.content?.slice(0, 1).map((e, i) => {
                    return (
                      <li key={i}>
                        <div className='hc2_box'>
                          <img src={e?.image ? base_url + e?.image : default_image} alt={e?.title?e?.title:""} />
                          <div className='hc2_content'>
                            <h4>{e?.title?e?.title:""}</h4>
                            <Link to={e?.link ? e?.link : void 0}><button>{e?.link_text ? e?.link_text : "Shop Now"}</button></Link>
                          </div>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div className='hc2_right'>
              <ul>
                {
                  props?.content?.slice(1, 3).map((e, i) => {
                    return (
                      <li key={i}>
                        <div className='hc2_box'>
                          <img src={e?.image ? base_url + e?.image : default_image} alt="Hc2 Section" />
                          <div className='hc2_content'>
                            <h4>{e?.title}</h4>
                            <Link to={e?.link ? e?.link : void 0}><button>{e?.link_text ? e?.link_text : "Shop Now"}</button></Link>
                          </div>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>

        </div>
      </div>
    </Hc2Section>
  )
}

export default Hc31
const H2 = styled.h2`
   font-size:30px;
   margin : 0 0 35px;
   text-transform: uppercase;
   font-family: ${font?.regular} !important;
   letter-spacing: 0.7px;

   @media screen and (max-width:768px) {
    text-align: center;
   }
`
const Hc2Section = styled.section`
    display: inline-block;
    width: 100%;
    position: relative;

    .hc2_section {
        display: inline-block;
        width: 100%;
        position: relative;
    }

    .hc2_align {
        display: flex;
        align-items: stretch;
        width: 100%;
        gap: 30px;
    }
    .hc2_align .hc2_left {
        flex: 2;
        
    }
    .hc2_align .hc2_right {
        flex: 1;
        
    }

.hc2_section .hc2_align ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 30px;
        height: 100%;
        width: 100%;
    }
    .hc2_section .hc2_align ul li {
        width: 100%;
        display: flex;
        position: relative;
        height: 100%;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0 0 15px rgb(0 0 0 / 5%);
    }
    .hc2_section .hc2_align ul li::before {
        content: "";
    position: absolute;
    background: rgb(0 0 0 / 35%);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 12px;
}
    .hc2_section .hc2_align ul li .hc2_box {
        display: inline-block;
        width: 100%;
        position: relative;
   
    }
    .hc2_section .hc2_align ul li .hc2_box img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .hc2_section .hc2_align ul li .hc2_box .hc2_content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        text-align: left;
       
    z-index:10;
    }
    .hc2_section .hc2_align ul li .hc2_box .hc2_content h4 {
        font-size: 30px;
    font-family: ${font?.bold} !important;
    text-transform: uppercase;
    color: #fff !important;
    font-weight: 600;
    -webkit-letter-spacing: 1px;
    -moz-letter-spacing: 1px;
    -ms-letter-spacing: 1px;
    letter-spacing: 1px;
    margin: 0 0 5px;
    width: 100%;
    }
    .hc2_section .hc2_align ul li .hc2_box .hc2_content button {
    width: fit-content;
    margin: 0px 0 0 0;
    border: 0;
    border-radius: 8px;
    padding: 6px 15px;
   display: flex;
    text-align: center;
    background: ${theme?.bg};
    color: ${theme?.while};
    font-family: ${font?.regular} !important;
}


@media screen and (max-width:768px) {
    .hc2_align {
        flex-direction: column;
    }
     .hc2_section .hc2_align ul li .hc2_box .hc2_content h4 {
    font-size: 26px;
    }
    .hc2_section .hc2_align ul li .hc2_box .hc2_content { 
        margin: auto;
    text-align: center;
    width: 100%;
    align-items: center;
    justify-content: center;
    }
    .hc2_section .hc2_align ul li .hc2_box .hc2_content { 
        margin: auto;
    }
 
}


`