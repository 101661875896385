import { Collapse } from "antd";
import React from "react";
import styled from "styled-components";
import { CaretRightOutlined } from "@ant-design/icons" 
const { Panel } = Collapse;
const text = `
Our plastic buffet plates are thoughtfully designed to blend seamlessly with various dining settings, adding a touch of elegance and sophistication to your table.
`;


const FAQ1 = ({ homecustom, content_all }) => {


  // console.log("FAQ Inside-->", content_all);

  const customExpandIcon = ({ isActive }) => {
    const iconColor = isActive ? '#01C834' : 'black';

    return (
      <CaretRightOutlined
        style={{ color: iconColor }}
        rotate={isActive ? 270 : 90}
      />
    );
  };

  return (
    <Section id="faq">
      <div className="main_container">
        <h2>{content_all?.faq_sec_title}</h2>
        <Collapse  
        expandIconPosition="right"  
        expandIcon={customExpandIcon} 
        className="custom_collapse" >
          {content_all?.faq_qus_ans?.map((faq, index) => (
        <Panel header={faq.question} key={index}>
          <p>{faq.answer}</p>
        </Panel>
      ))}
        </Collapse>
      </div>
    </Section>
  );
};

export default FAQ1;

const Section = styled.section`
  /* min-height: 100vh;
  height: 100%; */
  display: flex;

  .main_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    

    h2 {
      margin-bottom: 15px;
    }

    .custom_collapse {
      width: 60%;

      border-radius: none !important;
    }

    .ant-collapse {
      background-color: white;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border: none;
    }

    .ant-collapse
      > .ant-collapse-item {
      border: none;
    }

    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header {
      /* flex-direction: row-reverse; */
      border: 1px solid #000;
      background: #fff;
      padding: 20px 16px;
      /* box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15); */
    }

    .ant-collapse
      > .ant-collapse-item:last-child
      > .ant-collapse-header {
      border-radius: 0;
    }

    .ant-collapse
      .ant-collapse-content {
      border: none;
    }

    .ant-collapse
      .ant-collapse-content
      > .ant-collapse-content-box {
      p {
        margin: 0;
        color: #898787;

        font-family: Brevia;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 32px */
      }
    }
  }

  @media screen and (max-width: 480px) {
    .ant-collapse{
      width: 400px !important;
      /* border: 1px solid red !important; */
    }
  }
  @media screen and (max-width: 768px) {
    .ant-collapse{
      width: 80% !important;
    }
  }
`;
