import React from 'react'
import { Card } from "antd"
import { NavLink } from 'react-router-dom'
import styled from 'styled-components';

import { styles } from '../ApiService/Data'

const SideBar = () => {
  return (
    <>
      <SideBarSection>
        <div className='Side_BarMain'>
          <ul className="Side_BarMainUl" >
            <li>
              <NavLink className="link" to="/my-profile" style={({ isActive }) => ({
                color: isActive
                  ? "#f65c7f"
                  : "#000",
                textDecoration: 'none'
              })} >
                My Profile
              </NavLink>
            </li>
            <li>
              <NavLink className="link" to="/delivery-address" style={({ isActive }) => ({
                color: isActive
                  ? "#f65c7f"
                  : "#000",
                textDecoration: 'none'
              })} >
                Delivery Address
              </NavLink>
            </li>
            <li>
              <NavLink className="link" to="/my-order" style={({ isActive }) => ({
                color: isActive
                  ? "#f65c7f"
                  : "#000",
                textDecoration: 'none'
              })} >
                My Order
              </NavLink>
            </li>
            <li>
              <NavLink className="link" to="/change-password" style={({ isActive }) => ({
                color: isActive
                  ? "#f65c7f"
                  : "#000",
                textDecoration: 'none'
              })} >
                Change Password
              </NavLink>
            </li>
          </ul>
        </div>
      </SideBarSection>
      <br />
    </>

  );
}

export default SideBar;

const SideBarSection = styled.section`
  min-width: 295px;
  border: 1px solid red;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  min-height: 300px;
  padding: 20px;
  line-height: 50px;
  ul {
    list-style: none !important;
    li {
      list-style: none !important;
      margin-bottom: 10px;
      color: black;
      .link {
        color: black;
        
      }
    }
  }

  @media screen and (min-width: 885px) and (max-width: 1024px) {
    min-width: 220px;
    .Side_BarMain {
      min-width: 250px;
    }  
  }

  @media screen and (min-width: 768px) and (max-width: 884px) {
    .Side_BarMain {
      min-width: 250px;
    }    
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    .Side_BarMain {
      min-width: 250px;
    }    
  }

  @media screen and (min-width: 425px) and (max-width: 539px) {
    .Side_BarMain {
      min-width: 360px;
    }
    .Side_BarMainUl li{
      font-size: 12px;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 424px) {
    .Side_BarMain {
      min-width: 310px;
    }
    .Side_BarMainUl li{
      font-size: 12px;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 374px) {    
      min-width: 250px !important;
      .Side_BarMain {
      min-width: 260px;
    }
    .Side_BarMainUl li{
      font-size: 12px;
    }
  }

  /* @media screen and (max-width: 768px) {
    .Side_BarMain {
      min-width: 320px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

   .Side_BarMain ul {
    list-style: none !important;

    li {
      list-style: none !important;
      margin-bottom: 10px;
      color: black;
      .link {
        color: black;
        list-style: none !important;
      }
    }
  }
  } */



`;
