import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  message,
  Badge,
  Menu,
  Button,
  Dropdown,
  Drawer,
  Tooltip,
  Tabs,
  Space,
  Avatar,
} from "antd";
import { AiOutlineMenu } from "react-icons/ai";
import Login from "../../Ecommerce/Login";
import SearchBoxComponent from "../../Ecommerce/SearchBox";
// import MobileMenu from "../MenuBar/MobileMenu"
import {
  SearchOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  LogoutOutlined,
  DownOutlined,
  ProfileOutlined,
  LoginOutlined,
  MailFilled,
  PhoneFilled,
} from "@ant-design/icons";
import API from "../../ApiService/ApiService";
import { connect, useSelector, useDispatch } from "react-redux";
import { getCartList } from "../../Redux/Store";
import styled from "styled-components";
import { styles } from "../../ApiService/Data";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user).setColor;
const { SubMenu } = Menu;

const Header4 = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const loginTrue = useSelector((state) => state?.user?.currentUser?.token);
  const cartList = useSelector((state) => state?.cart?.products);
  const [search, setSearch] = useState(false);
  const [category, setCategory] = useState([]);
  const [productcategory, setProductcategory] = useState([]);
  const [visible, setVisible] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [themeTrue, setThemeTrue] = useState("");
  const socialMediaLink = useSelector(
    (state) => state?.company?.socialMediaLinks
  );
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
  }, []);

  const liveurl = window.location.pathname;
  const api = new API();
  const company = useSelector((state) => {
    return state?.company?.value;
  });

  const toogleSearch = () => {
    setSearch(!search);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const logout = (values) => {
    api.logout(dispatch);
    history("/");
  };

  const menu = (
    <Menu onClick={onClose} mode="horizontal">
      <Menu.Item key="1_profile">
        <Link to="/my-profile">
          <ProfileOutlined /> Profile
        </Link>
      </Menu.Item>
      <Menu.Item key="1_order">
        <Link to="/my-order">
          <ShoppingOutlined /> My Order
        </Link>
      </Menu.Item>
      <Menu.Item key="1_signout">
        <a onClick={logout}>
          <LogoutOutlined /> Sign out
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <HeaderSection>
        <header
          className={liveurl == "/" ? "HE_4" : "HE_4 Inner_Pages_All"}
          id={
            themeTrue === true ? (scroll ? "bg_black" : "bg_none") : "bg_black"
          }
        >
          <div className="Wrapper_Full">
            <div className="HE_4_Align">
              <div className="HE_4_Left">
                <Link to="/">
                  <img className="HE_4_Logo" src={company?.logo} />
                </Link>
              </div>
              <div className="HE_4_Right">
                <div className="HE_4_Right_Profile">
                  {!loginTrue && loginTrue !== "" ? (
                    <Link to="/login">
                      <LoginOutlined
                        style={{ color: "white", fontSize: "20px" }}
                      />
                    </Link>
                  ) : (
                    <Dropdown overlay={menu}>
                      <Button
                        type="text"
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        My Account
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  )}
                </div>
                <div className="HE_4_Right_CartBtn">
                  <Link to="/cart" onClick={onClose}>
                    <Badge count={cartList?.length}>
                      <Button type="text">
                        <ShoppingCartOutlined />
                      </Button>
                    </Badge>
                  </Link>
                </div>
                <div className="HE_4_SearchBar">
                  {search && <SearchBoxComponent />}
                  <Button type="text" onClick={toogleSearch}>
                    <SearchOutlined />
                  </Button>
                </div>
                <div className="HE_4_Navbar">
                  <div className="HE_4_Navigation">
                    <div className="HE_4_MobileMenu">
                      {/* <MobileMenu /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {themeTrue === true ? "" : <div className="others"></div>}
      </HeaderSection>
    </>
  );
};

export default Header4;

const HeaderSection = styled.div`
  .others {
    width: 100%;
    display: inline-block;
    margin: 0 0 80px 0;
  }

  header.HE_4#bg_black {
    background: ${styles?.bg80};
  }
  header.HE_4.Inner_Pages_All {
    background: ${styles?.bg80};
  }
  header.HE_4 {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1000;
    background: transparent;
    padding: 16px 0;
    width: 100%;
    display: inline-block;
  }
  header.HE_4 .HE_4_Align {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
  }
  header.HE_4 .HE_4_Align .HE_4_Left {
    width: fit-content;
    display: inline-block;
  }
  #bg_black {
    position: fixed;
    z-index: 1000;
  }
  header.HE_4 .HE_4_Align .HE_4_Left img {
    height: 55px;
    filter: brightness(0) invert(1);
  }

  header.HE_4 .HE_4_Align .HE_4_Right {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
  }
  header.HE_4 .HE_4_Align .HE_4_Right button {
    padding: 0 !important;
    background: none;
    color: #fff;
    border: 0;
    font-size: 16px !important;
    text-align: center;
    width: fit-content !important;
  }
  header.HE_4 .HE_4_Align .HE_4_Right button span {
    font-size: 25px;
    color: #fff;
    padding: 0%;
    width: fit-content;
  }

  header.HE_4 .HE_4_Align .HE_4_Right .HE_4_SearchBar {
    position: relative;
  }

  header.HE_4 .HE_4_Align .HE_4_Right .HE_4_SearchBar form {
    position: absolute;
    width: 250px;
    right: 0;
    top: 45px;
    box-shadow: 0 0 15px rgb(0 0 0 / 40%);
  }
  header.HE_4 .HE_4_Align .HE_4_Right .HE_4_SearchBar form button span {
    color: ${styles?.color};
    padding: 0 10px;
    font-size: ${styles?.p};
    font-family: ${styles?.bold};
  }
  header.HE_4 .HE_4_Align .HE_4_Right .HE_4_SearchBar form button {
    border-left: 1px solid ${styles?.gray};
  }
  header.HE_4
    .HE_4_Align
    .HE_4_Right
    .HE_4_SearchBar
    form
    button
    span
    .anticon
    svg {
    font-family: ${styles?.bold};
  }

  header.HE_4 .HE_4_Align .HE_4_Right .HE_4_Right_Profile button,
  header.HE_4 .HE_4_Align .HE_4_Right .HE_4_Right_Profile button span {
    font-size: 15px !important;
  }
  header.HE_4 .HE_4_Align .HE_4_Right .HE_4_Right_CartBtn {
    margin: 0 10px 0 0;
  }
  header.HE_4 .HE_4_Align .HE_4_Right .ant-badge-count {
    height: 15px;
    width: 15px;
    padding: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: auto;
    font-size: 11px;
    font-weight: 600;
  }
  header.HE_4 .HE_4_Align .HE_4_Right .ant-scroll-number {
    position: absolute;
    top: 6px;
  }
  .HE_4_Menu .ant-menu-item,
  .ant-menu-submenu-title {
    padding: 0 !important;
  }
  .HE_4_Menu .ant-drawer-body {
    padding: 20px 20px;
  }
  .HE_4_Menu .ant-menu {
    border: 0 !important;
  }
  .HE_4_Menu .ant-menu-item-only-child,
  .HE_4_Menu .ant-menu-title-content,
  .HE_4_Menu .ant-menu-sub.ant-menu-inline {
    background: none !important;
  }

  header.HE_4 .HE_4_Align .HE_4_Right button {
    font-size: 30px !important;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  header.HE_4
    .HE_4_Align
    .HE_4_Right
    .ant-btn.ant-btn-text.ant-dropdown-trigger.ant-dropdown-link {
    width: fit-content !important;
    display: flex;
    align-items: center;
  }
  header.HE_4 .HE_4_Align .HE_4_Right .HE_4_Right_Profile {
    display: flex;
    align-items: center;
  }
  header.HE_4 .HE_4_Align .HE_4_Right .HE_4_Navbar button {
    font-size: 30px !important;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 992px) {
    header.HE_4 .HE_4_Align .HE_4_Right button {
      margin: 0 !important;
      display: flex;
      align-items: center;
      gap: 0px;
    }
  }

  @media screen and (max-width: 580px) {
    header.HE_4 .HE_4_Align .HE_4_Left img {
      height: 40px;
    }
    header.HE_4 .HE_4_Align .HE_4_Right button {
      font-size: 30px !important;
    }

    header.HE_4 .HE_4_Align .HE_4_Right {
      gap: 10px;
    }
  }

  @media screen and (max-width: 480px) {
    header.HE_4 .HE_4_Align .HE_4_Left img {
      height: 32px;
    }
    header.HE_4 .HE_4_Align .HE_4_Right .HE_4_Right_Profile button,
    header.HE_4 .HE_4_Align .HE_4_Right .HE_4_Right_Profile button span {
      font-size: 12px !important;
      text-transform: uppercase;
    }
    header.HE_4 .HE_4_Align .HE_4_Right button span {
      font-size: 20px;
    }
    header.HE_4 .HE_4_Align .HE_4_Right button {
      font-size: 25px !important;
    }
    header.HE_4 .HE_4_Align .HE_4_Right {
      grid-gap: 8px;
      gap: 8px;
    }
    header.HE_4 .HE_4_Align .HE_4_Right .HE_4_Right_CartBtn {
      margin: 0 4px 0 0;
    }
    section.HE_4_FullPageBanner .bannerListDot {
      width: 100%;
    }
  }

  @media screen and (max-width: 380px) {
    header.HE_4 .HE_4_Align .HE_4_Left img {
      height: 25px;
    }
    header.HE_4 .HE_4_Align .HE_4_Right {
      gap: 6px;
    }
    header.HE_4 .HE_4_Align .HE_4_Right .HE_4_Right_Profile button,
    header.HE_4 .HE_4_Align .HE_4_Right .HE_4_Right_Profile button span {
      font-size: 11px !important;
    }
    header.HE_4 .HE_4_Align .HE_4_Right button span {
      font-size: 18px;
    }
    header.HE_4 .HE_4_Align .HE_4_Right button {
      font-size: 21px !important;
    }
  }
`;
