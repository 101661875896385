import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {base_url} from "../../config";
import {Link, useNavigate} from "react-router-dom";
import {Menu} from "antd";
import logo from "../../Assets/Images/logo.png";
import hsearch from "../../Assets/Images/icons/search.png";
import hprofile from "../../Assets/Images/icons/profile.png";
import hcart from "../../Assets/Images/icons/cart.png";
import blog from "../../Assets/Images/blog.png";
import contact from "../../Assets/Images/contact.png";
import about from "../../Assets/Images/about.png";
import home from "../../Assets/Images/home.png";
import {AutoComplete, Input, Dropdown, Space, Badge} from "antd";
import {cartStart, cartSuccess, cartFailure} from "../../Redux/slices/cart";
import {
  LogoutOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  LoginOutlined,
  HomeOutlined,
  ShopOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import API from "../../ApiService/ApiService";
import {useSelector, useDispatch} from "react-redux";
import {logoutUser} from "../../Redux/slices/user";

const {SubMenu} = Menu;

let token = localStorage.getItem("token");
let login = localStorage.getItem("login");
let userData = localStorage.getItem("user");

// console.log("login", login);

const getRandomInt = (max, min = 0) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
const searchResult = (query) =>
  new Array(getRandomInt(5))
    .join(".")
    .split(".")
    .map((_, idx) => {
      const category = `${query}${idx}`;
      return {
        value: category,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              Found {query} on{" "}
              <a
                href={`https://s.taobao.com/search?q=${query}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {category}
              </a>
            </span>
            <span>{getRandomInt(200, 100)} results</span>
          </div>
        ),
      };
    });

const Header1 = ({socialmedia}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state?.cart?.cart);
  const [scroll, setScroll] = useState(0);
  const [Cart ,setCart] = useState([]);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState(false);
  const company = useSelector((state) => state?.company?.company?.data[0]);
  const api = new API();



  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 1);
    });
    getAllCart();
  }, []);

  const getAllCart = () => {
    dispatch(cartStart);
    api.getcart().then((res) => {
      if (res.status === 200) {
        dispatch(cartSuccess(res?.data?.data?.cart_item));
        setCart(res?.data?.data?.cart_item);
      } else {
        dispatch(cartFailure);
      }
    });
  };

  // console.log("cart", cart)

  const toogleSearch = () => {
    setSearch(!search);
  };

  const logout = () => {
    //data of the user will be deleted from local storage after logout and also set null value to userSlice
    dispatch(logoutUser(null));
    // dispatch(emptyRemoveAddress(null));
    // dispatch(emptyUpdateAddress(null));
    // dispatch(emptyUserAddress(null));
    localStorage.setItem("user", "");
    localStorage.setItem("token", "");
    localStorage.setItem("login", false);
    navigate("/");
    window.location.reload();
  };

  const handleSearch = (value) => {
    setOptions(value ? searchResult(value) : []);
  };
  const onSelect = (value) => {
    // console.log("onSelect", value);
  };

  const items = [
    {
      label: "My Profile",
      key: "0",
    },
    {
      label: "My Order",
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: "Logout",
      key: "3",
    },
  ];

  const menuItems = [
    {
      label: "Privacy Policy",
      key: "Privacy Policy",
      link: "/privacy-policy",
    },
    {
      label: "Delivery Policy",
      key: "Delivery Policy",
      link: "/delivery-policy",
    },
    {
      label: "Terms and Condition",
      key: "Terms and Condition",
      link: "/terms",
    },
    {
      label: "Refund Policy",
      key: "Refund Policy",
      link: "/refund-policy",
    },
    {
      label: "Return Policy",
      key: "Return Policy",
      link: "/return-policy",
    },
    {
      label: "Cancellation Policy",
      key: "Cancellation Policy",
      link: "/cancellation-policy",
    },
  ];

  return (
    <>
      <HeaderSection>
        <div className="header_section">
          <div id={scroll ? "scroll_head" : ""}>
            <div className="wrapper">
              <div className="header_top_align">
                <div className="header_top_left">
                  <ul>
                    {socialmedia?.map((e, i) => {
                      return (
                        <li key={i}>
                          <a
                            href={e?.social_media_link}
                            title={e?.social_media_nam}
                            target="_blank"
                          >
                            <img
                              src={base_url + e?.social_media_icon}
                              alt={e?.social_media_nam}
                            />
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="header_top_center">
                  <Link to="/">
                    <img
                      src={company?.logo ? base_url + company?.logo : logo}
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="header_top_right">
                  <div className="shop_cart">
                    {login && token && userData ? (
                      <ul>
                        <li>
                          <Link to="/my-profile" className="link">
                            <div className="accounts_section">
                              <UserOutlined />
                              Profile
                            </div>
                          </Link>
                        </li>

                        <li>
                          <Link to="/cart" className="link">
                            <div className="cart_container">
                              <Badge count={cart?.length} showZero size="small">
                                <ShoppingCartOutlined className="cart_icon" />
                              </Badge>
                              Cart
                            </div>
                          </Link>
                        </li>
                        <li>
                          <div className="logout_container" onClick={logout}>
                            <LogoutOutlined />
                            <span>Logout</span>
                          </div>
                        </li>
                      </ul>
                    ) : (
                      <ul className="login_section">
                        <li>
                          <Link className="login_link" to="/login">
                          <UserOutlined style={{ marginRight: "5px" }} /> Sign In
                          </Link>
                        </li>
                        <li>
                          <Link className="login_link" to="/register">
                          <LoginOutlined style={{ marginRight: "5px" }}  /> Sign Up
                          </Link>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="header_bottom_align">
              <div className="header_bottom_center">
                <Menu mode="horizontal" className="menu">
                  <Menu.Item key="home">
                    <Link className="link" to="/">
                      Home
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="about">
                    <Link className="link" to="/about">
                      About Us
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="product">
                    <Link className="link" to="/products">
                      Products
                    </Link>
                  </Menu.Item>
                  
                  <Menu.Item key="contact">
                    <Link className="link" to="/contact">
                      Contact Us
                    </Link>
                  </Menu.Item>
                </Menu>
              </div>
            </div>
          </div>
          <div className="Bottom_Section">
            <div className="Menu_Contents">
              
              <img src={home} className="blog_img"  onClick={() => navigate("/")}/>
              <img src={contact} className="blog_img" onClick={() => navigate("/contact")} />
              <img src={about} className="blog_img" onClick={() => navigate("/about")} />
              <img src={blog} className="blog_img" onClick={() => navigate("/blog")} />
              {/* <HomeOutlined />
              <ShopOutlined />
              <ShopOutlined />
              <ContactsOutlined /> */}
            </div>
          </div>
          <div className="mob_menu">
            <Menu mode="vertical" className="menu">
              <Menu.Item key="home">
                <Link className="link" to="/">
                  Home
                </Link>
              </Menu.Item>
              <Menu.Item key="about">
                <Link className="link" to="/about">
                  About Us
                </Link>
              </Menu.Item>
              <Menu.Item key="product">
                <Link className="link" to="/products">
                  Products
                </Link>
              </Menu.Item>
              <Menu.Item key="blog">
                <Link
                  className="link"
                  target="_blank"
                  to="https://ecdigi.com/blog.php"
                >
                  Blogs
                </Link>
              </Menu.Item>
              <Menu.Item key="contact">
                <Link className="link" to="/contact">
                  Contact Us
                </Link>
              </Menu.Item>
            </Menu>
          </div>
        </div>
      </HeaderSection>
    </>
  );
};

export default Header1;

const HeaderSection = styled.section`
  .link {
    text-decoration: none !important;
    color: black;
  }

  .link:hover {
    color: #ed8f07;
  }

  .accounts_section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .link:hover .cart_icon {
    color: #ed8f07;
  }

  .link:hover .cart_container {
    color: #ed8f07;
  }
  .login_link {
    text-decoration: none;
    color: black;
    transition: color 0.5s;
  }

  .login_link:hover {
    color: #ed8f07;
  }

  .logout_container:hover {
    color: #ed8f07;
  }

  .cart_icon {
    font-size: 20px;
  }



  .logout_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  .cart_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #scroll_head {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    background: #fff;
    padding: 15px 0;
    box-shadow: 0 0 12px rgb(0 0 0 / 10%);
  }

  display: inline-block;
  width: 100%;
  position: relative;
  .header_section {
    display: inline-block;
    width: 100%;
    position: relative;
    background: "";
    padding: 20px 0 10px 0;
  }
  .header_top_align {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    position: relative;
    height: 55px;
  }
  .header_top_align .header_top_left {
    display: inline-block;
    position: relative;
    width: fit-content;

    ul {
      display: flex;
      align-items: center;
      gap: 17px;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
    }
    ul img {
      height: 32px;
    }
  }
  .header_top_align .header_top_center {
    width: fit-content;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;

    img {
      position: relative;
      height: 55px;
    }
  }
  .header_top_align .header_top_right {
    width: fit-content;
    display: inline-block;

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      align-items: center;
      gap: 17px;
      flex-wrap: wrap;
    }
    img {
      height: 25px;
      cursor: pointer;
    }
    a {
      display: flex;
    }
  }

  .search_comp {
    display: inline-block;
    position: relative;
    z-index: 100;
  }
  .search_comp .ant-select {
    position: absolute;
    top: 130%;
    right: 0;
  }

  @media screen and (max-width: 480px) {
    form {
      right: -100px;
    }
  }

  .mob_view_only {
    display: none;
  }
  .mob_menu {
    display: none;
  }

  .header_bottom_align {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0 0 0;
  }

  .header_bottom_center {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    ul {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mobile_menu_icon {
    display: none;
  }
  .Bottom_Section {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    .header_section {
      padding: 15px 0;
    }
    .header_bottom_align {
      display: none;
    }
    .mob_view_only {
      display: block;

      button {
        display: flex;
        align-items: center;
        background: transparent;
      }
    }
  }

  @media screen and (max-width: 580px) {
    .header_top_align {
      height: auto;
    }
    .header_top_align .header_top_left {
      display: none;
    }
    .header_top_align .header_top_center {
      position: relative;
      top: 0;
      left: 0;
      transform: inherit;

      img {
        height: 40px;
      }
    }
    .header_top_align .header_top_right img {
      height: 20px;
    }
    .mob_view_only button {
      border: 0;
      padding: 0;
    }

    .Bottom_Section {
      display: block;
      background: #F3EEEA;
      padding: 15px 0;
      overflow: hidden;
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 9999;
      margin-top: 25px;
    }

    .Bottom_Section .Menu_Contents {
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 25px;
      font-weight: 500;

      .blog_img {
        width: 28px;
      }

      .blog_img .is_active {
        color: red;
      }

    
    }
  }
`;
