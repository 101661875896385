
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  message,
  Badge,
  Menu,
  Button,
  Avatar,
  Dropdown,
  Drawer,
  Tooltip,
  Space,
  Tabs,
 
} from "antd";
import { base_url } from '../../config'
import logo from '../../Assets/Images/logo.png'   
import { LoginOutlined } from '@ant-design/icons';
import { AiOutlineMenu } from "react-icons/ai";
import Login from "../../Ecommerce/Login";
 import SearchBoxComponent from "../../Ecommerce/SearchBox";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BsSearch } from "react-icons/bs";
import { BsFilterLeft } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
//  import MobileMenu from "../../Ecommerce/"
import {
  SearchOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  MobileOutlined,
  LogoutOutlined,
  DownOutlined,
  ProfileOutlined,
  MobileFilled,
  MailFilled,
  PhoneFilled,
} from "@ant-design/icons";
import API from "../../ApiService/ApiService";
import { connect, useSelector, useDispatch } from "react-redux";
// import { getCartList } from "../../Store/cartTypes";
import styled from "styled-components";
import { styles } from "../../ApiService/Data";



 const  Header2=(props)=> {
  const dispatch = useDispatch();
  const history = useNavigate();
const loginTrue = useSelector((state) => state.user?.currentUser?.token);
 const cartList = useSelector((state) => state?.cart?.products);
  const [search, setSearch] = useState(true);
  // const [category, setCategory] = useState([]);
  const [productcategory, setProductcategory] = useState([]);
  const [visible, setVisible] = useState(false);
  const api = new API();
  const company = useSelector((state) => state?.company?.company?.data[0]);
  const category = useSelector((state) => state?.CetgoryMenu?.CetgoryMenu);
 
  const socialMediaLink = useSelector(
    (state) => state.company?.socialMediaLinks
  );

  // useEffect(() => {
  //   props.getCartList();
  //   api.allCategory().then((res)=>{
  //     if(res.status===200) {
  //       setCategory(res.data.filter(e=>
  //         e.parent==null
  //         ))
  //     }
  //   }).catch((err)=>{})
  // }, []);

  const toogleSearch = () => {
    setSearch(!search);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const logout = (values) => {
    api.logout(dispatch);
    history('/')
  };

  const menu = (
    <div className="menu__cart">
      <Menu onClick={onClose} mode="horizontal">
        <Tooltip placement="bottom" title="cart">
          <Menu.Item key="1_cart">
            <Link to="/cart">
              <Badge count={cartList?.length} offset={[-40, 0]}>
                <Button type="text">
                  <ShoppingCartOutlined />
                </Button>
              </Badge>
            </Link>
          </Menu.Item>
        </Tooltip>
        <Tooltip placement="bottom" title="Profile">
          <Menu.Item key="1_profile">
            <Link to="/my-profile">
              <ProfileOutlined />
            </Link>
          </Menu.Item>
        </Tooltip>
        <Tooltip placement="bottom" title="Order">
          <Menu.Item key="1_order">
            <Link to="/my-order">
              <ShoppingOutlined />
            </Link>
          </Menu.Item>
        </Tooltip>
        <Tooltip placement="bottom" title="Signout">
          <Menu.Item key="1_signout">
            <a onClick={logout}>
              <LogoutOutlined />
            </a>
          </Menu.Item>
        </Tooltip>
      </Menu>
    </div>
  );

  return (
    <React.Fragment>
      <HeaderSection>

    <header className="Temp2_Header">
      <div className="Temp2_Header_Align">
        <div className="Temp2_Header_Menu1">
          <div className="menu">
            <div className="Wrapper">
              <div className="Temp2_Header_Menu1_Align">
                <div className="Temp2_Header_Menu1_Left">
                  {company?.mobile_no && (
                    <div className="mob__phone">
                      <PhoneFilled /> CALL US:
                      <a href={"tel:+91" + company?.mobile_no}>
                        {" "}
                        {company?.mobile_no}
                      </a>
                    </div>
                  )}
                </div>
                <div className="Temp2_Header_Menu1_Center">
                  <Link to="/">
                    <img className="logo" src={company?.logo ? base_url + company?.logo : logo} />
                  </Link>
                </div>
                <div className="Temp2_Header_Menu1_Right">
                  {!loginTrue && loginTrue!=="" ? <Link to="/login"><Button type="text"><LoginOutlined /> Login</Button></Link> : <div>{menu}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Temp2_Header_Menu2">
          <div className="Wrapper">
            <div className="Temp2_Header_Menu2_Align">
              <div className="Temp2_Header_Menu2_Left">
                <div className="search__desk">
                  {search && <SearchBoxComponent />}
                </div>
              </div>
              <div className="Temp2_Header_Menu2_Right">
                <Navbar expand="sm" className="nav__bar">
                  <Nav className="me-auto1">
                    <Link to="/">Home</Link>
                    <Link to="/about">About Us</Link>
                    <Link to="/Contact">Contact Us</Link>
                    <NavDropdown title="Product" className="down">
                      {category?.map((e, i) => {
                        // console.log("eeeeeeeeee",e)
                        return (
                          <NavDropdown.Item
                            className="down__item"
                            key={`menuPdttemp2_${i}`}
                          >
                            <Link to={`${e?.key}`}>
                              {e?.label}
                            </Link>
                          </NavDropdown.Item>
                        );
                      })}
                    </NavDropdown>
                    <Link to="/enquiry">Enquiry</Link>
                  </Nav>
                </Navbar>
              </div>
            </div>
          </div>
        </div>
        <div className="Temp2_Header_Menu3">
          <nav className="Temp2_Header_Menu3_Mobile">
            <div className="Temp2_Header_Menu3_Align">
              <div className="Wrapper">
                <div className="Temp2_Header_Menu3_Top">
                  <div className="Temp2_Header_Menu3_Top_Left">
                    <Link to="/">
                      <img className="logo" src={company?.logo ? base_url + company?.logo : logo} />
                    </Link>
                  </div>
                  <div className="Temp2_Header_Menu3_Top_Right">
                  {/* <MobileMenu /> */}
                  </div>
                  <div className="Temp2_Header_Menu3_Bottom">
                    <div className="Temp2_Header_Menu3_Bottom_Align">
                      <div className="Temp2_Header_Menu3_Bottom_Left">
                        <div className="search">
                          {search && <SearchBoxComponent />}
                        </div>
                      </div>
                      <div className="Temp2_Header_Menu3_Bottom_Right">
                        <div className="open_menu">
                          {!loginTrue && loginTrue!=="" ? (
                            <Link to="/login"><Button type="text"><LoginOutlined /> Login</Button></Link>
                          ) : (
                            <div>{menu}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
    </HeaderSection>
    </React.Fragment>
  );
}
export default Header2

const HeaderSection = styled.div`
position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #ffff;
    box-shadow: 0 0 8px rgb(0 0 0 / 15%);
.Temp2_Header {
  display: inline-block;
  width: 100%;
  position: sticky;
  top:0;
  z-index: 1000;
  background: #fff;

  .ant-menu-horizontal {
    line-height: 46px;
    border: 0;
    border-bottom: 0px solid #f0f0f0 !important;
    box-shadow: none;
    background: transparent !important;
}
}
.Temp2_Header .Temp2_Header_Align {
  display: inline-block;
  position: relative;
  width: 100%;
  
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 {
  display: inline-block;
  width: 100%;
  background: ${styles?.light};
  padding: 15px 0;

}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 {
  display: inline-block;
  width: 100%;
  position: relative;
  background: ${styles?.colorapi};
padding-left: 10px;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 {
  display: none;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;
  position: relative;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Left {
  display: inline-block;
  width: fit-content;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Center {
  display: inline-block;
  width: fit-content;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right {
  display: inline-block;
  width: fit-content;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right div {
  display: inline-block;
  width: fit-content;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right .menu__cart {
  width: fit-content;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Left .mob__phone {
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${styles?.color};
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Left .mob__phone a {
  color: ${styles?.color};
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Center img {
  padding: 0%;
  height: 45px;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right .menu__cart ul li {
  padding: 7px 10px !important;
  line-height: initial;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right .menu__cart ul li:hover {
  background:${styles?.colorapi};
  
  color: ${styles?.white} !important;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right .menu__cart ul {
  width: fit-content;
    gap: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right .menu__cart ul::before, .Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right .menu__cart ul::after {
content: inherit;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right .menu__cart ul .ant-menu-title-content a::before {
  content: inherit;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right button {
  padding: 0 !important;
    line-height: 0;
    height: 15px;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right .menu__cart ul .ant-menu-title-content {
  line-height: initial;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 .Temp2_Header_Menu1_Align .Temp2_Header_Menu1_Right .menu__cart ul .ant-menu-title-content a {
  height: auto;
  width: auto;
}

.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  position: relative;
  padding: 10px 0;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align .Temp2_Header_Menu2_Left {
  width: 28%;
  display: inline-block;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align .Temp2_Header_Menu2_Right {
  width: fit-content;
  display: inline-block;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align .Temp2_Header_Menu2_Left .search__desk {
  display: flex;
  width: 100%;
  position: relative;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align .Temp2_Header_Menu2_Left .search__desk form {
  width: 100%;
  position: relative;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align .Temp2_Header_Menu2_Left .search__desk form button {
  background: ${styles?.white};
  width: 55px;
  height: 36px;
  color: ${styles?.color};
  font-family: ${styles?.bold};
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align .Temp2_Header_Menu2_Left .search__desk form input {
  padding: 2px 15px;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align .Temp2_Header_Menu2_Right .nav__bar {
  padding: 0;

}


.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align .Temp2_Header_Menu2_Right .nav__bar a.dropdown-toggle.nav-link {
  padding: 5px 12px;
  color: ${styles?.white} !important;
  font-size: ${styles?.p};
  font-family: ${styles?.regular};
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align .Temp2_Header_Menu2_Right .nav__bar a {
  padding: 5px 12px;
  color: ${styles?.white};
  font-size: ${styles?.p};
  font-family: ${styles?.regular};
  text-decoration: none;
}

.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align .Temp2_Header_Menu2_Right .nav__bar a.down__item.dropdown-item a {
  color: ${styles?.colorapi};
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 .Temp2_Header_Menu2_Align .Temp2_Header_Menu2_Right .nav__bar a.down__item.dropdown-item:hover {
  background: ${styles?.light};
}

.Temp2_Header .ant-badge-count {
  margin-top: 0px !important;
    right: -5px !important;
    height: 15px !important;
    min-width: 15px !important;
    width: 15px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 10px !important;
    font-weight: 700 !important;
    line-height: 17px !important;

}


@media screen and (max-width:1200px) {

.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu1 {
  display: none;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu2 {
  display: none;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 {
  display: block;
  background: ${styles.light};
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile {
  display: inline-block;
  width: 100%;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align {
  display: inline-block;
  width: 100%;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px 0;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Top_Left {
  display: inline-block;
  width: fit-content;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Top_Left img {
  padding: 0;
  height: 45px;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Top_Right {
  width: fit-content;
  display: inline-block;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Top_Right button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom {
  width: 100%;
  display: inline-block;
  padding: 15px 0 0 0;
}

.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom .Temp2_Header_Menu3_Bottom_Align {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom .Temp2_Header_Menu3_Bottom_Align .Temp2_Header_Menu3_Bottom_Left {
  width: 35%;
  display: inline-block;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom .Temp2_Header_Menu3_Bottom_Align .Temp2_Header_Menu3_Bottom_Right {
  width: fit-content;
  display: inline-block;
 
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom .Temp2_Header_Menu3_Bottom_Align .Temp2_Header_Menu3_Bottom_Left form {
  width: 100%;
  display: inline-block;
  position: relative;
  
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom .Temp2_Header_Menu3_Bottom_Align .Temp2_Header_Menu3_Bottom_Left input {
  padding: 3px 15px;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom .Temp2_Header_Menu3_Bottom_Align .Temp2_Header_Menu3_Bottom_Left button {
  width: 55px;
  color: ${styles?.white};
  font-family: ${styles?.bold};
  background: ${styles?.colorapi};
  border: 1px solid ${styles?.colorapi};
 height: 37px;

}









}


@media screen and (max-width:768px) {
  .Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom .ant-menu-horizontal > .ant-menu-item, .Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom .ant-menu-horizontal > .ant-menu-submenu {
    padding: 0;
  }
  .Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom button {
    padding: 0;
  }

  .Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom .Temp2_Header_Menu3_Bottom_Align .Temp2_Header_Menu3_Bottom_Left {
    width: 45%;
  }
  .Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom .Temp2_Header_Menu3_Bottom_Align .Temp2_Header_Menu3_Bottom_Left input {
    padding: 5px 10px;
  }
  .Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom .Temp2_Header_Menu3_Bottom_Align .Temp2_Header_Menu3_Bottom_Left button {
    width: 33px;
    height: 42px;
}


.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom ul.ant-menu-horizontal {
  display: flex;
  align-items: center;
  gap: 13px;
}
.Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom ul.ant-menu-horizontal::after, .Temp2_Header .Temp2_Header_Align .Temp2_Header_Menu3 .Temp2_Header_Menu3_Mobile .Temp2_Header_Menu3_Align .Temp2_Header_Menu3_Top .Temp2_Header_Menu3_Bottom ul.ant-menu-horizontal::before {
  content: inherit;
}

}

















`;