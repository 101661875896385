import { createSlice } from "@reduxjs/toolkit";

const homeSlice = createSlice({
  name:'home',
  initialState:{
    home:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    homeGetAllStart:(state)=>{
      state.isFetching = true;
    },
    homeGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.home = action.payload;
    },
    homeGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {homeGetAllStart,homeGetAllSuccess,homeGetAllFail} = homeSlice?.actions;
export default homeSlice.reducer;



