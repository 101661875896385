import React, { useState, useEffect, createContext, useReducer, useCallback } from "react";
import styled from "styled-components";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cartStart, cartFailure, cartSuccess } from "../../Redux/slices/cart";
import {
  HomeOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UpOutlined,
  DownOutlined,
  HeartOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import Image1 from "../../Assets/Images/Thumb-1.jpg";
import Image2 from "../../Assets/Images/Thumb-2.png";
import ProductImage from "../../Assets/Images/pro-3.png";
import {
  Breadcrumb,
  Divider,
  Row,
  Col,
  Tabs,
  Tooltip,
  Alert,
  Modal,
  Button,
  Image,
  List,
  Rate,
  Form,
  Input,
  message,
  Card,
  Radio,
  Skeleton,
  Tag,
} from "antd";
import { Comment } from "@ant-design/compatible";
import Default from "../../Assets/Images/default.png";
import API from "../../ApiService/ApiService";
import moment from "moment";
import crud_service from "../../ApiService/CrudService";
import { theme, font } from "../../ApiService/Theme";
import App from "../../App";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import VideoPlay from "../../Assets/Images/video.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, Mousewheel } from "swiper";
import "swiper/swiper-bundle.css";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import styles from "../../ApiService/Data";
import {
  userGetAllStart,
  userGetAllSuccess,
  userGetAllFail,
} from "../../Redux/slices/user";
import {
  productGetAllStart,
  productGetAllSuccess,
  productGetAllFail,
} from "../../Redux/slices/product";
import Countdown from "react-countdown";
import pro1 from "../../Assets/Images/pro-1.png";
import pro2 from "../../Assets/Images/pro-2.png";
import pro3 from "../../Assets/Images/pro-4.png";
import defaultPng from "../../Assets/Images/default.png";
import Carousel from "react-grid-carousel";

const crud = new crud_service();

const { TextArea } = Input;
const ProductContext = createContext();
SwiperCore.use([Navigation, Thumbs, Mousewheel]);

let specificationSet;
let user = "";

const HomeProduct1 = ({ product }) => {
  // console.log("HomeProduct1--->", product);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const catName = params.get("catName");
  const [pageView, setPageView] = useState("grid");
  const catId = params.get("catId");
  const product_name = params.get("product_name");
  const loginTrue = localStorage.getItem("login");
  const api = new API();
  const getUser = localStorage.getItem("user");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const params = useParams();

  const [products1, setProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [singleProducts, setSingleProducts] = useState([]);
  const [totalRelatedProducts, setTotalRelatedProducts] = useState([]);
  const [singleProductSpecData, setSingleProductSpecData] = useState([]);
  const [singleProductreviews, setSingleProductreviews] = useState([]);
  const [productsDiscountDetails, setProductsDiscountDetails] = useState([]);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isSaving, setSaving] = useState(false);


  const [loginForm] = Form.useForm();

  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

  // console.log("user", user);

  //getting categoty from redux
  const category = useSelector((state) => state?.CetgoryMenu?.CetgoryMenu);

  const tax = useSelector((state) => state?.tax?.data);

  const products = useSelector(
    (state) => state?.product?.product?.data[0].data
  );
  // console.log("products", products)

  const totalProducts = useSelector(
    (state) => state?.product?.product?.data[0].totalCount[0]?.total
  );

  const CarouselImages = product?.product_slider?.map((item) => item?.images);
  // console.log("product?.product_slider", product?.product_slider);

  // console.log("totalProducts", totalProducts);
  // console.log("products", products);
  // console.log("category", category);
  // console.log("tax", tax);

  const handleRightArrowClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % singleProductSpecData[0]?.product_images?.length);
  };

  const handleLeftArrowClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + singleProductSpecData[0]?.product_images?.length) % singleProductSpecData[0]?.product_images?.length);
  };
// console.log("currentIndex", currentIndex);
  const product1 = singleProductSpecData[0]?.product_images?.map((item) => item)[currentIndex];
  // console.log("product1", product1);
  // console.log("singleProductSpecData----?", singleProductSpecData[0]?.product_images?.length);

  const getSingleProduct = () => {
    api.getSingleProduct(product?.product_main?._id).then((res) => {
      // console.log("res??", res?.data?.data)
      setSingleProducts(res?.data?.data?.product);
      setTotalRelatedProducts(res?.data?.data?.relatedProducts);
      setSingleProductSpecData(res?.data?.data?.productspec);
      setSingleProductreviews(res?.data?.data?.reviews);
    });
  };

  useEffect(() => {
    getSingleProduct();
    // getRating();
    getProductDiscountDetails();
  }, []);  

  const getProductDiscountDetails = () => {
    api.getProductDiscountDetails(product?.product_main?._id).then((res) => {
      //  console.log( res)
      if (res?.status == 200) {
        setProductsDiscountDetails(res?.data?.data?.discounts);
      } else {
        console.log("ProductDiscountDetails not available");
      }
    });
  };

  // console.log("productsDiscountDetails", productsDiscountDetails);

  // console.log("singleProducts", singleProducts);
  // console.log("singleProductSpecData", singleProductSpecData);
  // console.log("singleProductreviews", singleProductreviews); 
  // console.log("products1", singleProductreviews?.find((item) => item?.rating >=4)?.rating); 

  const addToCart = () => {
    setAddToCartLoading(true);

    if (!loginTrue) {
      setAddToCartLoading(false);

      setOpen(true);
    } else {
      const combinedData = {
        buyer_id: user?._id,
        qty: 1,
        product_id: singleProductSpecData[0]?.product_id,
        product_spec: singleProductSpecData[0]?._id,
      };
      // console.log("combinedDataCart", combinedData);
      api.addCart(combinedData).then((res) => {
        if (res?.status == 200) {
          dispatch(cartStart);
          api.getcart().then((res) => {
            // console.log("res--->", res);
            dispatch(cartSuccess(res?.data));
          });
          message.success(res?.data?.message);
          setAddToCartLoading(false);
          window.location.reload();
        } else {
          // console.log("res--->", res?.response?.data?.message);
          dispatch(cartFailure);
          message.error(res?.response?.data?.message);
          setAddToCartLoading(false);
        }
      });
    }
  };

  const login = async (values) => {
    let user;
    try {
      user = await api.create(
        dispatch,
        [userGetAllStart, userGetAllSuccess, userGetAllFail],
        "login",
        values
      );
    } catch (error) {
      message.error("Something went wrong");
      console.error("Login error:", error);
    }
    if (user?.status === 200) {
      // console.log("res--------->", user);
      message.success("Login Successful");
      localStorage.setItem("token", user?.data?.token);
      localStorage.setItem("user", JSON.stringify(user?.data?.data));
      localStorage.setItem("login", true);
      localStorage.setItem("buyer_name", values?.buyer_number);
      localStorage.setItem("buyer_pasword", values?.buyer_password);
      window.location.reload();
    } else {
      message.error(user?.response?.data?.message);
    }
  };

  const handleOk = () => {
    loginForm.resetFields();
    setOpen(false);
  };
  const handleCancel = () => {
    loginForm.resetFields();
    setOpen(false);
  };

  const ThumbImages = [
    {
      image: Image1,
    },
    {
      image: Image1,
    },
    {
      image: Image1,
    },
    {
      image: Image2,
    },
  ];

  const productImages = [
    {
      image: pro1,
    },
    {
      image: pro2,
    },
    {
      image: pro3,
    },
    {
      image: pro1,
    },
    {
      image: pro2,
    },
    {
      image: pro3,
    },
    {
      image: pro1,
    },
    {
      image: pro2,
    },
    {
      image: pro3,
    },
  ];

  // const targetDate = new Date("2024-12-31T23:59:59");
  // console.log("productsDiscountDetails", productsDiscountDetails[0]?.discount_valid_to);
  const targetDate =  productsDiscountDetails[0]?.discount_valid_to ? new Date(productsDiscountDetails[0]?.discount_valid_to) : new Date();

  const CountownAlign = styled.div`
    .CountDown {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }

    .days {
      line-height: 0;
    }
    .day_Style {
      padding: 3px 10px;
      color: #141718;
      background: #f3f5f7;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Flama Trial;
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: 38px; /* 111.765% */
      letter-spacing: -0.6px;
    }

    .Text {
      color: var(--neutral-04100, #6c7275);
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Flama Trial;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px;
    }

    @media (max-width: 767px) {
      .day_Style {
        font-size: 24px;
        line-height: 28px;
      }

      .Text {
        font-size: 12px;
        line-height: 8px;
      }

      .CountDown .days .day_Style {
        display: flex;
      }
    }
  `;

  const renderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
      return <span>Flash Sale Ended!</span>;
    } else {
      return (
        <CountownAlign>
          <div className="CountDown">
            <div className="days">
              <p className="day_Style">{days}</p>
              <p className="Text">days</p>
            </div>
            <div className="days">
              <p className="day_Style">{hours}</p>
              <p className="Text">hours</p>
            </div>
            <div className="days">
              <p className="day_Style">{minutes}</p>
              <p className="Text">minutes</p>
            </div>
            <div className="days">
              <p className="day_Style">{seconds}</p>
              <p className="Text">seconds</p>
            </div>
          </div>
        </CountownAlign>
      );
    }
  };

  return (
    <HomeProductAlign>
      <div className="home_wrapper">
        <div className="Product_Main">
          <div className="Left_Section">
            <div className="Thumb_Images">
              {singleProductSpecData[0]?.product_images?.slice(0, 4)?.map((item, i) => {
                return (
                  <div key={i} className="images">
                    <img src={api.rootUrl1 + item?.image_path} alt="Product" />
                  </div>
                );
              })}
            </div>
            <br />
            {targetDate ? (<div className="Time">
              <p>Offer expires in:</p>
              <Countdown date={targetDate} renderer={renderer} />
            </div>) : null}
          </div>
          {(<div className="Middle_Section">
            <div className="Image">
              <img src={api.rootUrl1 + product1?.image_path} alt="Product" />
            </div>
            <div className="Left_Right">
              <div className="Left" onClick={handleLeftArrowClick}>
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29.0908 17.6505H6.69708M6.69708 17.6505C6.69708 17.6505 13.0447 13.476 14.3345 10.0131M6.69708 17.6505C6.69708 17.6505 12.3086 21.573 14.3345 25.288"
                    stroke="#1E1E1E"
                    strokeWidth="2.82869"
                    strokeLinecap="square"
                  />
                </svg>
              </div>
              <div className="Right" onClick={handleRightArrowClick}>
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.80078 18.005H28.1945M28.1945 18.005C28.1945 18.005 21.8469 13.8305 20.5571 10.3676M28.1945 18.005C28.1945 18.005 22.583 21.9275 20.5571 25.6425"
                    stroke="#1E1E1E"
                    strokeWidth="2.82869"
                    strokeLinecap="square"
                  />
                </svg>
              </div>
            </div>
          </div>)}
          <div className="Right_Section">
            <div className="Review_Section">
              <Rate 
              allowHalf 
              defaultValue={0}
               className="Rate" 
              value={singleProductreviews ? singleProductreviews?.find((item) => item?.rating >=4)?.rating : 0}
              disabled
               />
              <span className="Reviews">{singleProductreviews?.length}</span>
            </div>
            <div className="Product_Name">{singleProducts?.product_name}</div>
            <div className="Product_Description" dangerouslySetInnerHTML={{ __html: singleProducts?.product_description?.slice(0, 30) }}>
             {/* {singleProducts?.product_description} */}
            </div>
            <div className="Price">
              <p className="mrp">
                {styles?.currency}
                {singleProductSpecData[0]?.sp ? singleProductSpecData[0]?.sp : " - "} &nbsp;&nbsp;
                <span className="sp"> {styles.currency}{singleProductSpecData[0]?.mrp ? singleProductSpecData[0]?.mrp : " - "}</span>
              </p>
            </div>
            <div className="Buttons">
              <Button 
              className="AddtoCart"
              htmlType="submit"
              loading={addToCartLoading}
              onClick={addToCart} 
              block
              >
                Add to cart
              </Button>
              <Link to={`/product/${product?.product_main?._id}`} style={{ textDecoration: "none" }}>
                <Button className="ViewDetail" block>
                  View Details
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="Bottom_Product">
       { product?.product_slider?.length > 0 ? ( <div className="Product_Box">
          <Carousel cols={8} rows={1}  loop>
          {product?.product_slider?.map((item, i) => {
              return (
                <Carousel.Item key={i}>
                <Link to={`/product/${item?._id}`} style={{ textDecoration: "none" }}>
                  <div className="images" key={i}>
                    <img src={api.rootUrl1 + item?.images} alt="Product" className="img" />
                  </div>
                </Link>
                </Carousel.Item>
              );
            })}
          </Carousel>
          </div>) : null}
          {/* <div className="Left">
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.0908 17.6505H6.69708M6.69708 17.6505C6.69708 17.6505 13.0447 13.476 14.3345 10.0131M6.69708 17.6505C6.69708 17.6505 12.3086 21.573 14.3345 25.288"
                stroke="#1E1E1E"
                strokeWidth="2.82869"
                strokeLinecap="square"
              />
            </svg>
          </div>
          <div className="Product_Box">
            {product?.product_slider?.map((item, i) => {
              return (
                <div className="images" key={i}>
                  <img src={api.rootUrl1 + item?.images} alt="Product" className="img" />
                </div>
              );
            })}
          </div>
          <div className="Right">
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.80078 18.005H28.1945M28.1945 18.005C28.1945 18.005 21.8469 13.8305 20.5571 10.3676M28.1945 18.005C28.1945 18.005 22.583 21.9275 20.5571 25.6425"
                stroke="#1E1E1E"
                strokeWidth="2.82869"
                strokeLinecap="square"
              />
            </svg>
          </div> */}
        </div>
      </div>
      <Modal
          open={open}
          onCancel={handleCancel}
          onOk={handleOk}
          footer={null}
          title="Login"
          width={400}
        >
          <Form
            layout="vertical"
            form={loginForm}
            name="Login_Form"
            onFinish={login}
          >
            <Form.Item
              label="Enter Mobile number"
              name="buyer_number"
              rules={[
                {
                  required: true,
                  message: "Please enter your Mobile number!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Enter Password"
              name="buyer_password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={isSaving}
                className="primary_btn"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          <Or>or</Or>

          <NewAccount>
            <Link to="/register">Click Here</Link>
            to Register a Account
          </NewAccount>
        </Modal>
    </HomeProductAlign>
  );
};

export default HomeProduct1;

const HomeProductAlign = styled.div`
  .Product_Main {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    padding: 10px 40px 10px 40px;
  }

  .Product_Main .Left_Section {
    width: 20%;
  }

  .Product_Main .Middle_Section {
    width: 40%;
  }

  .Product_Main .Right_Section {
    width: 30%;
  }

  .Left_Section .Thumb_Images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .Left_Section .Thumb_Images .images {
    width: 90.244px;
    /* height: 90.894px; */
    flex-shrink: 0;
  }

  .Product_Main .Middle_Section {
    width: 400px;
    /* height: 350px; */
    flex-shrink: 0;
    display: grid;
    place-content: center;
  }

  .Middle_Section .Image {
    padding: 0 20px;
  }

  .Product_Main .Middle_Section img {
    width: 400px;
    height: 400px;
  }

  .Product_Main .Middle_Section .Left_Right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: -150px;
  }

  .Product_Main .Middle_Section .Left_Right .Left,
  .Product_Main .Middle_Section .Left_Right .Right {
    display: inline-flex;
    padding: 10px;
    align-items: flex-start;
    gap: 14.143px;
    border-radius: 33.944px;
    background: #fff;
    box-shadow: 0px 9.149px 25.414px 0px rgba(0, 0, 0, 0.06);
  }

  .Right_Section {
    line-height: 60px;
  }

  .Right_Section .Review_Section .Rate {
    display: flex;
    width: 70px;
    height: 14px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    color: #343839;
  }

  .Right_Section .Review_Section {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 0 0 20px 30px;
  }

  .Right_Section .Review_Section .Reviews {
    color: var(--neutral-07100, #141718);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .Right_Section .Product_Name {
    color: #141718;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px; /* 137.5% */
    letter-spacing: -0.4px;
  }

  .Right_Section .Product_Description {
    padding: 10px 0;
    color: #6c7275;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Flama Trial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .Right_Section .Price {
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 10px 0;
  }
  .Right_Section .Price .mrp {
    color: #121212;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px; /* 141.667% */
    letter-spacing: -0.6px;
    padding-inline: 5px;
  }

  .Right_Section .Price .sp {
    color: #6c7275;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
    text-decoration-line: line-through;
  }

  .Buttons .AddtoCart {
    border-radius: 8px 0px;
    border: 1px solid #56c20e;
    background: #56c20e;
    width: 357.968px;
    height: 40px;
    flex-shrink: 0;
  }

  .Buttons .AddtoCart span {
    color: #fff;
    text-align: center;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.16px;
    text-transform: capitalize;
  }

  .Buttons .AddtoCart:hover {
    border: 1px solid #56c20e;
    background: #56c20e;
  }

  .Buttons .ViewDetail span {
    color: #56c20e;
    text-align: center;
    font-family: Flama Trial;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 20.8px */
    letter-spacing: -0.16px;
    text-transform: capitalize;
  }

  .Buttons .ViewDetail {
    border-radius: 8px 0px;
    border: 1px solid #56c20e;
    width: 357.968px;
    height: 40px;
    flex-shrink: 0;
  }

  .Buttons .ViewDetail:hover {
    border: 1px solid #56c20e;
    background: transparent;
  }

  .Bottom_Product {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 0 50px;
  }

  .Bottom_Product .Product_Box {
    display: flex;
    align-items: center;
    justify-content: center;
   
  }

  .Bottom_Product .Product_Box .images {
    display: grid;
    place-content: center;
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 10.471px;
    background: rgba(217, 217, 217, 0.49);
  }

  .Bottom_Product .Product_Box .images img {
    width: 62px;
    height: 62px;
    flex-shrink: 0;
    mix-blend-mode: multiply;
    filter: opacity(1);
  }

  .Bottom_Product .Left,
  .Bottom_Product .Right {
    display: inline-flex;
    padding: 10.735px;
    align-items: flex-start;
    gap: 9.76px;
    border-radius: 23.423px;
    background: #fff;
    box-shadow: 0px 6.313px 17.537px 0px rgba(0, 0, 0, 0.06);
  }  

  .bqyzQp {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    .Product_Main {
      display: grid;
      grid-template-columns: 1fr;
      gap: 20px;
      align-items: center;
      justify-content: center;
    }

    .Left_Section .Thumb_Images {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-content: center;
    }

    .Left_Section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }

    .Middle_Section {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  .Right_Section {
   
    width: 100%;
    display: grid;
    place-items: left
  }

  .Bottom_Product .Product_Box {
    border: 1px solid red;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
  }

  }
`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;

const NewAccount = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
