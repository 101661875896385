import { createSlice } from "@reduxjs/toolkit";

const address = createSlice({
  name: "address",
  initialState: {
    address: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    addressGetAllStart: (state) => {
      state.isFetching = true;
    },
    addressGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.address = action.payload;
    },
    addressGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    emptyUserAddress: (state, action) => {
      state.address = action.payload;
    },
  },
});

export const {
  addressGetAllStart,
  addressGetAllSuccess,
  addressGetAllFail,
  emptyUserAddress,
} = address.actions;
export default address.reducer;
