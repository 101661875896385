import API from "./ApiService";

class crud_service {
  constructor() {
    this.api = new API();
  }

  //getall function
  getAll = async (dispatch, action, url, params, callback) => {
    await this.api
      .getAll(dispatch, action, url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };
  getAll1 = async (url,params,callback) => {
    await this.api.getAll1(url,params).then((res)=>{
      callback(null,res)
    }).catch((err)=>{
      callback(err,null)
    });
  };
  getAllTax = async (dispatch, action, url, params, callback) => {
    await this.api
      .getAllTax(dispatch, action, url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };
  getAllCategory = async (dispatch, action, url, params, callback) => {
    await this.api
      .getAllCategory(dispatch, action, url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //getsingle function
  getSingle = async (dispatch, action, url, params, callback) => {
    await this.api
      .getSingle(dispatch, action, url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);

      });
  };

  //create function
  create = async (dispatch, action, url, data, callback) => {
    await this.api
      .create(dispatch, action, url, data)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //update function
  update = async (dispatch, action, url, params, data, callback) => {
    await this.api
      .update(dispatch, action, url, params, data)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //remove function
  remove = async (dispatch, action, url, params, callback) => {
    await this.api
      .remove(dispatch, action, url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //create common function
  createCommon = async (url, data, callback) => {
    await this.api
      .createCommon(url, data)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };

  //getcommonall function
  getCommonAll = async (url, params, callback) => {
    await this.api
      .getCommonAll(url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };
  
  getCommonAll1 = async (dispatch, action, url, params, callback) => {
    await this.api
      .getCommonAll1(dispatch, action, url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };
  getCommonAll2 = async (dispatch, action, url, params, callback) => {
    await this.api
      .getCommonAll2(dispatch, action, url, params)
      .then((res) => {
        callback(null, res);
      })
      .catch((err) => {
        callback(err, null);
      });
  };
};


export default crud_service;
