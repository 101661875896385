import React, { useState, useEffect } from "react";
// import MobileMenu from '../MenuBar/MobileMenu';
import {
  ShoppingCartOutlined,
  BankOutlined,
  UserOutlined,
  ShopOutlined,
  WalletOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Menu, Input, Badge, Dropdown } from "antd";
import { Link, useNavigate } from "react-router-dom";
import logo from '../../Assets/Images/logo.png'
import { base_url } from '../../config'
import hcart from '../../Assets/Images/Agri/c_head.jpg'
import loginimg from "../../Assets/Images/Agri/c_login.jpg"
import { getCartList } from "../../Redux/Store";
import { styles } from "../../ApiService/Data";
import styled from "styled-components";
import { connect, useSelector, useDispatch } from "react-redux";
import API from "../../ApiService/ApiService";
import SearchBoxComponent from "../../Ecommerce/SearchBox";
const { Search } = Input;

const { SubMenu } = Menu;

const Header5 = () => {
  const loginTrue = useSelector((state) => state?.user?.currentUser?.token);
  const dispatch = useDispatch();
  const [category, setCategory] = useState([]);
  const cartList = useSelector((state) => state?.cart?.products);
  const api = new API();
  const history = useNavigate();
  const [visible, setVisible] = useState(false);
  const logout = () => {
    api.logout(dispatch);
    history("/");
  };
  const company = useSelector((state) => {
    return state?.company?.value;
  });
  const onClose = () => {
    setVisible(false);
  };

  const menu = (
    <Menu onClick={onClose} className="profile_drop">
      <Menu.Item key="3">
        <Link to="/my-profile">
          <UserOutlined />
          My Profile
        </Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to="/my-profile">
          <ShopOutlined /> My Address
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/cart">
          <ShoppingCartOutlined /> Cart
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/cart">
          <BankOutlined /> Checkout
        </Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link to="/my-order">
          <WalletOutlined /> My Order
        </Link>
      </Menu.Item>
      <Menu.Item key="6">
        <a onClick={logout}>
          <LogoutOutlined /> Sign out
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
    <HeaderSection>
                <div className='wrapper'>
                    <div className='header_align'>
                        <div className='header_left'>
                            <Link to="/">
                                <img src={company?.logo ? base_url + company?.logo : logo} alt="Logo" />
                            </Link>

                        </div>
                        <div className='header_right'>
                            <div className='header_menubar'>

                                <Menu mode="horizontal">
                                    <Menu.Item key="home">
                                        <Link to="/">Home</Link>
                                    </Menu.Item>
                                    <Menu.Item key="about">
                                        <Link to="/about">About Us</Link>
                                    </Menu.Item>
                                    <SubMenu key="Categories" title="Categories">
                                        {category?.map((e, i) => {
                                            return (
                                                <Menu.Item key={`menuPdttemp1_${i}`}>
                                                    <Link to={`/${e?.category_name.toLowerCase().replace(/ /g, '-')
                                                        .replace(/[^\w-]+/g, '')}`}>
                                                        {e?.category_name}
                                                    </Link>
                                                </Menu.Item>
                                            );
                                        })}
                                    </SubMenu>
                                    <Menu.Item key="contact">
                                        <Link to="/contact">Contact Us</Link>
                                    </Menu.Item>
                                </Menu>
                            </div>
                            <div className='header_searchbar'>
                                {/* <Search
                                    placeholder="Search Products"
                                    onSearch={onSearch}
                                    style={{
                                        width: 200,
                                    }}
                                /> */}
                                {<SearchBoxComponent />}
                            </div>
                            <div className='shop_cart'>
                                <div className='shop_account'>
                                    {
                                        !loginTrue && loginTrue !== "" ? <Link to="/login"><img src={loginimg} alt="Login" title='Login' /></Link>
                                            :
                                            <Dropdown overlay={menu} placement="bottomRight">
                                                <div
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img src={loginimg} alt="Login" title='My Account' />

                                                </div>
                                            </Dropdown>
                                    }

                                </div>
                                <Link to="/cart">
                                    <Badge count={loginTrue != "" && !loginTrue ? 0 : cartList?.length === 0 ? 0 : cartList?.length} size="small" showZero>
                                        <img src={hcart} alt="cart" title='View Cart' />
                                    </Badge>
                                </Link>
                            </div>
                            <div className='mob_menu'>
                                {/* <MobileMenu /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </HeaderSection>
    </>
  );
};

export default Header5;

const HeaderSection = styled.section`
  .mob_menu .ant-drawer-body {
    padding: 20px 0 !important;
  }
  * {
    font-family: ${styles?.r_regular};
  }
  width: 100%;
  display: inline-block;
  position: relative;
  padding: 17px 0;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 0 8px rgb(0 0 0 / 10%);
  .header_align {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .ant-input-affix-wrapper {
    border: 0 !important;
    background-color: transparent !important;
  }
  .header_align .header_left {
    display: inline-block;
    width: fit-content;
    position: relative;

    img {
      height: 60px;
      cursor: pointer;
    }
  }
  .header_align .header_right {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    width: fit-content;
  }
  .header_align .header_right .header_menubar {
    display: inline-block;
    width: fit-content;
    ul {
      display: inline-block;
      width: 375px;
      li {
        padding: 0 15px;
      }
      a {
        font-size: 15px;
      }
    }
  }
  .header_searchbar {
    display: inline-block;
    width: fit-content;
    border: 1px solid #a7a7a7;
    padding: 5px 5px;
    border-radius: 35px;
    input.ant-input,
    button {
      border: 0 !important;
      outline: none !important;
      background-color: transparent !important;
      background: transparent !important;
    }
    .ant-input:focus {
      border: 0 !important;
    }
    .ant-input-group-addon {
      background-color: transparent;
    }
  }

  .header_align .header_right .shop_cart {
    display: flex;
    align-items: center;
    gap: 17px;
    margin: 0 0 0 13px;
    img {
      height: 25px;
    }
  }
  .header_align .header_right .shop_cart a {
    display: flex;
  }
  .mob_menu {
    display: none;
  }

  .HE_6_Menu button.menu_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 9px;
  }
  .shop_account img {
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    padding: 15px 0;
    .header_menubar {
      display: none !important;
    }

    .mob_menu {
      display: block;
      button.barsMenu {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .header_searchbar {
      padding: 2px 5px !important;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 12px 0;

    .header_align .header_left img {
      height: 50px;
    }

    .header_align {
      position: relative;
      padding: 0 0 45px;
    }

    .header_searchbar {
      padding: 0px 5px !important;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      .ant-input-group-wrapper.ant-input-search {
        width: 100% !important;
      }
    }

    @media screen and (max-width: 480px) {
      .header_align .header_left img {
        height: 45px;
      }
      .header_align .header_right .shop_cart {
        gap: 12px;
      }
    }

    @media screen and (max-width: 380px) {
      .header_align .header_left img {
        height: 40px;
      }
    }
  }
`;
