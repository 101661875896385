import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import footerlogo from "../../Assets/Images/Agri/footer_logo.png";
import instagram from "../../Assets/Images/Agri/instagram.png";
import footerbg from "../../Assets/Images/Agri/footer_bg.png";
import address from "../../Assets/Images/Agri/location.png";
import call from "../../Assets/Images/Agri/call.png";
import mail from "../../Assets/Images/Agri/mail.png";
import { Avatar, Tooltip, Space } from "antd";
import { styles } from "../../ApiService/Data";
import { useSelector } from "react-redux";
import API from "../../ApiService/ApiService";
import { base_url } from "../../config";

const Footer5 = ({ company, socialmedia }) => {
  const FooterLinks = [
    { path: "/", title: "Home" },
    { path: "/about", title: "About Us" },
    { path: "/contact", title: "Contact Us" },
    { path: "/enquiry", title: "Enquiry" },
  ];

  const OtherLinks = [
    { path: "/privacy-policy", title: "Privacy Policy" },
    { path: "/terms", title: "Terms and Conditions" },
    { path: "/refund-policy", title: "Refund Policy" },
    { path: "/delivery-policy", title: "Delivery Policy" },
    { path: "/return-policy", title: "Return Policy" },
    { path: "/cancellation-policy", title: "Cancellation Policy" },
  ];
  const year = new Date().getFullYear();

  return (
    <>
      <FooterSection>
        <div className="footer_Section">
          <div className="wrapper">
            <div className="footer_align">
              <div className="footer_1">
                <h4>Contact Us</h4>
                <ul>
                  <li className="address">
                    {company?.company_addresss}, {company?.city},<br />
                    Pincode -{company?.pin_code},{company?.state}
                  </li>
                  <li className="phone">
                    Office :{" "}
                    <a href={"tel:" + company?.contact_number}>
                      {company?.contact_number}
                    </a>
                    ,{" "}  
                    <a href={"tel:+91" + company?.mobile_number}>
                      {company?.mobile_number}
                    </a>
                  </li>
                  <li className="email">
                    <a href={"mailto:" + company?.email_id}>{company?.email_id}</a>
                  </li>
                </ul>
              </div>
              <div className="footer_2">
                <h4>Quick links</h4>
                <ul>
                  {FooterLinks?.map((e, i) => (
                    <li key={`qL_${i}`}>
                      <Link to={e?.path}>{e?.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="footer_3">
                <h4>Useful Links</h4>
                <ul>
                  {OtherLinks?.map((e, i) => (
                    <li key={`qL_${i}`}>
                      <Link to={e?.path}>{e?.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="footer_4">
                <img src={base_url + company?.logo} alt="Logo" />
                {/* <ul>
                                    <li>
                                        <img src={instagram} alt="instagram" />
                                    </li>
                                </ul> */}
                {/* <div className="s_media">
                  <Space>
                    {socialmedia?.map((e, i) => (
                      <Tooltip title={e?.label} key={`smL${i}`}>
                        <a href={e?.link} target="_blank">
                          <Avatar gap={4} src={base_url + e?.icon}></Avatar>
                        </a>
                      </Tooltip>
                    ))}
                  </Space>
                </div> */}
              </div>
            </div>
          </div>
          <div className="copy_text">
            <div className="wrapper">
              <div className="copy_align">
                <div className="copy_left">
                  <p>All Rights Reserved. {company?.company_name}</p>
                </div>
                <div className="copy_right">
                  <p>
                    © {year} Designed by{" "}
                    <a
                      href="https://ecdigi.com/"
                      target="_blank"
                      title="ecDigi Technologies"
                    >
                      ecDigi Technologies.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FooterSection>
    </>
  );
};

export default Footer5;

const FooterSection = styled.section`
  width: 100%;
  display: inline-block;
  position: relative;
  background: #1e1e1e;
  margin: 145px 0 0 0;
  &::before {
    content: "";
    background: url(${footerbg});
    position: absolute;
    top: -144px;
    left: 0;
    background-size: 100% auto;
    width: 100%;
    height: 145px;
    background-repeat: no-repeat;
    background-position: bottom center;
  }
  a {
    color: ${styles?.white};
  }
  .footer_Section {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 60px 0 0 0;
  }
  .footer_Section .footer_align {
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    ul {
      padding: 0;
    }
    h4 {
      color: ${styles?.white} !important;
      font-family: ${styles?.r_regular} !important;
      font-size: 27px;
      margin: 0 0 30px !important;
      text-transform: uppercase;
    }
  }
  .footer_1 {
    width: 28%;
    display: inline-block;
    ul {
      list-style: none;
    }
    ul li {
      color: ${styles?.white};
      margin: 0 0 20px;
      padding: 0 0 0 40px;
      font-size: 15px;
      position: relative;
      line-height: 1.9;
    }
    ul li:last-child {
      margin: 0;
    }
    ul li::before {
      content: "";
      position: absolute;
      background: url(${address});
      background-repeat: no-repeat !important;
      height: 21px;
      width: 21px;
      background-size: contain !important;
      left: 0;
      top: 6px;
      background-position: center left !important;
    }
    ul li.address::before {
      background: url(${address});
    }
    ul li.phone::before {
      background: url(${call});
    }
    ul li.email::before {
      background: url(${mail});
    }
  }
  .footer_2 {
    width: fit-content;
    display: inline-block;
    ul li {
      line-height: 1.9;
      margin: 0 0 8px;
      font-size: 15px;
      color: ${styles?.white} !important;
      a {
        color: ${styles?.white} !important;
      }
    }
  }
  .footer_3 {
    width: fit-content;
    display: inline-block;
    ul li {
      line-height: 1.9;
      margin: 0 0 8px;
      font-size: 15px;
      color: ${styles?.white} !important;
      a {
        color: ${styles?.white} !important;
      }
    }
  }
  .footer_4 {
    width: fit-content;
    display: inline-block;

    img {
      margin: auto;
      height: 80px;
      filter: brightness(0) invert(1);
    }

    ul {
      list-style: none;
      width: fit-content;
      margin: 30px auto auto;
      display: flex;
      align-items: center;
      gap: 15px;

      img {
        height: auto;
        filter: inherit;
      }
    }
  }

  .copy_text {
    width: 100%;
    display: inline-block;
    padding: 15px 0;
    border-top: 1px solid #4f4f4f;
    margin: 50px 0 0 0;
    p {
      line-height: 1.5;
      color: ${styles?.white};
      margin: 0;
    }
  }
  .copy_text .copy_align {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  .s_media {
    width: fit-content;
    margin: 40px auto auto;
  }

  @media screen and (max-width: 956px) {
    .footer_Section .footer_align {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 45px 30px;
    }
    .footer_1,
    .footer_2,
    .footer_3,
    .footer_4 {
      display: inline-block;
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 75px 0 0 0;
  }

  @media screen and (max-width: 480px) {
    .footer_Section .footer_align {
      display: flex;
      flex-direction: column-reverse;
    }

    .copy_text .copy_align {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
    }
  }
`;
