import { createSlice } from "@reduxjs/toolkit";

const category = createSlice({
  name:'category',
  initialState:{
    category:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    categoryGetAllStart:(state)=>{
      state.isFetching = true;
    },
    categoryGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.category = action.payload;
    },
    categoryGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {categoryGetAllStart,categoryGetAllSuccess,categoryGetAllFail} = category?.actions;

export default category.reducer;



