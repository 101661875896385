import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { styled } from "styled-components";
import Default from "../../Assets/Images/default.png";
import API from "../../ApiService/ApiService";
import { base_url } from "../../config";


const TC6 = ({ category }) => {
// console.log("cat component", category)
  const api = new API();
  const navigate = useNavigate()
  return (
    <CategoryWrapper>
      <div className="wrapper">
        <div className="heading_container">
          <h2>Categories</h2>
          <Button onClick={()=>navigate("/all-categories")}>Show All</Button>
        </div>
        <Row className="row" gutter={[16, 24]}>
          {category?.slice(0,6)?.map((item) => (

            <Col key={item._id} xs={12} sm={8} md={6} lg={6} xl={4}>
              <div className="category">
                <Link
                  to={`/Products?catName=${item?.name
                    .replace(" ", "-")
                    .toLowerCase()
                    .replace(/ /g, "")
                    .replace(/[^\w-]+g/, "")}`}
                >
                  <img
                    src={item?.image ? api.rootUrl1 + item?.image : Default}
                    className="category_img"
                    alt=""
                  />
                </Link>
              </div>
              <div className="categoryDetail">
                <Link
                  to={`/Products?catName=${item?.name
                    .replace(" ", "-")
                    .toLowerCase()
                    .replace(/ /g, "")
                    .replace(/[^\w-]+g/, "")}`}
                  style={{ textDecorationColor: "black" }}
                >
                  <p>{item?.name}</p>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </CategoryWrapper>
  );
};

export default TC6;

const CategoryWrapper = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin: 0 auto;
  /* margin: 0 10px; */

 

  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .heading_container{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .row {
    width: 90%;
    /* justify-content: center; */
    justify-items: center;
    margin-top: 20px;
  }

  .category {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid gray;
  }

  .category .category_img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
  /* img {
    width: 100%;
  } */

  .heading {
    width: 100%;
  }
  .categoryDetail {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 8px 0;
      font-size: 16px;
      color: black;
    }
  }
`;
