import React from "react";
import styled from "styled-components";
import { base_url } from "../../config";
import default_image from "../../Assets/Images/default.png";
import { Link } from "react-router-dom";
import { font, theme } from "../../ApiService/Theme";

const Hc36 = ({ props }) => {
  return (
    <Hc1Section>
      <div className="hc1Section">
        <div className="wrapper">
          <h2 className="secHeading">
            {props?.section_value ? props?.section_value : ""}
          </h2>
          <ul>
            {props?.content?.slice(0, 3).map((e, i) => {
              // console.log(e);
              return (
                <li key={i}>
                  <div className="container">
                    <div className="productImg">
                      <img
                        src={e?.image ? base_url + e?.image : default_image}
                        alt={e?.title}
                        //   style={{width: "200px", height: "200px"}}
                      />
                    </div>
                    <div className="productDetail">
                      <h4>{e?.title}</h4>
                      <h5>{e?.sub_title}</h5>
                      <div className="btnContainer">
                        <Link to={e?.link ? e?.link : void 0}>
                          <button className="shopNowBtn">
                            {e?.link_text ? e?.link_text : "Shop Now"}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Hc1Section>
  );
};

export default Hc36;

const Hc1Section = styled.section`
  width: 100%;

  .secHeading {
    margin-bottom: 15px;
  }

  .wrapper {
    max-width: 1200px;
    width: 100%;

    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      padding: 0;

      @media screen and (max-width: 1023px) {
        grid-template-columns: repeat(1, 1fr);
      }

      h4,
      h5 {
        font-family: ${font?.regular} !important;
        color: #212529;
      }

      h4 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 15px;
        @media screen and (max-width: 450px) {
          margin-bottom: 10px;
          font-size: 30px;
        }
      }
      h5 {
        margin-bottom: 50px;
        @media screen and (max-width: 450px) {
          margin-bottom: 10px;
          font-size: 20px;
        }
      }
    }

    .container {
      display: flex;
      background-color: #f8d7da;
      padding: 0;
      border-radius: 10px;
      box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.18);

      .productImg {
        width: 200px;
        img {
          width: 200px;
          aspect-ratio: 1/1;
          object-fit: cover;
          border-radius: 10px;
        }
        @media screen and (max-width: 450px) {
          width: 150px;
          img {
            width: 150px;
          }
        }
      }
      .productDetail {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;

        transition: all 2s ease;
        color: #212529;

        .shopNowBtn {
          padding: 10px 60px;
          border-radius: 10px;
          border: none;
          color: white;
          background-color: #212529;
          @media screen and (max-width: 450px) {
            padding: 5px 40px;
          }
        }
      }
    }
  }
`;
