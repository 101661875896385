import { createSlice } from "@reduxjs/toolkit";

const tax = createSlice({
  name:'tax',
  initialState:{
    tax:null,
    isFetching: false,
    error: false,
  },
  reducers:{
    taxGetAllStart:(state)=>{
      state.isFetching = true;
    },
    taxGetAllSuccess:(state,action)=>{
      state.isFetching = false;
      state.tax = action.payload;
    },
    taxGetAllFail:(state)=>{
      state.isFetching = false;
      state.error = true;
    },
  }
});

export const {taxGetAllStart,taxGetAllSuccess,taxGetAllFail} = tax?.actions;
export default tax.reducer;