import React from "react";
import BannerMain from "../Pages/BannerMain";
import styled from "styled-components";
import HomeProductMain from "../Pages/HomeProductMain";
import AboutUsClientSectionMain from "../Pages/AboutUsClientSectionMain";
import BestSellerMain from "../Pages/BestSellerMain";
import WhyWeUsSectionMain from "../Pages/WhyWeUsSectionMain";
import BannerSingleFurniture from "../Pages/BannerSingleFurnitureMain";
import NewArrivalSectionMain from "../Pages/NewArrivalSectionMain";
import ClientReviewMain from "../Pages/ClientReviewMain";
import HomeContactMain from "../Pages/HomeContactMain";
import HCFurniture from "../Hc2/HCFurniture";

const Template9 = ({homecustom, banner, category, product, Home_product}) => {

  // console.log("homecustom3----------", homecustom)

  return (
    <div>
      <BannerMain banner_type="BA9" banner={homecustom?.banner_main} />
      <div className="section_wrap">
        <HomeProductMain Home_product="HP1" product={homecustom?.product} />
        <AboutUsClientSectionMain about_us_client="AC2" About_us={homecustom?.aboutus} />
        <BestSellerMain best_seller="BestSeller2" homecustom={homecustom?.best_selling} />
        <HCFurniture homecustom={homecustom?.custom_banner}/>
        <WhyWeUsSectionMain why_us_type="why_us_3" why_us={homecustom?.whychoose_us} />
        <BannerSingleFurniture banner_type="BF1" homecustom={homecustom?.banner_mid} />
        <NewArrivalSectionMain new_arraival="NA1" homecustom={homecustom?.new_arrival} />
        <ClientReviewMain review_type="review_1" homecustom={homecustom?.testimonial} />
        <HomeContactMain home_contact="home_contact_1" touchwith_us={homecustom?.touchwith_us} />
        {/* <FeatureProductMain featuredproduct_type="FP7" featureProduct={product} />
        <DynamicSection />
        <Blog /> */}
      </div>
    </div>
  );
};

export default Template9;

const HomeSection = styled.section`
  overflow-x: hidden;
  .section_wrap {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 20px 0;
    gap: 40px 0;
  }
`;
