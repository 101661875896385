import React, { useEffect, useState } from "react";
import styled from "styled-components";
import API from "../ApiService/ApiService";
import {
  Tag,
  Button,
  Divider,
  Popconfirm,
  message,
  Empty,
  Input,
  Spin,
  Table,
  Modal
} from "antd";
import { DeleteOutlined, InfoOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import styles from "../ApiService/Data";
import "../../src/Assets/Css/style.css";
import { Spinner } from "react-bootstrap";
import dayjs from "dayjs";

import { cartSuccess, cartStart, cartFailure } from "../Redux/slices/cart";
import {
  wiseListStart,
  wiseListSuccess,
  wiseListFailure,
} from "../Redux/slices/wishList";
import { company_code, base_url, api_website, api_admin } from "../config";

const { confirm } = Modal
const WishList = () => {
  const api = new API();
  const CurrentDate = new dayjs();

  const [wishList, setWishList] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token") || ""
  const [cartUpdate, setCartUpdate] = useState(false);
  const [scrollSize, setScrollSize] = useState(1000);
  const navigate = useNavigate();
  const params = useParams();
  const loginTrue = localStorage.getItem("login");

  const dispatch = useDispatch()

  // console.log("user", user?.company_code);


  useEffect(() => {
    getAllWishList();
  }, []);
  const getAllWishList = () => {
    api.getWishList(user?._id).then((res) => {
      setWishList(res?.data?.data || []);
      dispatch(wiseListSuccess(res?.data));
    });
  };

  useEffect(() => {
    const handleResize = () => {
      // Adjust scroll size based on screen width
      const newScrollSize = getNewScrollSize();
      setScrollSize(newScrollSize);
    };

    // Initial adjustment
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getInitialScrollSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1024) return 1000;
    if (screenWidth >= 768) return 500;
    if (screenWidth >= 540) return 400;
    if (screenWidth >= 425) return 300;
    if (screenWidth >= 385) return 200;
    return 200; // Default for screen width <= 320
  };

  const getNewScrollSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1024) return 1000;
    if (screenWidth >= 768) return 500;
    if (screenWidth >= 540) return 400;
    if (screenWidth >= 425) return 300;
    if (screenWidth >= 385) return 200;
    return 200; // Default for screen width <= 320
  };

  const deleteItem = (id) => {
    api.deleteWishList(id).then((res) => {
      message.success("Deleted Successfully");
      getAllWishList()

    });
  };


  const showconfirm = (id) => {
    confirm({
      title: "Are you sure delete this item?",
      content: "Click OK to delete",
      onOk() {
        deleteItem(id);
      },
      onCancel() { },
    });
  }

  const addToCart = (item) => {
    // console.log(item)
    // setAddToCartLoading(true);

    if (!loginTrue) {
      // setAddToCartLoading(false);
    } else {
      const combinedData = {
        buyer_id: item?.buyer_id,
        qty: item?.qty,
        product_id: item?.product_id?._id,
        product_spec: item?.product_spec?._id,
        company_code: company_code,
        // company_code: user?.company_code,
      };

      api.addCart(combinedData).then((res) => {
        if (res?.status == 200) {
          dispatch(cartStart);
          api.getcart().then((res) => {
            // console.log("res--->", res);
            dispatch(cartSuccess(res?.data));
          });
          message.success(res?.data?.message);
          // deleteItem();
          // setAddToCartLoading(false);
        } else {
          dispatch(cartFailure);
          message.error(res?.response?.data?.message);
          if (res?.response?.data?.message == "Please Add Delivery Address") {
            navigate("/add-address");
          }
          // setAddToCartLoading(false);
        }
      });
    }
  };


  const data = [];
  wishList?.map((item) => {

    data.push({
      thumb: item?.product_spec?.product_images[0],
      title: item?.product_id?.product_name,
      price: dayjs(item?.product_spec?.discount_id?.discount_id?.discount_valid_to).format("DD-MMM-YYYYhh:mm:ss") > dayjs(CurrentDate).format("DD-MMM-YYYYhh:mm:ss") ? (item?.product_spec?.sp - ((Number(item?.product_spec?.sp * (item?.product_spec?.discount_id?.discount_id?.discount / 100)))) * (item?.product_id?.tax_percentage?.tax_percentage / 100)).toFixed(0) : (item?.product_spec?.sp + (item?.product_spec?.sp * (item?.product_id?.tax_percentage?.tax_percentage / 100))).toFixed(0),
      item,
      remove: item?._id,
    });
  });

  const columns = [
    {
      title: "THUMBNAIL",
      dataIndex: "thumb",
      key: "thumb",
      // fixed: "left",
      render: (value, records) => {
        // console.log(value)
        return <img width={50} src={api.rootUrl1 + value?.image_path} />;
      },
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      // fixed: "left",
    },
    {
      title: "SPECIFICATION",
      dataIndex: "item",
      key: "item",
      render: (value) => {
        return (
          <>
            {value?.product_spec?.specification?.map((item) => {
              return (<Tag>{item?.specification_details}</Tag>)
            })}
          </>
        )
      }
      // fixed: "left",
    },
    {
      title: "PRICE",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "ADD TO CART",
      dataIndex: "cart",
      key: "cart",
      render: (item, records) => {
        console.log("206", records)
        return <Button onClick={() => addToCart(records?.item)} disabled={!(records?.item?.product_spec?.quantity > 0)}>Add to Cart</Button>;
      },
    },
    {
      title: "REMOVE",
      dataIndex: "remove",
      key: "remove",
      render: (action) => {
        // console.log(action)
        return <DeleteOutlined onClick={() => showconfirm(action)} />;
      },
    },
  ];

  return (
    <CartSection>
      <div className="wrapper" style={{ padding: "60px 40px" }}>
        <h1>Wish List</h1>
        {wishList?.length < 1 ? (
          <Empty />
        ) : cartUpdate ? (
          <Spin fullScreen />
        ) : (
          <div className="cart_section">
            <div className="cart_left">
              <div className="cart_list" id="style-10">
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={true}
                  responsive={true}
                  scroll={{
                    x: scrollSize,
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </CartSection>
  );
};

export default WishList;

const CartSection = styled.section`
  width: 100%;
  margin: 60px 0;
  display: inline-block;

  @media screen and (max-width: 768px) {
   max-width: 768px;
    
  }
`;
