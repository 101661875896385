import { createSlice } from "@reduxjs/toolkit";

const deliverypolicy = createSlice({
  name: "deliverypolicy",
  initialState: {
    deliverypolicy: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    registerGetAllStart: (state) => {
      state.isFetching = true;
    },
    registerGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.deliverypolicy = action.payload;
    },
    registerGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    deliverypolicyGetAllStart: (state) => {
      state.isFetching = true;
    },
    deliverypolicyGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.deliverypolicy = action.payload;
    },
    deliverypolicyGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
    logoutdeliverypolicy: (state, action) => {
      state.deliverypolicy = action.payload;
    },
  },
});

export const {
  deliverypolicyGetAllStart,
  deliverypolicyGetAllSuccess,
  deliverypolicyGetAllFail,
  logoutdeliverypolicy,
  registerGetAllStart,
  registerGetAllSuccess,
  registerGetAllFail,
} = deliverypolicy?.actions;
export default deliverypolicy.reducer;
