import React, { useRef } from "react";
import styled from "styled-components";
import { Carousel, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import BannerImg1 from "../../Assets/Images/OneWear/MainBannerImg1.jpg";
import BannerImg2 from "../../Assets/Images/OneWear/MainBannerImg1.jpg";
import BannerImg3 from "../../Assets/Images/OneWear/MainBannerImg1.jpg";
import "../../Assets/Fonts/fonts.css";

const contentStyle = {
  height: "100vh",
  width: "100%",
  objectFit: "fill",
};

const overlayStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
  textAlign: "center",
  width: "50%",
};

const slideStyle = {
  position: "relative",
};

const Banner12 = ({ content_all }) => {
  console.log("content_allBanner12", content_all);
  const carouselRef = useRef(null);

  let token = localStorage.getItem("token") || "";
  let login = localStorage.getItem("login") || false;
  let userData = localStorage.getItem("user") || null;

  return (
    <BannerSection>
      <Carousel 
        ref={carouselRef}
        arrows={false}
        autoplay  
        fade 
        infinite={false}
      >
        {content_all?.bannersOneWear?.map((item, index) => (
          <div style={slideStyle} key={index}>
            <img
              className="banner-image"
              src={process.env.REACT_APP_BASE + item?.banner_img?.image}
              style={contentStyle}
            />
            <div className="overlay" style={overlayStyle}>
              <h2>{item?.description_main}</h2>
              <p>{item?.description_sub}</p>
              <div
                className="custom-button-wrapper"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "32%",
                  margin: "auto",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                {login && token && userData ? (
                  <Button
                    className="custom-button"
                    ghost
                    style={{
                      borderRadius: 0,
                      fontWeight: "bold",
                    }}
                    onClick={() => (window.location.href = `/${item?.btn_link}`)}
                  >
                    {item?.btn_txt}
                  </Button>
                ) : (
                  <>
                    <Button
                      className="custom-button"
                      ghost
                      style={{ borderRadius: 0, fontWeight: "bold" }}
                      onClick={() => (window.location.href = `/register`)}
                    >
                      SIGN UP
                    </Button>
                    <Button
                      className="custom-button"
                      ghost
                      style={{
                        borderRadius: 0,
                        fontWeight: "bold",
                      }}
                      onClick={() => (window.location.href = `/${item?.btn_link}`)}
                    >
                      {item?.btn_txt}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <ArrowLeft onClick={() => carouselRef.current.prev()}>
        <LeftOutlined />
      </ArrowLeft>
      <ArrowRight onClick={() => carouselRef.current.next()}>
        <RightOutlined />
      </ArrowRight>
    </BannerSection>
  );
};

export default Banner12;

const BannerSection = styled.div`
  position: relative;

  .overlay {
    h2 {
      font-size: 60px;
      font-family: Inter;
      font-weight: 700;
    }
  }

  .custom-button:hover {
    background-color: #ffffff !important;
    color: #c23b2c !important;
    border: 1px solid #ffffff !important;
  }

  @media (max-width: 860px) {
    .banner-image {
      height: 45vh !important;
    }
    .overlay {
      h2 {
        font-size: 6vw; /* Adjust font size for smaller screens */
      }
      p {
        font-size: 3.5vw; /* Adjust font size for smaller screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    .banner-image {
      height: 45vh !important;
    }
    .overlay {
      h2 {
        font-size: 6vw; /* Adjust font size for smaller screens */
      }
      p {
        font-size: 3.5vw; /* Adjust font size for smaller screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 10px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }

  @media (max-width: 480px) {
    .banner-image {
      height: 45vh !important;
    }
    .overlay {
      h2 {
        font-size: 8vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 4vw; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }
  @media (max-width: 380px) {
    .banner-image {
      height: 45vh !important;
    }
    .overlay {
      h2 {
        font-size: 7vw; /* Adjust font size for mobile screens */
      }
      p {
        font-size: 3vw; /* Adjust font size for mobile screens */
      }
      .button-group {
        width: 100%;
        flex-direction: column;
        gap: 5px;
      }
    }

    .custom-button-wrapper {
      width: 100%;
      flex-direction: column;
    }
  }
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  transform: translateY(-50%);
  font-size: 18px;
`;

const ArrowLeft = styled(Arrow)`
  left: 10px;
`;

const ArrowRight = styled(Arrow)`
  right: 10px;
`;
