import React from "react";
import "../../Assets/Css/style.css";
import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import Carousel from "react-grid-carousel";
import { Card, Row, Col } from "antd";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  EffectCoverflow,
} from "swiper";
import { CarTwoTone } from "@ant-design/icons";

const AboutUsClient = ({ review }) => {
  return (
    <AboutUsClientAlign>
      <div className="home_wrapper">
        <div className="Top_Title">
          <p className="Title">{review?.section_title}</p>
        </div>
        <div className="About_Section">
          <div className="AboutUsClient_Main">
            {/* <Swiper
            autoplay={{delay: 5000}}
            slidesPerView={
              3
            }
            modules={[EffectCoverflow, Navigation, Pagination, Autoplay]}
            draggable
            // breakpoints={{
            //   // when window width is >= 480px
            //   480: {
            //     slidesPerView: 2,
            //     spaceBetween: 20,
            //   },
            //   // when window width is >= 768px
            //   768: {
            //     slidesPerView: 2,
            //     spaceBetween: 10,
            //   },
            // }}
          >
            {review?.review_content?.map((item, index) => (
              <SwiperSlide key={index} className="slide">
              <div className="AboutUs_Box" key={index}>
                <div className="AboutUs_Content">{item?.card_description}</div>
                <div className="AboutUs_Title">{item?.card_name}</div>
              </div>
              </SwiperSlide>
            ))}
            </Swiper> */}
            <Carousel cols={3} rows={1} gap={50} loop
            // containerStyle={{ maxWidth: '1200px', margin: '0 auto' }}
            responsiveLayout={[
              {
                breakpoint: 800,
                cols: 2,
                rows: 1,
                gap: 10,
                loop: true,
              }
            ]}
            >
            {review?.review_content?.map((item, index) => (
              <Carousel.Item key={index} >
              <Card key={index}>
                <div className="AboutUs_Content">{item?.card_description}</div>
                <div className="AboutUs_Title">{item?.card_name}</div>
              </Card>
              </Carousel.Item>
            ))}
            </Carousel>
          </div>
        </div>
      </div>
    </AboutUsClientAlign>
  );
};

export default AboutUsClient;

const AboutUsClientAlign = styled.div`
  /* border: 1px solid red; */
  .Top_Title {
    text-align: center;
  }

  .Left_Right_Arrow {
    position: relative;
    top: -241px;
    padding: 0 50px 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
  }

  .Left_Right_Arrow .Left,
  .Left_Right_Arrow .Right {
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }

  .Left_Right_Arrow .Left:hover,
  .Left_Right_Arrow .Right:hover {
    transform: scale(1.1);
  }

  .Left_Right_Arrow .Left svg,
  .Left_Right_Arrow .Right svg {
    fill: #959595; // Change color as needed
  }

  .Top_Title .Title {
    color: #3d418b;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .Top_Title .Span_Title {
    padding-left: 10px;
    color: #0174be;
    font-family: Yellowtail;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .About_Section {
    padding: 60px 0 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /* .swiper {
    max-width: 1200px;
    width: 100%;
    height: 100%;
  } */

  /* .swiper-slide .swiper-slide-prev .slide {
    width: 300px;
  }

  .swiper-slide .swiper-slide-next .slide {
    width: 300px;
  }

  .swiper-slide .slide .swiper-slide-active {
    width: 300px;
  } */

  .swiper-slide {
    /* text-align: center;
  font-size: 18px;
  background: #fff; */

    /* Center slide text vertically */
    /* display: flex;
  justify-content: center;
  align-items: center; */
  }

  /* .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */

  .AboutUs_Box {
    padding: 30px;
    width: 350px;
    max-height: 350px;
    flex-shrink: 0;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid red;
  }
.AboutUs_Content {
    color: #8d8d8d;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
  }

 .AboutUs_Title {
    width: 90%;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
    line-height: 200%;
  }

  /* @media screen and (max-width: 767px) {
    .Top_Title span::before {
      content: "";
      white-space: pre;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    } */

    /* .AboutUsClient_Main {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
    } */
  /* } */

  /* @media screen and (min-width: 768px) and (max-width: 1023px) { */
    /* .AboutUsClient_Main {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      justify-content: center;
      align-items: center;
    } */
  /* } */
`;
