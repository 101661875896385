import React from "react";
import "../../Assets/Css/style.css";
import styled from "styled-components";
import {Button} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons";
import API from "../../ApiService/ApiService";
import { Link } from "react-router-dom"

const GiftStoriesSection = ({category, content_all}) => {
  const api = new API();

  // console.log("category", content_all);

  return (
    <Stories_Section>
      <div className="home_wrapper">
        <div className="Stories_Section">
          <div className="Section_Main">
            <div className="Left_Section">
              <p className="Title">{content_all?.smile_gift_title}</p>
              <p className="Content">{content_all?.smile_gift_desc}</p>
              <Button className="Know_More" href={`/products`}>
                Know More <ArrowRightOutlined />
                {/* {category?.category_button} <ArrowRightOutlined /> */}
              </Button>
            </div>
            <div className="Right_Section">
              {content_all?.smile_gift_pro?.map((item, index) => (
                <div className="Card_Section" key={index}>
                  <div className="Image">
                  <Link to={`/product/${item?.value}`}>
                    <img src={api.rootUrl1 + item?.image} alt={item?.label} />
                    </Link>
                  </div>
                  <div className="Title">
                    <p>{item?.label}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Stories_Section>
  );
};

export default GiftStoriesSection;

const Stories_Section = styled.div`
  padding: 0 46px 0 46px;
  .Stories_Section {
    width: 100%;
    /* height: 400px; */
    flex-shrink: 0;
    border-radius: 24px;
    border: 1px solid #eaeaea;
  }

  .Stories_Section .Section_Main {
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .Left_Section {
    width: 40%;
  }

  .Left_Section .Title {
    display: flex;
    flex-direction: column;
    color: #3d418b;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .Left_Section .Span_Title {
    margin-left: 30px;
    color: #0174be;
    font-family: Yellowtail;
    font-size: 96px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .Left_Section .Content {
    width: 231px;
    /* height: 100px; */
    flex-shrink: 0;
    color: #000;

    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 200%;
  }

  .Left_Section Button {
    color: #f6a82d;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
    border: none;
    background: transparent;
  }

  .Right_Section {
    width: 60%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
  }

  .Card_Section .Title {
    text-align: center;
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 200%;
  }

  .Card_Section .Image {
    width: 150px;
    
  }

  @media screen and (max-width: 767px) {
    padding: 0 20px 0 20px;

    .Stories_Section .Section_Main {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .Left_Section,
    .Right_Section {
      width: 100%;
    }

    .Left_Section .Span_Title {
      font-size: 50px;
      margin: 0;
    }

    .Left_Section .Title,
    .Left_Section .Content,
    .Left_Section Button {
      text-align: center;
      width: 100%;
    }

    .Right_Section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      justify-content: center;
      align-items: center;
      justify-items: center;
    }
  }

  @media screen and (min-width: 767px) and (max-width: 1200px) {
    .Right_Section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      justify-content: center;
      justify-items: center;
      gap: 20px;
    }
  }
`;
