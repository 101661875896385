import { createSlice } from "@reduxjs/toolkit";

const termsAndCondition = createSlice({
  name: "termsAndCondition",
  initialState: {
    termsAndCondition: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    termsAndConditionGetAllStart: (state) => {
      state.isFetching = true;
    },
    termsAndConditionGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.termsAndCondition = action.payload;
    },
    termsAndConditionGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
      // console.log("error--->", state.error);
    },
  },
});

export const {
  termsAndConditionGetAllFail,termsAndConditionGetAllSuccess, termsAndConditionGetAllStart
} = termsAndCondition?.actions;
export default termsAndCondition.reducer;
