import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Form, Input, Radio, Button, message } from "antd";
import {
  profileGetAllStart,
  profileGetAllSuccess,
  profileGetAllFail,
} from "../Redux/slices/profile";
import SideBar from "./SideBar";
import API from "../ApiService/ApiService";
import { styles } from "../ApiService/Data";
import { useSelector, useDispatch } from "react-redux";
import crud_service from "../ApiService/CrudService";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const crud = new crud_service();
  const profile = useSelector((state) => state?.profile?.profile);
  const user = JSON.parse(localStorage.getItem("user"));
  const api = new API();
  const userName = localStorage.getItem("user_name");
  const password = localStorage.getItem("password");
  const login = localStorage.getItem("login") || false

  const getProfile = () => {
    api.getMyProfile(user?._id).then((res) => {
      // console.log("res----->", res);
      form.setFieldsValue({
        ...res?.data?.data,
      });
    });
  };

  useEffect(() => {
    getProfile();
    if (!login) {
      localStorage.setItem('redirectPath', window.location.pathname);
      navigate("/login")
    }
  }, []);

  // useEffect(() => {
  //   if (login) {
  //     // If the user is logged in, check for a saved redirect path
  //     const redirectPath = localStorage.getItem('redirectPath');

  //     if (redirectPath) {
  //       // Clear the saved redirect path
  //       localStorage.removeItem('redirectPath');
  //       // Navigate to the saved redirect path
  //       navigate(redirectPath);
  //     } else {
  //       // If no saved redirect path, navigate to the default route
  //       navigate('/');
  //     }
  //   }
  // }, [login]);

  // console.log("user----->", user);

  const myProfileForm = (values) => {
    api.UpdateProfile(user?._id, values).then((res) => {
      // console.log("res----->", res);
      if (res?.data?.status == 200) {
        message.success(res?.data?.message);
      } else {
        message.error(res?.data?.message);
      }
    });
  };

  return (
    <MyProfileSection>
      <div className="wrapper" style={{ padding: "60px 40px" }}>
        <div>
          <Title>My Profile</Title>
          <div className="my_profile_section">
            <div className="">
              <SideBar />
            </div>
            <div className="my_profile_right">
              <div className="my_profile_form">
                <div className="my_profile_form_left">
                  <Form
                    name="basic"
                    form={form}
                    layout="vertical"
                    onFinish={myProfileForm}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Name"
                      name="buyer_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your name!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Email Address"
                      name="buyer_emailid"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please input your valid email!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Mobile Number"
                      name="buyer_number"
                      readOnly
                      rules={[
                        {
                          required: true,
                          pattern: /^[0-9]{10}$/,
                          message: "Please input your mobile number!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    {/* <Form.Item
                      name="buyer_gender"
                      label="Gender"
                      rules={[
                        {
                          required: true,
                          message: "Please select gender!",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="Male">Male</Radio>
                        <Radio value="Female">Female</Radio>
                        <Radio value="Transgender">Transgender</Radio>
                      </Radio.Group>
                    </Form.Item> */}
                    <Form.Item>
                      <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                      >
                        Save Profile
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
                <div className="my_profile_form_right"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MyProfileSection>
  );
};

export default MyProfile;

const MyProfileSection = styled.section`
  margin-top: 40px;
  .my_profile_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .my_profile_section {
    margin-top: 20px;
  }
  .my_profile_form {
    padding: 25px 20px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
   .my_profile_section {
    display: flex !important;
    gap: 30px;
   }    
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    .my_profile_section {
      display: flex !important;
    }
    .Side_BarMain {
      min-width: 0% !important;
      width: 130px !important;
    }
    .Side_BarMain ul{
      padding: 0;
    }
    .laQDLI {
      min-width: 0% !important;
    }
    
  }

  @media screen and (min-width: 425px) and (max-width: 539px) {
    .my_profile_section .my_profile_right {
      width: 100% !important;
    }

    .my_profile_section {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      flex-direction: column !important;
    }

    /* .laQDLI {
      padding: 0px;
      min-height: 0px;
    } */
  }

  @media screen and (min-width: 375px) and (max-width: 424px) {
    .my_profile_section {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      flex-direction: column !important;
    }
    .my_profile_right {
      width: 110% !important;
    }
    .laQDLI {
      padding: 0px;
      min-height: 0px;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 374px) {
    .my_profile_section {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      flex-direction: column !important;      
    }
    .my_profile_right {
      width: 120% !important;
    }
    .laQDLI {
      padding: 0px;
      min-height: 0px;
    }   
    .Side_BarMain {
      min-width: 0% !important;
    } 
  }

  @media screen and (max-width: 319px) {
    .my_profile_section {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      flex-direction: column !important;
    }
    .laQDLI {
      padding: 0px;
      min-height: 0px;
    }
  }

  /* @media screen and (min-width: 760px) and (max-width: 1024px) {
    .my_profile_section {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      flex-direction: row !important;
    }
  } */
`;

const Title = styled.h1`
  font-size: 30px;
  color: ${styles.color};
  margin: 30px 0 10px;
`;
