import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import CartList from "../../Ecommerce/CartList";
import PriceList from "../../Ecommerce/PriceList";
import BillingAddress from "../../Ecommerce/BillingAddress";
import CheckoutAddress from "./CheckoutAddress";
import { Divider, Radio, Space, Avatar, Result, message } from "antd";
import styles from "../../ApiService/Data";
import "../../../src/Assets/Css/style.css";
import { Link, useNavigate } from "react-router-dom";
import Gpay_Logo from "../../Assets/Images/Payment/gpay.png";
import API from "../../ApiService/ApiService";
import Phonepe_Logo from "../../Assets/Images/Payment/phonepe_logo.png";

const CheckoutStepper = ({ totalAmount }) => {
  const [radioValue, setRadioValue] = useState(1);
  const [activeStep, setActiveStep] = useState(0);
  const [cart, setCart] = useState([]);
  const navigate = useNavigate();
  const api = new API();
  const getUser = localStorage.getItem("user") || "";
  const getDeliveryAddress = localStorage.getItem("Default Address") || "";

  let user;
  let deliveryAddress;
  if (getUser || getDeliveryAddress) {
    user = getUser && JSON.parse(getUser);
    deliveryAddress = getDeliveryAddress && JSON.parse(getDeliveryAddress);
  } else {
    user = "";
    deliveryAddress = "";
  }

  const data4Checkout = {
    buyer_id: user?._id
  }

  useEffect(() => {
    getAllCart();
  }, []);
  const getAllCart = () => {
    api.getcheckout(data4Checkout).then((res) => {
      // console.log("res->", res);
      setCart(res?.data?.data);
    });
  };

  const onChange = (e) => {
    setRadioValue(e.target.value);
  };

  const steps = [
    // {
    //   label: (
    //     <div
    //       style={{
    //         fontSize: "16px",
    //         fontWeight: 600,
    //         textTransform: "uppercase",
    //       }}
    //     >
    //       Order Summary
    //     </div>
    //   ),
    //   description: (
    //     <>
    //       <CartList />
    //       <Divider />
    //     </>
    //   ),
    // },
    {
      label: (
        <div className="address_label"
          style={{
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          Delivery Address
        </div>
      ),
      description: <CheckoutAddress />,
    },
    {
      label: (
        <div className="address_label"
          style={{
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          Order Summary
        </div>
      ),
      description: (
        <>
          <CartList />
          <Divider />
        </>
      ),
    },
    {
      label: (
        <div className="address_label"
          style={{
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "uppercase",
          }}
        >
          Payment Options
        </div>
      ),
      description: (
        <div className="Payment">
          <Radio.Group onChange={onChange} value={radioValue}>
            <Space direction="vertical">
              {/* <Radio value={1}>UPI</Radio>
              {radioValue == 1 && (
                <Radio.Group style={{marginLeft: "20px"}}>
                  <Radio value={1}>
                    <Avatar src={Gpay_Logo} /> Gpay
                  </Radio>
                  <Radio value={2}>
                    <Avatar src={Phonepe_Logo} /> PhonePe
                  </Radio>
                </Radio.Group>
              )}
              <Radio value={2}>Net Banking</Radio> */}
              <Radio value={1}>Easebuzz</Radio>
            </Space>
          </Radio.Group>
        </div>
      ),
    },
  ];

  const min = 1;
  const max = 10000;
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

  const handleNext = (index) => {
    // console.log("index--->", index);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (index == steps?.length - 1) {
      const combinedData = {
        buyer_id: user?._id,
        buyer_address_id: deliveryAddress?._id,
      };

      api.placeorder(combinedData).then((res) => {
        // console.log("res--->", res);
        if (res?.status == 200) {
          // console.log(res?.data?.data)
          window.open(res?.data?.data?.redirect, "_self")
          message.success("Directed to Payment Successfully");
        } else {
          message.error("Something Went Wrong");
        }
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <StepperAlign>
      <Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step?.label}>
              <StepLabel
                className={
                  index < activeStep
                    ? "StepperLabel-completed"
                    : "StepperLabel-incomplete"
                }
              // optional={
              //   index == 2 ? (
              //     <Typography variant="caption">Last step</Typography>
              //   ) : null
              // }
              >
                {step?.label}
              </StepLabel>
              <StepContent>
                <Typography>{step?.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  <div>
                    <Button
                      style={{ backgroundColor: "rgb(25, 118, 210)" }}
                      variant="contained"
                      sx={{ mt: 1, mr: 1 }}
                      onClick={() => handleNext(index)}
                    >
                      {index == steps?.length - 1 ? "Place Order" : "Continue"}
                    </Button>
                    <Button
                      disabled={index === 0}
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {/* {activeStep === steps?.length && (
          <Paper square elevation={0} sx={{p: 3}}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} sx={{mt: 1, mr: 1}}>
              Reset
            </Button>
          </Paper>
        )} */}
      </Box>
    </StepperAlign>
  );
};

export default CheckoutStepper;

const StepperAlign = styled.div`
padding-left: 20px;
  /* padding: 20px; */
  width: 100%;

  .MuiBox-root .css-1qm1lh {
    float: right;
  }

  .StepperLabel-incomplete {
    background-color: #ddd;
    color: #999;
    border-radius: 10px;
    padding: 20px;
  }

  /* Completed Step */
  .StepperLabel-completed {
    border-radius: 10px;
    padding: 20px;
    background-color: ${styles.appColor};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .css-vnkopk-MuiStepLabel-iconContainer {
    padding: 5px;
    border-radius: 5px;
  }

  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: black;
    border-radius: 0 !important;
    width: 25px;
    height: 25px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: black;
    border-radius: 0 !important;
    width: 25px;
    height: 25px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .css-1f5ro5o-MuiButtonBase-root-MuiButton-root {
    background-color: ${styles.appColor};
    color: ${styles.white};
  }

  .css-1f5ro5o-MuiButtonBase-root-MuiButton-root:hover {
    background-color: ${styles.appColor};
  }
`;
