import React, { useState } from 'react'
import styled from "styled-components";
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useNavigate, Link } from "react-router-dom";
import API from "../../ApiService/ApiService";
import '../../Assets/Fonts/fonts.css';

let user = "";

const NewArrival3 = ({ content_all }) => {

    const getUser = localStorage.getItem("user");

    const api = new API();

    const navigate = useNavigate();

    if (getUser) {
        user = JSON.parse(localStorage.getItem("user"));
    } else {
        user = "";
    }

    const Favorite_Products = [];

    if (content_all && content_all?.onewear_lc_product) {
        content_all?.onewear_lc_product.forEach((item) => {
            if (
                !Favorite_Products.some(
                    (favoriteItem) => favoriteItem?.value === item?.value
                )
            ) {
                Favorite_Products.push(item);
            }
        });
    }

    console.log("Favorite_Products", Favorite_Products);

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        if (currentIndex + 6 < Favorite_Products.length) {
            setCurrentIndex(currentIndex + 6);
        }
    };

    const handlePrev = () => {
        if (currentIndex - 6 >= 0) {
            setCurrentIndex(currentIndex - 6);
        }
    };

    return (
        <WhyUsSectionAlign>
            <div className="home_wrapper">
                <div className="Top_Title">
                    <LeftOutlined
                        onClick={handlePrev}
                        style={{ cursor: currentIndex === 0 ? 'not-allowed' : 'pointer' }}
                    />
                    <p className="Title">
                        {content_all?.onewear_lc_title}
                    </p>
                    <RightOutlined
                        onClick={handleNext}
                        style={{
                            cursor: currentIndex + 6 >= Favorite_Products.length ? 'not-allowed' : 'pointer',
                        }}
                    />
                </div>
                <div className="ViewAll">
                    <p onClick={() => navigate(`/${content_all?.onewear_lc_link}`)}>View All</p>
                </div>
                <div className="WhyUs_Section_Main">
                    <div className="WhyUs_Section">
                        {Favorite_Products?.slice(currentIndex, currentIndex + 6)?.map((item, index) => (
                            <div
                                key={index}
                                className="WhyUs_Section_Box"
                            >
                                <div className="WhyUs_Section_Box_Image">
                                    <Link to={`/product/${item?.value}`}>
                                        <img src={api?.rootUrl1 + item?.image} />
                                    </Link>
                                </div>
                                <div className="WhyUs_Section_Box_Title">
                                    <div>{item?.label}</div>
                                </div>
                                <div className="WhyUs_Section_Box_Description">
                                    <div onClick={() => navigate(`/product/${item?.value}`)} style={{ cursor: "pointer" }}>Know More</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </WhyUsSectionAlign>
    )
}

export default NewArrival3;

const WhyUsSectionAlign = styled.div`
    margin-bottom: 80px;

    .home_wrapper {
        padding: 0 80px;
    }

    .Top_Title {
        text-align: center;
        padding: 0px 0 0px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        width: 20%;
        margin: auto;

        p {
            font-family: Inter;
            font-size: 25px;
            margin-bottom: 0;
            font-weight: 700;
        }
    }

    .ViewAll {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        p {
            font-family: Montserrat;
            font-size: 10px;
            text-decoration: underline;
        }
    }

    .WhyUs_Section_Main {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .WhyUs_Section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1%;
        flex-wrap: wrap;
    }

    .WhyUs_Section_Box {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .WhyUs_Section_Box_Image {
        width: 100%;
        height: 80vh;
        margin-bottom: 10px;
        overflow: hidden;
        background-color: #ECECEC;
    }

    .WhyUs_Section_Box_Image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .WhyUs_Section_Box_Title {
        color: #000;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        text-align: center;
        line-height: normal;
        height: 2.5em;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .WhyUs_Section_Box_Description {
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        line-height: normal;
        height: 2em;
        overflow: hidden;
    }

    @media (max-width: 1200px) {
        .home_wrapper {
            padding: 0 60px;
        }

        .Top_Title p {
            font-size: 22px;
        }
    }

    @media (max-width: 991px) {
        .home_wrapper {
            padding: 0 40px;
        }

        .Top_Title p {
            font-size: 20px;
        }

        .WhyUs_Section {
            grid-template-columns: repeat(2, 1fr);
        }

        .WhyUs_Section_Box_Title {
            font-size: 18px;
        }

        .WhyUs_Section_Box_Description {
            font-size: 9px;
        }
    }

    @media (max-width: 767px) {
        .home_wrapper {
            padding: 0 20px;
        }

        .Top_Title p {
            font-size: 18px;
        }

        .WhyUs_Section {
            grid-template-columns: 1fr;
        }

        .WhyUs_Section_Box_Image {
            height: 60vh;
        }

        .WhyUs_Section_Box_Title {
            font-size: 16px;
        }

        .WhyUs_Section_Box_Description {
            font-size: 8px;
        }
    }

    @media (max-width: 576px) {
      
        .home_wrapper {
            padding: 0px 10px;
            padding-bottom: 100px;
        }

        .Top_Title {
            width: 100%;
        }

        .Top_Title p {
            font-size: 16px;
        }

        .WhyUs_Section_Box_Image {
            height: 60vh;
        }

        .WhyUs_Section_Box_Title {
            font-size: 14px;
        }

        .WhyUs_Section_Box_Description {
            font-size: 7px;
        }
    }

    @media (max-width: 360px) {
        .home_wrapper {
            padding: 0 5px;
            padding-bottom: 100px;
        }

        .Top_Title {
            width: 100%;
        }

        .Top_Title p {
            font-size: 14px;
        }

        .WhyUs_Section_Box_Image {
            height: 60vh;
        }

        .WhyUs_Section_Box_Title {
            font-size: 12px;
        }

        .WhyUs_Section_Box_Description {
            font-size: 6px;
        }
    }
`;
