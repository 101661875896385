import React, {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import styled from "styled-components";

import {Button, Form, Input, message} from "antd";

import {styles} from "../ApiService/Data";
import API from "../ApiService/ApiService";

import {useDispatch, useSelector} from "react-redux";
import {
  userGetAllStart,
  userGetAllSuccess,
  userGetAllFail,
} from "../Redux/slices/user";
import crud_service from "../ApiService/CrudService";

const Login = () => {
  const [form] = Form.useForm();
  const [isSaving, setSaving] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginTrue = useSelector((state) => state?.user?.user?.token);
  const user = useSelector((state) => state?.user?.user);
  const redirect = localStorage.getItem("redirectPath") || ""
  const api = new crud_service();

  useEffect(() => {
    if (!loginTrue && loginTrue != "") {
    } else {
      if(redirect){
      navigate(redirect);
      }else{
        navigate("/");
      }
    }
  }, [loginTrue]);

  const loginForm = async (values) => {
    try {
      const user = await api.create(
        dispatch,
        [userGetAllStart, userGetAllSuccess, userGetAllFail],
        "login",
        values,
        (err, res) => {
          // console.log("res--------->", res);
          if (res?.status === 200) {
          // console.log("res--------->", res);
            // message.success("Login Successful");
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem("user", JSON.stringify(res?.data?.data));
            localStorage.setItem("login", true);
            localStorage.setItem("buyer_name", values?.buyer_number);
            localStorage.setItem("buyer_pasword", values?.buyer_password);
            window.location.reload();
          } else {
            message.error(res?.response?.data?.message);
          }
        }
      );
    } catch (error) {
      message.error("Something went wrong");
      // console.error("Login error:", error);
    }
  };

  return (
    <React.Fragment>
      <LoginSection>
        <LoginAlign>
          <LoginRight>
            <Title>Login</Title>
            <Form
              layout="vertical"
              form={form}
              name="Login_Form"
              onFinish={loginForm}
            >
              <Form.Item
                label="Enter Mobile number"
                name="buyer_number"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Mobile Number",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter valid Mobile Number!",
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <div className="Forgot_Password">
                <Link to="/forgot-password">Forgot Password</Link>
              </div>
              <Form.Item
                label="Enter Password"
                name="buyer_password"
                rules={[
                  {required: true, message: "Please enter your password!"},
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isSaving}
                  className="primary_btn"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <Or>or</Or>

            <NewAccount>
              <Link to="/register">Click Here</Link>
              to Register a Account
            </NewAccount>
          </LoginRight>
        </LoginAlign>
      </LoginSection>
    </React.Fragment>
  );
};

export default Login;
const LoginSection = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 580px) {
    padding: 0 20px;
  }
  .primary_btn {
    background: ${styles?.background1};
    /* border: 1px solid ${styles?.colorapi}; */
  }

  @media screen and (max-width: 768px) {
    margin-top: 60px;    
  }
`;
const LoginAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  flex-wrap: wrap;
  box-shadow: 0 0 40px rgb(0 0 0 / 9%);
  border-radius: 5px;
  margin: 70px 0;
  min-height: 300px;

  @media screen and (max-width: 580px) {
    width: 100%;
    margin: 40px 0;
  }
`;

const LoginRight = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 35px 35px;
  input {
    width: 100%;
    padding: 8px 14px;
  }
.Forgot_Password {
  width: 100%;
  text-align: right;
  margin: 0;
  Link {
    color: #000;
    font-size: 14px;
    text-decoration: underline;
  }
}

  input[type="password"] {
    width: 100%;
    padding: 4px 0px;
  }
  .ant-space {
    width: 100%;
    margin: 0 0 10px;
  }
  button {
    padding: 7px 20px;
    height: auto;
    font-size: 15px;
    background: ${styles.background};
    border: 1px solid ${styles.background};
  }

  .ant-row.ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form label {
    width: 100%;
    display: inline-block;
    text-align: left;
  }

  @media screen and (max-width: 580px) {
    padding: 30px 25px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  color: #000;
  font-weight: 700;
  width: 100%;
  margin: 0 0 20px;
  text-transform: uppercase;
`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;
const LoginOtp = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin: 10px 0 0 0;
  width: 100%;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
const ForgetPassword = styled.div`
  margin: 25px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
`;
const NewAccount = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
