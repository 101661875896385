import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import Logo from "../../Assets/Images/Poornima/PoornimaLogo.png";
import PoornimaMap from "../../Assets/Images/Poornima/Poornima_map.png";
import { Input } from "antd";
import { Link } from "react-router-dom";
import { base_url } from "../../config";
import API from "../../ApiService/ApiService";
import Curd from "../../ApiService/CrudService";
import { Tooltip } from 'antd/lib';

const Footer13 = ({ company, social, home_custom, content_all }) => {

  const [categorys, setCategory] = useState([]);
  const [finalOne, setFinalOne] = useState([]);

  const api = new API();
const curd = new Curd();

  const getAllCategory = async(category) => {
    await api.getcategory().then((res) => {
      if(res?.data?.success === true){
        const data = res?.data?.data?.filter((item) => item?.status == true);
        setCategory(data);
        categoryTree(data);
      }
    })
  }

  useEffect(() => { 
    getAllCategory()     
  }, [])

  console.log("data----->", categorys)


  const categoryTree = (category) => {
    let filter = category.filter((e) => {
      return e.category_id == null;
    });
    let final = filter.map((e) => {
      return {
        value: e?._id,
        label: e?.name,
        children:
          categoryTree2(e._id, category).length > 0
            ? categoryTree2(e._id, category)
            : "",
      };
    });
    setFinalOne(final);
    return final;
  };

  const categoryTree2 = (id, category) => {
    let filter = category.filter((e) => {
      return e.category_id == id;
    });
    let final = filter.map((e) => {
      return {
        value: e?._id,
        label: e?.name,
        children:
          categoryTree2(e._id, category).length > 0
            ? categoryTree2(e._id, category)
            : "",
      };
    });
    // setFinalOne(final);
    return final;
  };

  // console.log("finalOne", finalOne);

  const category = [
    {
      name: "Paper Craft",
      id: 1
    },
    {
      name: "Home Utilities",
      id: 2
    },
    {
      name: "Wedding hand bags",
      id: 3
    },
    {
      name: "Mobile or Cellphone Pouches",
      id: 4
    },
    {
      name: "Womens wear",
      id: 5
    },
    {
      name: "Girls Ethnic Wear",
      id: 6
    },
    {
      name: "Childrens Accessories",
      id: 7
    }
  ]
  return (
    <Section>
      <div className='Footer_Container'>
        <div className='Footer_Top_bar'>
          <div></div>
          <div></div>
        </div>
        <div className='Footer_Middle_bar'>
          <div className='Footer_logo_p_icon'>
            <div className='Image_box'>
              <Link to="/"><img src={base_url + content_all?.poornima_footer_logo?.image} alt="footer-logo" /></Link>
            </div>
            <p className='Footer_p' style={{color: "#000"}}>
              {content_all?.poornima_footer_address}
              <br />
              <Tooltip title="Email"><a style={{ textDecoration: "none" , color: "#000" }} href={"mailto:" + content_all?.poornima_footer_email}>{content_all?.poornima_footer_email}</a></Tooltip><br />
            <Tooltip title="Mobile"><a style={{ textDecoration: "none",color: "#000" }} href={"tel:" + content_all?.poornima_footer_contact}>{content_all?.poornima_footer_contact}</a></Tooltip>  
            {/* <Tooltip title="Mobile">
              <a style={{ textDecoration: "none", color: "#000" }} href={"tel:+91" + content_all?.poornima_footer_contact}>
                      {content_all?.poornima_footer_contact}
                    </a>
            </Tooltip> */}
            </p>
            <div className='Icons'>
            {content_all?.poornima_social_media?.map((item, index) => (                
                <div className='Online'>
                    <Link to={item?.poornima_media_link} style={{textDecoration: "none", color: "white"}}>
                    <img src={process.env.REACT_APP_BASE + item?.image} alt="" />
                    </Link>
                </div>
                ))}
              {/* <Tooltip title="Facebook">
                <Link to={"https://www.facebook.com/Poornima-Eshop-103236822352789/"} target="_blank" alt="facebook" title='Facebook'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" >
                    <path d="M28.9999 16.0005C28.9959 19.1777 27.8301 22.2438 25.7223 24.6213C23.6145 26.9987 20.7101 28.5233 17.5562 28.908C17.486 28.9159 17.4149 28.9089 17.3475 28.8873C17.2802 28.8658 17.2182 28.8302 17.1657 28.783C17.1131 28.7357 17.0712 28.6779 17.0426 28.6132C17.014 28.5486 16.9995 28.4786 16.9999 28.408V19.0005H19.9999C20.137 19.0008 20.2727 18.9729 20.3986 18.9185C20.5244 18.8642 20.6378 18.7845 20.7315 18.6845C20.8253 18.5845 20.8975 18.4663 20.9437 18.3373C20.9899 18.2082 21.009 18.071 20.9999 17.9342C20.9779 17.6771 20.8592 17.4378 20.6679 17.2646C20.4766 17.0914 20.2268 16.997 19.9687 17.0005H16.9999V14.0005C16.9999 13.47 17.2107 12.9613 17.5857 12.5862C17.9608 12.2112 18.4695 12.0005 18.9999 12.0005H20.9999C21.137 12.0008 21.2727 11.9729 21.3986 11.9185C21.5244 11.8642 21.6378 11.7845 21.7315 11.6845C21.8253 11.5845 21.8975 11.4663 21.9437 11.3373C21.9899 11.2082 22.009 11.071 21.9999 10.9342C21.9778 10.6766 21.8588 10.437 21.667 10.2637C21.4751 10.0904 21.2247 9.99634 20.9662 10.0005H18.9999C17.9391 10.0005 16.9217 10.4219 16.1715 11.172C15.4214 11.9222 14.9999 12.9396 14.9999 14.0005V17.0005H11.9999C11.8629 17.0002 11.7272 17.028 11.6013 17.0824C11.4755 17.1367 11.3621 17.2164 11.2683 17.3164C11.1746 17.4164 11.1023 17.5346 11.0562 17.6636C11.01 17.7927 10.9909 17.9299 10.9999 18.0667C11.0221 18.3243 11.141 18.5639 11.3329 18.7372C11.5248 18.9105 11.7752 19.0046 12.0337 19.0005H14.9999V28.4105C15.0004 28.481 14.9859 28.5509 14.9574 28.6154C14.9289 28.68 14.887 28.7377 14.8346 28.785C14.7822 28.8322 14.7204 28.8678 14.6532 28.8894C14.5861 28.9111 14.5151 28.9182 14.4449 28.9105C11.2066 28.5161 8.2344 26.9203 6.11654 24.439C3.99867 21.9577 2.88955 18.7718 3.00869 15.5117C3.25869 8.76171 8.72619 3.27421 15.4812 3.01171C17.2301 2.94396 18.9746 3.22965 20.6105 3.85166C22.2464 4.47368 23.7401 5.41925 25.0021 6.63183C26.2642 7.8444 27.2687 9.29906 27.9556 10.9088C28.6425 12.5185 28.9977 14.2503 28.9999 16.0005Z" fill="#88AB8E" />
                  </svg>
                </Link>
              </Tooltip> */}
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M30.7071 9.70709L26.9696 13.4446C26.2146 22.1971 18.8333 28.9996 9.99957 28.9996C8.18457 28.9996 6.68832 28.7121 5.55207 28.1446C4.63582 27.6858 4.26082 27.1946 4.16707 27.0546C4.08348 26.9292 4.0293 26.7866 4.00857 26.6373C3.98785 26.4881 4.00112 26.3361 4.0474 26.1927C4.09368 26.0493 4.17176 25.9182 4.27582 25.8092C4.37988 25.7002 4.50722 25.6162 4.64832 25.5633C4.68082 25.5508 7.67832 24.3996 9.58207 22.2083C8.5263 21.3403 7.60467 20.321 6.84707 19.1833C5.29707 16.8821 3.56207 12.8846 4.09707 6.91084C4.11403 6.72102 4.18488 6.54 4.30126 6.3891C4.41765 6.23819 4.57474 6.12369 4.75402 6.05907C4.9333 5.99445 5.12732 5.9824 5.31322 6.02435C5.49912 6.0663 5.66916 6.16049 5.80332 6.29584C5.84707 6.33959 9.96332 10.4333 14.9958 11.7608V10.9996C14.9939 10.2013 15.1517 9.41076 15.46 8.67443C15.7683 7.93809 16.2208 7.27089 16.7908 6.71209C17.3444 6.15925 18.0032 5.72289 18.7282 5.42877C19.4532 5.13465 20.2298 4.98872 21.0121 4.99959C22.0615 5.00994 23.0904 5.29166 23.9988 5.81735C24.9071 6.34304 25.664 7.09481 26.1958 7.99959H29.9996C30.1975 7.99943 30.391 8.058 30.5556 8.16787C30.7202 8.27774 30.8484 8.43398 30.9242 8.6168C30.9999 8.79962 31.0198 9.00081 30.9811 9.1949C30.9425 9.38898 30.8471 9.56723 30.7071 9.70709Z" fill="#88AB8E" />
              </svg> */}
              {/* <Tooltip title="Instagram">
                <Link to={"https://www.instagram.com/poornima_eshop/"} target="_blank" alt="instagram">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" title="instagram">
                    <path d="M22 3H10C8.14409 3.00199 6.36477 3.74012 5.05245 5.05245C3.74012 6.36477 3.00199 8.14409 3 10V22C3.00199 23.8559 3.74012 25.6352 5.05245 26.9476C6.36477 28.2599 8.14409 28.998 10 29H22C23.8559 28.998 25.6352 28.2599 26.9476 26.9476C28.2599 25.6352 28.998 23.8559 29 22V10C28.998 8.14409 28.2599 6.36477 26.9476 5.05245C25.6352 3.74012 23.8559 3.00199 22 3ZM16 22C14.8133 22 13.6533 21.6481 12.6666 20.9888C11.6799 20.3295 10.9108 19.3925 10.4567 18.2961C10.0026 17.1997 9.88378 15.9933 10.1153 14.8295C10.3468 13.6656 10.9182 12.5965 11.7574 11.7574C12.5965 10.9182 13.6656 10.3468 14.8295 10.1153C15.9933 9.88378 17.1997 10.0026 18.2961 10.4567C19.3925 10.9108 20.3295 11.6799 20.9888 12.6666C21.6481 13.6533 22 14.8133 22 16C21.9983 17.5908 21.3657 19.116 20.2408 20.2408C19.116 21.3657 17.5908 21.9983 16 22ZM23.5 10C23.2033 10 22.9133 9.91203 22.6666 9.7472C22.42 9.58238 22.2277 9.34811 22.1142 9.07403C22.0007 8.79994 21.9709 8.49834 22.0288 8.20736C22.0867 7.91639 22.2296 7.64912 22.4393 7.43934C22.6491 7.22956 22.9164 7.0867 23.2074 7.02882C23.4983 6.97094 23.7999 7.00065 24.074 7.11418C24.3481 7.22771 24.5824 7.41997 24.7472 7.66664C24.912 7.91332 25 8.20333 25 8.5C25 8.89782 24.842 9.27936 24.5607 9.56066C24.2794 9.84196 23.8978 10 23.5 10ZM20 16C20 16.7911 19.7654 17.5645 19.3259 18.2223C18.8864 18.8801 18.2616 19.3928 17.5307 19.6955C16.7998 19.9983 15.9956 20.0775 15.2196 19.9231C14.4437 19.7688 13.731 19.3878 13.1716 18.8284C12.6122 18.269 12.2312 17.5563 12.0769 16.7804C11.9225 16.0044 12.0017 15.2002 12.3045 14.4693C12.6072 13.7384 13.1199 13.1136 13.7777 12.6741C14.4355 12.2346 15.2089 12 16 12C17.0609 12 18.0783 12.4214 18.8284 13.1716C19.5786 13.9217 20 14.9391 20 16Z" fill="#88AB8E" />
                  </svg>
                </Link>
              </Tooltip> */}
            </div>
          </div>
          <div className="Quick_links_container">
            <h4>Quick Links</h4>
            <ul className="quick_links">
              <Link to="/privacy-policy" style={{ textDecoration: "none", color: "#000" }}>
                <li>Privacy Policy</li>
              </Link>
              <Link to="/terms-and-condition" style={{ textDecoration: "none", color: "#000" }}>
                <li>Terms and Conditions</li>
              </Link>
              <Link to="/refund-policy" style={{ textDecoration: "none", color: "#000" }}>
                <li>Refund Policy</li>
              </Link>
              <Link  to="/delivery-policy" style={{ textDecoration: "none", color: "#000" }}>
                <li>Delivery Policy</li>
              </Link>
              <Link  to="/return-policy" style={{ textDecoration: "none", color: "#000" }}>
                <li>Return Policy</li>
              </Link>
              <Link to="/cancellation-policy" style={{ textDecoration: "none", color: "#000" }}>
                <li>Cancellation Policy</li>
              </Link>
            </ul>
          </div>
          <div className='Categories_container'>
            <h4>Categories</h4>
            <ul className="quick_links">
            {
               finalOne?.map((item, index) => (
                   <li key={index}>
                       <Link
                           to={`/products?catName=${item?.label}`}
                           style={{ textDecoration: "none", color: "#000" }}
                       >
                           {item?.label}
                       </Link>
                   </li>
               ))
           
                // (<li><Link to="/products" style={{ textDecoration: 'none' , color: '#000'}}>Maternity Leggins</Link></li>)
              }
            </ul>
          </div>
          <div className='Footer_map'>
          <iframe src={`${content_all?.poornima_footer_map}`}  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15662.360134790662!2d76.945946!3d11.069332000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8f63cb18e7145%3A0x90d266e3d150d5a6!2sSreevatsa%20Garden%2C%20Thudiyalur%2C%20Tamil%20Nadu%20641029!5e0!3m2!1sen!2sin!4v1710408624772!5m2!1sen!2sin"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe> */}
          </div>
        </div>
        <div className='Footer_Bottom_bar'>
          <div className='Footer_Bottom_bar_wrap'>
          <div className="Left_Bar">
          Copyright & Designed by{" "}
          <Link
            to="https://www.ecdigi.com/"
            target="_blank"
            style={{ textDecoration: "none", color: "white" }}
          >
            ecDigi
          </Link>
        </div>
            {/* <div className="Right_Bar">Web designer by Blazon</div> */}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Footer13;

const Section = styled.section`

  .Footer_Middle_bar{
    /* padding-top: 100px; */
    padding-bottom: 100px;
  }

  .Footer_Top_bar{
    margin: 0px 100px 0px 100px;
  }

  .Footer_Middle_bar{
    width: 90%;
    margin: 0px 100px 0px 100px; 
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }

  .Footer_logo_p_icon{
    /* border: 1px solid red; */
  }
  
  .Footer_Bottom_bar{
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    background: #5D7C63;
    color: #FFF;       
  }

  .Footer_Bottom_bar_wrap{   
    height: 50px;
    flex-shrink: 0;
    background: #5D7C63;
    /* margin: 0px 100px 0px 100px;    */
    display: flex;
    align-items: center;
    justify-content: center;

    .Left_Bar{
      font-size: 14px;
    }
    
    .Right_Bar{
      font-size: 14px;
    }
  }

  .Image_box{    
    flex-shrink: 0;
  }

  .Footer_p{    
    width: 259px;
    flex-shrink: 0;
    color: #000;
    font-family: Avenir LT Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 200%; /* 32px */
  }

  .Icons{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 17px;
  }

  .Quick_links_container{
    /* border: 1px solid red; */
    /* width: 150px; */
    h4{
      color: #000;
      font-family: Brandon Text;
      font-size: 16px;
      font-style: normal;
      font-weight: 550;
      line-height: 200%; /* 32px */
      letter-spacing: 0.8px;
      text-transform: uppercase;
    }
  }

  .quick_links{    
    color: #000;
    font-family: Avenir LT Std;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 200%; /* 32px */
    padding-left: 0px;
  }

  .Categories_container{
    /* border: 1px solid red; */
    /* width: 150px; */
    h4{
      color: #000;
      font-family: Brandon Text;
      font-size: 16px;
      font-style: normal;
      font-weight: 550;
      line-height: 200%; /* 32px */
      letter-spacing: 0.8px;
      text-transform: uppercase;
    }
  }

  .Footer_map{
    /* border: 1px solid red;         */
    /* width: 467px; */
    /* height: 360px; */
    flex-shrink: 0;
  }

  @media screen and (max-width: 1024px) {
    max-width: 1024px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(3, 1fr);
    } 
    iframe{
      width: 295%;
    height: 248px;
    }
  }

  @media screen and (max-width: 992px) {
    max-width: 992px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 930px) {
    max-width: 930px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(1, 1fr);
    }
    
  }

  @media screen and (max-width: 768px) {
    max-width: 768px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(2, 1fr);
      width: 70%;
      margin: 0px 50px 0px 50px;
    }
    .Footer_map{
      width: 400px;
    }
    iframe{
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 540px) {
    max-width: 540px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
      margin: 0px 25px 0px 25px;
    }    
  }

  @media screen and (max-width: 520px) {
    max-width: 520px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
      margin: 0px 25px 0px 25px;
    }
    .Footer_map{
      width: 400px;
    }    
  }

  @media screen and (max-width: 480px) {
    max-width: 480px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
      margin: 0px 25px 0px 25px;
    }
    .Footer_map{
      width: 350px;
    }
  }

  @media screen and (max-width: 425px) {
    max-width: 425px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
      margin: 0px 25px 0px 25px;
    }
    .Footer_map{
      width: 350px;
    }
  }

  @media screen and (max-width: 414px) {
    max-width: 414px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
      margin: 0px 25px 0px 25px;
    }
    .Footer_map{
      width: 350px;
    }
  }

  @media screen and (max-width: 412px) {
    max-width: 412px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
      margin: 0px 25px 0px 25px;
    }
    .Footer_map{
      width: 350px;
    }
  }

  @media screen and (max-width: 390px) {
    max-width: 390px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
      margin: 0px 25px 0px 25px;
    }
    .Footer_map{
      width: 330px;
    }
  }

  @media screen and (max-width: 375px) {
    max-width: 375px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
      margin: 0px 25px 0px 25px;
    }
    .Footer_map{
      width: 320px;
    }
  }

  @media screen and (max-width: 360px) {
    max-width: 360px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
      margin: 0px 25px 0px 25px;
    }
    .Footer_map{
      width: 300px;
    }
  }

  @media screen and (max-width: 320px) {
    max-width: 320px;
    .Footer_Middle_bar{
      grid-template-columns: repeat(1, 1fr);
      width: 80%;
      margin: 0px 25px 0px 25px;
    }
    .Footer_map{
      width: 280px;
    }
  }

`;