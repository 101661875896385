import React, { useState, useEffect } from "react";
import { Card , Button, Divider} from "antd";
import {ArrowRightOutlined} from "@ant-design/icons"
import styled from "styled-components";
import API from "../../../ApiService/ApiService";
import {useNavigate} from "react-router-dom"

const { Meta } = Card;

const Blog = () => {
  const api = new API();
  const navigate = useNavigate();
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    getAllBlogs();
  }, []);

  const getAllBlogs = () => {
    api.getblogs().then((res) => {
      // console.log("blog----->", res);
      setBlog(res?.data?.data);
    });
  };

const handleBlogClick = (id) => {
  // console.log("id", id);
  navigate(`/blog/${id}`);
     
}


  return (
    <BlogSection>
      <div className="Title">
        <H2>Our Blogs</H2>
      </div>

      <div className="Wrapper">
        {blog?.slice(0, 3).map((item) => (
          <div className="Blog_section" key={item?._id}>
            <StyledCard hoverable>
              <CardContent>
                <CardImageWrapper>
                  <img src={api.rootUrl1 + item?.image} alt="Blog Image" />
                </CardImageWrapper>
                <div className="description">
                  <Meta title={item?.title} description={item?.short_description} />
                </div>
                <Divider />
                <Button 
                className="Blog_Button"
                onClick={() => handleBlogClick(item?._id)}
                >
                Continue Reading <ArrowRightOutlined />
                </Button>
              </CardContent>
            </StyledCard>
          </div>
        ))}
      </div>
    </BlogSection>
  );
};

export default Blog;

const BlogSection = styled.section`
  display: grid;
  justify-content: center;
  align-items: center;
  .Wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

.Blog_Button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 16px;
  
    :focus {
      outline: none;

    }
}

`;

const H2 = styled.h2`
  font-size: 30px;
  margin: 0 0 35px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  letter-spacing: 0.7px;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

const StyledCard = styled(Card)`
  width: 350px;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    height: 100%;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .description {
    flex: 1;
    max-height: 50px;
    overflow: hidden;
  }
`;

const CardImageWrapper = styled.div`
  img {
    width: 100% !important;
    height: 50% !important;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
  }
`;
